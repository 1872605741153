import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Algorithms from './Algorithms';
import { ReactComponent as AlgorithmIcon } from '../../../../assets/icons/algorithm.svg';
import { ReactComponent as DatabaseIcon } from '../../../../assets/icons/database.svg';
import CustomTabs from '../../../../shared/CustomTabs';
import Databases from './Databases';
import text from '../../../../text.json';
import { setAlgorithms } from '../../../../redux/algorithmSlice';
import { setDatabases } from '../../../../redux/databaseSlice';
import { setProject } from '../../../../redux/projectSlice';

export const TAB_INDEX = {
  ALGORITHMS: 0,
  DATABASES: 1
};

const Project = () => {
  const { state } = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(
    (state
      && (state?.tabIndex
        || state?.tabIndex === 0)) ? state.tabIndex : TAB_INDEX.ALGORITHMS
  );
  const dispatch = useDispatch();

  const handleChange = (event, newIndex) => {
    setCurrentTabIndex(newIndex);
  };

  useEffect(() => () => {
    dispatch(setAlgorithms([]));
    dispatch(setDatabases([]));
    dispatch(setProject({}));
  }, []);

  useEffect(() => {
    if (state && (state?.tabIndex || state?.tabIndex === 0)) {
      setCurrentTabIndex(state.tabIndex);
    }
  }, [state]);

  const tabs = [
    {
      icon: AlgorithmIcon,
      label: text.sections.algorithms,
      component: Algorithms,
      index: TAB_INDEX.ALGORITHMS
    },
    {
      icon: DatabaseIcon,
      label: text.sections.databases,
      component: Databases,
      index: TAB_INDEX.DATABASES
    }
  ];

  return (
    <Box sx={{ width: '100%', height: 'calc(100% - 84px)' }}>
      <CustomTabs currentIndex={currentTabIndex} handleChange={handleChange} tabs={tabs} />
    </Box>
  );
};

export default Project;
