import { InputAdornment } from '@mui/material';

export const NUMBER_FIELDS_NAMES = {
  width: 'width',
  height: 'height',
  resolution: 'dotsPerInch'
};

// eslint-disable-next-line max-len
export const getNumberInputTextFieldCommonProps = (inputAdornmentText, field, setErrorMessage, setData, dataTestId) => ({
  fullWidth: true,
  variant: 'standard',
  type: 'number',
  onKeyPress: (event) => {
    if (event?.key === '-'
    || event?.key === '+'
    || event?.key === '.'
    || event?.key === 'E'
    || event?.key === 'e'
    || event?.key === ','
    || event.target.value > 32767
    ) {
      event.preventDefault();
    }
  },
  InputProps: {
    endAdornment: (
      <InputAdornment position="end">
        {inputAdornmentText}
      </InputAdornment>
    ),
    inputProps: {
      min: 0,
      max: 32767,
      'data-testid': dataTestId
    }
  },
  onChange: (event) => {
    setData((prevData) => (
      { ...prevData, [field]: parseInt(event.target.value, 10) }));
    setErrorMessage('');
  },
});
