import {
  Snackbar, Slide, SnackbarContent, Box, Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetSnackbar } from '../redux/snackbarSlice';
import { BORDER_RADIUS, PALETTE } from '../theme/constants';
import text from '../text.json';

const CustomSnackbar = () => {
  const { errorMessage, successMessage } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});

  const handleClose = () => {
    setIsOpen(false);
    dispatch(resetSnackbar());
  };

  useEffect(() => {
    if (errorMessage || successMessage) {
      setIsOpen(true);
    }
  }, [errorMessage, successMessage]);

  useEffect(() => {
    if (errorMessage) {
      setSnackbarData({
        text: errorMessage,
        additionalText: text.errorMessages.contactSupport,
        color: PALETTE.general.errorLight,
        iconColor: PALETTE.general.error,
        icon: ErrorIcon,
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      setSnackbarData({
        text: successMessage,
        color: 'white',
        iconColor: PALETTE.general.success,
        icon: CheckCircleIcon,
      });
    }
  }, [successMessage]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3500}
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Slide}
      sx={{ zIndex: 1401 }}
    >
      {snackbarData?.color && snackbarData?.text && snackbarData?.icon && (
      <SnackbarContent
        style={{
          backgroundColor: snackbarData.color,
          borderRadius: BORDER_RADIUS.SMALL,
          display: 'block',
          borderLeft: `8px solid ${snackbarData.iconColor}`,
          color: 'black'
        }}
        message={(
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <snackbarData.icon sx={{ color: snackbarData.iconColor }} />
              <Typography sx={{ fontWeight: 'fontWeightBold', color: snackbarData.iconColor, ml: 1 }} variant="body2">
                {snackbarData.text}
              </Typography>
            </Box>
            {snackbarData?.additionalText && (
              <Typography variant="caption">
                {snackbarData.additionalText}
              </Typography>
            )}
          </Box>
      )}
      />
      )}
    </Snackbar>
  );
};

export default CustomSnackbar;
