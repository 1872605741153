import {
  ADDING_REQUESTED,
  OBJECTS,
  OBJECT_STATUSES_ACTIVITY_LOG,
  REMOVAL_REQUESTED
} from '../../config';
import {
  algorithmAdded,
  algorithmAdding,
  algorithmDeleting,
  databaseAdded,
  databaseDeleting,
  databaseInvalid,
  databaseUpdated,
  databaseUploaded,
  projectAdded,
  projectRequested,
  projectUpdated,
  sensorAdded,
  sensorRequested,
  sensorUpdated,
} from '../../assets/icons';
import text from '../../text.json';
import { PALETTE } from '../../theme/constants';

export const OBJECT_ICON = {
  [OBJECTS.SENSOR]: {
    [OBJECT_STATUSES_ACTIVITY_LOG.ADDED]: sensorAdded,
    [OBJECT_STATUSES_ACTIVITY_LOG.REMOVAL_REQUESTED]: sensorRequested,
    [OBJECT_STATUSES_ACTIVITY_LOG.UPDATED]: sensorUpdated,
  },
  [OBJECTS.PROJECT]: {
    [OBJECT_STATUSES_ACTIVITY_LOG.ADDED]: projectAdded,
    [OBJECT_STATUSES_ACTIVITY_LOG.REMOVAL_REQUESTED]: projectRequested,
    [OBJECT_STATUSES_ACTIVITY_LOG.UPDATED]: projectUpdated,
  },
  [OBJECTS.ALGORITHM]: {
    [OBJECT_STATUSES_ACTIVITY_LOG.ADDED]: algorithmAdded,
    [OBJECT_STATUSES_ACTIVITY_LOG.REMOVAL_REQUESTED]: algorithmDeleting,
    [OBJECT_STATUSES_ACTIVITY_LOG.ADDING_REQUESTED]: algorithmAdding,
  },
  [OBJECTS.DATABASE]: {
    [OBJECT_STATUSES_ACTIVITY_LOG.ADDED]: databaseAdded,
    [OBJECT_STATUSES_ACTIVITY_LOG.REMOVAL_REQUESTED]: databaseDeleting,
    [OBJECT_STATUSES_ACTIVITY_LOG.NOT_READY]: databaseInvalid,
    [OBJECT_STATUSES_ACTIVITY_LOG.UPDATED]: databaseUpdated,
    [OBJECT_STATUSES_ACTIVITY_LOG.INDEX_FILE_UPLOADED]: databaseUploaded,
    [OBJECT_STATUSES_ACTIVITY_LOG.FPDB_UPLOADED]: databaseUploaded,
  }
};

export const OBJECT_STATUS_LABEL = {
  [OBJECT_STATUSES_ACTIVITY_LOG.ADDED]: text.status.added,
  [OBJECT_STATUSES_ACTIVITY_LOG.UPDATED]: text.status.updated,
  [OBJECT_STATUSES_ACTIVITY_LOG.READY]: text.status.ready,
  [OBJECT_STATUSES_ACTIVITY_LOG.UPLOADED]: text.status.uploaded,
  [OBJECT_STATUSES_ACTIVITY_LOG.NOT_READY]: text.status.notReady,
  [OBJECT_STATUSES_ACTIVITY_LOG.REMOVAL_REQUESTED]: REMOVAL_REQUESTED.text,
  [OBJECT_STATUSES_ACTIVITY_LOG.ADDING_REQUESTED]: ADDING_REQUESTED.text,
};

export const OBJECT_STATUS_COLOR = {
  [OBJECT_STATUSES_ACTIVITY_LOG.ADDED]: PALETTE.general.success,
  [OBJECT_STATUSES_ACTIVITY_LOG.UPDATED]: PALETTE.gray.dark,
  [OBJECT_STATUSES_ACTIVITY_LOG.REMOVAL_REQUESTED]: PALETTE.primary.main,
  [OBJECT_STATUSES_ACTIVITY_LOG.ADDING_REQUESTED]: PALETTE.secondary.main,
  [OBJECT_STATUSES_ACTIVITY_LOG.READY]: PALETTE.general.success,
  [OBJECT_STATUSES_ACTIVITY_LOG.NOT_READY]: PALETTE.general.error,
  [OBJECT_STATUSES_ACTIVITY_LOG.INDEX_FILE_UPLOADED]: PALETTE.secondary.main,
  [OBJECT_STATUSES_ACTIVITY_LOG.FPDB_UPLOADED]: PALETTE.secondary.main,
};
