import {
  Box, Tooltip, Typography
} from '@mui/material';
import PendingIcon from '@mui/icons-material/WatchLater';
import PropTypes from 'prop-types';

import { ADDING_REQUESTED } from '../config';

const StatusFlag = ({ data }) => {
  const isAddedFlag = data?.text === ADDING_REQUESTED.text;

  return (
    <Tooltip title={data?.tooltip} placement="bottom-end">
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: isAddedFlag ? 'secondary.dark' : 'primary.dark'
      }}
      >
        <PendingIcon sx={{ color: isAddedFlag ? 'secondary.dark' : 'primary.dark', mr: 0.5 }} />
        <Typography noWrap variant="caption" sx={{ fontWeight: 'fontWeightBold' }}>
          {data?.text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default StatusFlag;

StatusFlag.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.string
  })
};

StatusFlag.defaultProps = {
  data: {
    text: '',
    tooltip: ''
  }
};
