import Axios from 'axios';

import { loginRequest } from '../authConfig';
import { API_URL } from '../config';

export const getToken = async (msalInstance) => {
  const currentAccount = msalInstance.getActiveAccount();
  const accessTokenRequest = {
    ...loginRequest,
    account: currentAccount,
  };

  if (currentAccount) {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
    return `${accessTokenResponse.tokenType} ${accessTokenResponse.accessToken}`;
  }
  return null;
};

export const getCurrentUserAuthorizationInformation = () => Axios.get(`${API_URL}/Authorize`);
