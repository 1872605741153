import {
  Box, CssBaseline, ThemeProvider
} from '@mui/material';
import {
  Navigate, Route, Routes, useNavigate
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import defaultTheme from './theme';
import {
  AdminOutlet, PrivateOutlet, PublicOutlet, SensorOutlet, UserOutlet,
} from './components/Outlets';
import Login from './components/Login';
import Customers from './components/Customers';
import Projects from './components/Sensors/Projects';
import ActivityLogs from './components/ActivityLogs';
import { ROUTES } from './config';
import Releases from './components/Releases';
import Sensors from './components/Sensors';
import Snackbar from './shared/CustomSnackbar';
import Project from './components/Sensors/Projects/Project';
import { fetchMetaData } from './redux/api/general';
import UploadSnackbar from './components/Sensors/Projects/Project/Databases/UploadSnackbar';
import NotFoundItemModal from './shared/NotFoundNameModal';
import MainLoaderIcon from './shared/MainLoaderIcon';
import { getCurrentUserRole } from './utils/authHelper';
import { setLoggedUser } from './redux/authSlice';
import { getCurrentUserAuthorizationInformation, getToken } from './services/auth';
import ConnectionLost from './shared/ConnectionLost';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { itemNotFoundData } = useSelector((state) => state.general);
  const { shouldUserBeLoggedOut } = useSelector((state) => state.auth);

  useEffect(() => {
    if (inProgress === 'none') {
      if (!isAuthenticated) {
        setIsLoading(false);
        navigate(ROUTES.LOGIN);
      }
    }
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    if (account && Object.keys(accounts)?.length && inProgress === 'none') {
      (async () => {
        try {
          await instance.setActiveAccount(account);
          axios.interceptors.request.use(async (config) => {
            try {
              const bearer = await getToken(instance);
              config.headers.Authorization = bearer;
              return config;
            } catch (error) {
              setIsLoading(false);
              await instance.logoutRedirect();
              return config;
            }
          });

          const userAuthorizationResponse = await getCurrentUserAuthorizationInformation();
          setIsLoading(false);
          dispatch(setLoggedUser({
            ...account,
            ...userAuthorizationResponse?.data
          }));
          dispatch(fetchMetaData());
        } catch (error) {
          setIsLoading(false);
          await instance.logoutRedirect();
        }
      })();
    }
  }, [account, inProgress, instance]);

  useEffect(() => {
    if (shouldUserBeLoggedOut) {
      instance.logoutRedirect();
    }
  }, [shouldUserBeLoggedOut]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ConnectionLost />
      <Snackbar />
      <UploadSnackbar />
      <CssBaseline />
      {!!itemNotFoundData && (
        <NotFoundItemModal isOpen={!!itemNotFoundData} />
      )}
      {isLoading && (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MainLoaderIcon />
      </Box>
      )}
      {!isLoading && (
      <Routes>
        <Route element={<PublicOutlet />}>
          <Route path={ROUTES.LOGIN} element={<Login />} />
        </Route>
        <Route path="/" element={<PrivateOutlet />}>
          <Route element={<AdminOutlet loggedUserRole={getCurrentUserRole(account)} />}>
            <Route path="/" element={<Navigate to={ROUTES.CUSTOMERS} />} />
            <Route path={ROUTES.CUSTOMERS} element={<Customers />} />
            <Route path={`${ROUTES.CUSTOMERS}/:customerId/${ROUTES.ACTIVITY_LOGS}`} element={<ActivityLogs />} />
            <Route path={`${ROUTES.CUSTOMERS}/:customerId/${ROUTES.RELEASES}`} element={<Releases />} />
            <Route element={<SensorOutlet />}>
              <Route path={`${ROUTES.CUSTOMERS}/:customerId/${ROUTES.SENSORS}`} element={<Sensors />} />
              <Route path={`${ROUTES.CUSTOMERS}/:customerId/${ROUTES.SENSORS}/:sensorId`} element={<Projects />} />
              <Route path={`${ROUTES.CUSTOMERS}/:customerId/${ROUTES.SENSORS}/:sensorId/${ROUTES.PROJECTS}/:projectId`} element={<Project />} />
            </Route>
          </Route>
          <Route element={<UserOutlet loggedUserRole={getCurrentUserRole(account)} />}>
            <Route path="/" element={<Navigate to={ROUTES.ACTIVITY_LOGS} />} />
            <Route path={ROUTES.ACTIVITY_LOGS} element={<ActivityLogs />} />
            <Route path={ROUTES.RELEASES} element={<Releases />} />
            <Route element={<SensorOutlet />}>
              <Route path={ROUTES.SENSORS} element={<Sensors />} />
              <Route path={`${ROUTES.SENSORS}/:sensorId`} element={<Projects />} />
              <Route path={`${ROUTES.SENSORS}/:sensorId/${ROUTES.PROJECTS}/:projectId`} element={<Project />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
      )}
    </ThemeProvider>
  );
};

export default App;
