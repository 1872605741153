import text from '../../../../../text.json';

export const columns = [
  {
    name: text.commonInputFields.name,
    key: 'name'
  },
  {
    name: text.commonInputFields.type,
    key: 'algoType'
  },
  {
    name: text.addAlgorithm.ispShortTerm,
    key: 'hasISP'
  },
  {
    name: text.addAlgorithm.padShortTerm,
    key: 'hasPAD'
  },
  {
    name: text.addAlgorithm.latentShortTerm,
    key: 'hasLatentDetection'
  },
  {
    name: '',
    key: 'action'
  }
];
