import { TextField } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { GENERAL_ERROR_MESSAGE } from '../../config';
import Modal from '../../shared/Modal';
import text from '../../text.json';
import { checkIfCustomerNameExist } from '../../services/customers';
import { setErrorMessage } from '../../redux/snackbarSlice';
import { addCustomer } from '../../redux/api/customer';

const AddCustomer = ({ handleClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [customerData, setCustomerData] = useState({
    name: ''
  });

  const dispatch = useDispatch();

  const getNameErrorMessage = async (name, checkIfNameExist) => {
    if (!name) {
      return text.errorMessages.fieldRequired.replace(/{{field}}/g, text.commonInputFields.name);
    }

    const response = await checkIfNameExist();

    if (!response?.data?.isAvailable) {
      return text.errorMessages.fieldValueExists.replace(/{{field}}/g, text.commonInputFields.name);
    }

    return '';
  };

  const handleAddingCustomer = async () => {
    const { name } = customerData;

    let isDataValid = true;
    setIsLoading(true);

    try {
      const nameErrorMessageTmp = await
      getNameErrorMessage(name, () => checkIfCustomerNameExist({ name }));

      if (nameErrorMessageTmp) {
        setNameErrorMessage(nameErrorMessageTmp);
        isDataValid = false;
      }
    } catch (error) {
      dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
      isDataValid = false;
    }

    if (isDataValid) {
      const result = await dispatch(addCustomer(customerData));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.add.title.replace('{{object}}', text.objects.customer)}
      primaryButtonText={text.buttons.add.replace('{{object}}', text.objects.customer)}
      onPrimaryButtonClick={handleAddingCustomer}
      isLoading={isLoading}
    >
      <TextField
        fullWidth
        label={`${text.commonInputFields.name} *`}
        variant="standard"
        onChange={(event) => {
          setCustomerData((prevCustomerData) => (
            { ...prevCustomerData, name: event.target.value }));
          setNameErrorMessage('');
        }}
        helperText={nameErrorMessage}
        error={!!nameErrorMessage}
        inputProps={{
          'data-testid': 'nameInputTest',
        }}
      />
    </Modal>
  );
};

export default AddCustomer;

AddCustomer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

AddCustomer.defaultProps = {
  isOpen: false
};
