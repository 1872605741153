import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchSensors } from '../../redux/api/sensor';
import { ADDING_REQUESTED, REMOVAL_REQUESTED } from '../../config';
import { setSensor } from '../../redux/sensorSlice';
import { columns } from './config';
import Table from './OverviewsShared/Table';
import AddSensor from '../Modals/AddSensor';
import text from '../../text.json';
import RemoveSensor from '../Modals/RemoveSensor';
import EditSensor from '../Modals/EditSensor';

const Sensors = () => {
  const [transformedSensors, setTransformedSensors] = useState([]);
  const [filteredSensors, setFilteredSensors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isAddSensorModalOpen, setIsAddSensorModalOpen] = useState(false);
  const [isRemoveSensorModalOpen, setIsRemoveSensorModalOpen] = useState(false);
  const [isEditSensorModalOpen, setIsEditSensorModalOpen] = useState(false);
  const [rowActionSensorData, setRowActionSensorData] = useState({});

  const { sensors } = useSelector((state) => state.sensor);
  const { customerInfo } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(setSensor({}));
  }, []);

  useEffect(() => {
    if (customerInfo) {
      (async () => {
        await dispatch(fetchSensors(customerInfo?.id));
        setIsLoading(false);
      })();
    }
  }, [customerInfo]);

  useEffect(() => {
    if (sensors?.length) {
      setTransformedSensors(sensors?.map((sensor) => {
        const sensorTmp = {
          ...sensor,
          size: `${sensor.width}x${sensor.height}`,
        };
        return sensorTmp;
      }));
    } else if (!isLoading && !sensors?.length) {
      setFilteredSensors([]);
    }
  }, [sensors, isLoading]);

  useEffect(() => {
    if (transformedSensors?.length) {
      setFilteredSensors(transformedSensors.filter(
        ({
          name, sensorType, size, isPendingToAdd, isPendingToRemove
        }) => (
          name?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || sensorType?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || size?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || (isPendingToRemove && REMOVAL_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          ))
          || (isPendingToAdd && ADDING_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          ))
        )
      ));
    }
  }, [searchValue, transformedSensors]);

  return (
    <>
      <Table
        isButtonDisabled={false}
        onRowClick={(sensorId) => navigate(`${location.pathname}/${sensorId}`)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoading={isLoading}
        rows={filteredSensors}
        columns={columns}
        buttonText={`+ ${text.buttons.add.replace('{{object}}', text.objects.sensor)}`}
        onButtonClick={() => setIsAddSensorModalOpen(true)}
        handleRemove={(sensorData) => {
          setRowActionSensorData(sensorData);
          setIsRemoveSensorModalOpen(true);
        }}
        handleEdit={(sensorData) => {
          setRowActionSensorData(sensorData);
          setIsEditSensorModalOpen(true);
        }}
      />
      {isAddSensorModalOpen && (
      <AddSensor handleClose={() => setIsAddSensorModalOpen(false)} isOpen={isAddSensorModalOpen} />
      )}
      {isRemoveSensorModalOpen && (
        <RemoveSensor
          handleClose={() => {
            setIsRemoveSensorModalOpen(false);
            setRowActionSensorData({});
          }}
          isOpen={isRemoveSensorModalOpen}
          data={rowActionSensorData}
        />
      )}
      {isEditSensorModalOpen && (
        <EditSensor
          handleClose={() => {
            setIsEditSensorModalOpen(false);
            setRowActionSensorData({});
          }}
          isOpen={isEditSensorModalOpen}
          data={rowActionSensorData}
        />
      )}
    </>
  );
};

export default Sensors;
