import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

import Modal from '../../shared/Modal';
import text from '../../text.json';
import { getNumberInputTextFieldCommonProps, NUMBER_FIELDS_NAMES } from './helper';
import { editProject } from '../../redux/api/project';

const EditProject = ({ handleClose, isOpen, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState(data);
  const [resolutionErrorMessage, setResolutionErrorMessage] = useState('');
  const dispatch = useDispatch();

  const handleEditProject = async () => {
    const { dotsPerInch } = projectData;

    setIsLoading(true);
    let isDataValid = true;

    if (dotsPerInch !== 0 && !dotsPerInch) {
      setResolutionErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.commonInputFields.resolution));
      isDataValid = false;
    }

    if (isDataValid) {
      const result = await dispatch(editProject(projectData));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.edit.title.replace('{{object}}', text.objects.project)}
      primaryButtonText={text.buttons.editObject.replace('{{object}}', text.objects.project)}
      onPrimaryButtonClick={handleEditProject}
      isLoading={isLoading}
      isPrimaryButtonDisabled={JSON.stringify(projectData) === JSON.stringify(data)}
    >
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        label={`${text.commonInputFields.name} *`}
        variant="standard"
        value={data?.name}
        disabled
        inputProps={{ 'data-testid': 'nameInputTest' }}
      />
      <TextField
        {...getNumberInputTextFieldCommonProps('dpi', NUMBER_FIELDS_NAMES.resolution, setResolutionErrorMessage, setProjectData, 'resolutionInputTest')}
        sx={{ mb: 4 }}
        label={`${text.commonInputFields.resolution} *`}
        helperText={resolutionErrorMessage}
        error={!!resolutionErrorMessage}
        defaultValue={data?.dotsPerInch}
      />
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        label={`${text.addProject.platform} *`}
        variant="standard"
        value={data?.platform}
        disabled
        inputProps={{ 'data-testid': 'platformInputTest' }}
      />
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        label={`${text.addProject.display} *`}
        variant="standard"
        value={data?.display}
        disabled
        inputProps={{ 'data-testid': 'displayInputTest' }}
      />
    </Modal>
  );
};

export default EditProject;

EditProject.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    platform: PropTypes.string,
    display: PropTypes.string,
    dotsPerInch: PropTypes.number
  })
};

EditProject.defaultProps = {
  isOpen: false,
  data: {}
};
