import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import snackbarReducer from './snackbarSlice';
import generalReducer from './generalSlice';
import sensorReducer from './sensorSlice';
import projectReducer from './projectSlice';
import customerReducer from './customerSlice';
import algorithmReducer from './algorithmSlice';
import databaseReducer from './databaseSlice';
import releaseReducer from './releaseSlice';

const reducer = {
  auth: authReducer,
  snackbar: snackbarReducer,
  general: generalReducer,
  sensor: sensorReducer,
  project: projectReducer,
  customer: customerReducer,
  algorithm: algorithmReducer,
  database: databaseReducer,
  release: releaseReducer
};

const combinedReducer = combineReducers(reducer);

export const rootReducer = (state, action) => {
  // clear state on logout
  if (action.type === 'auth/sessionExpired') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: true,
});
