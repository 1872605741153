import { createSlice } from '@reduxjs/toolkit';
import { fetchMetaData } from './api/general';

const initialState = {
  metaData: {},
  itemNotFoundData: null
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setItemNotFoundData: (state, action) => {
      state.itemNotFoundData = action.payload;
    }
  },
  extraReducers: {
    [fetchMetaData.fulfilled]: (state, action) => {
      state.metaData = action.payload.data;
    },
  },
});

export const { setItemNotFoundData } = generalSlice.actions;

export default generalSlice.reducer;
