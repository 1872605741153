import { MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import text from '../../../../../text.json';
import { MENU_ICON_STYLE, MENU_ITEM_STYLE } from '../../../OverviewsShared/config';
import UploadIndexFileModal from './UploadingModals/UploadIndexFileModal';
import UploadFingerprintDatasetModal from './UploadingModals/UploadFingerprintDatasetModal';
import { handleDownloadIndexFile } from './helper';

const DatabaseRowActions = ({ data }) => {
  const [isUploadIndexFileModalOpen, setIsUploadIndexFileModalOpen] = useState(false);
  const [isUploadFingerprintDatasetModalOpen,
    setIsUploadFingerprintDatasetModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { uploadingProcessData } = useSelector((state) => state.database);

  const dispatch = useDispatch();

  return (
    <>
      <MenuItem
        sx={{ ...MENU_ITEM_STYLE, pr: 1.5 }}
        {...(!data?.isIndexFileUploaded ? { divider: true } : {})}
        onClick={() => {
          setIsUploadIndexFileModalOpen(true);
        }}
      >
        <UploadIcon sx={MENU_ICON_STYLE} fontSize="small" />
        <Typography variant="body2" sx={{ mr: 1 }}>{text.buttons.uploadFile.replace('{{file}}', text.files.indexFile.toLowerCase())}</Typography>
        {!data?.isIndexFileUploaded && (
        <WarningIcon sx={{ ...MENU_ICON_STYLE, color: 'general.error' }} fontSize="small" />
        )}
      </MenuItem>
      {!!data?.isIndexFileUploaded && (
      <MenuItem
        sx={{ ...MENU_ITEM_STYLE, pr: 1.5 }}
        onClick={() => handleDownloadIndexFile(setIsDownloading, data, dispatch)}
        divider
        disabled={isDownloading}
      >
        <DownloadIcon sx={MENU_ICON_STYLE} fontSize="small" />
        <Typography variant="body2">
          {text.buttons.downloadFile.replace('{{file}}', text.files.indexFile.toLowerCase())}
        </Typography>
      </MenuItem>
      )}
      {!data?.isFingerprintDatasetsUploaded
      && (uploadingProcessData?.databaseData?.id !== data?.id
      || (uploadingProcessData?.databaseData?.id === data?.id
        && uploadingProcessData?.isFinished)) && (
        <MenuItem
          sx={{ ...MENU_ITEM_STYLE, pr: 1.5 }}
          onClick={() => {
            setIsUploadFingerprintDatasetModalOpen(true);
          }}
          divider
        >
          <UploadIcon sx={MENU_ICON_STYLE} fontSize="small" />
          <Typography variant="body2">{text.buttons.uploadFile.replace('{{file}}', text.files.fpdb.toLowerCase())}</Typography>
          <WarningIcon sx={{ ...MENU_ICON_STYLE, color: 'general.error', ml: 1 }} fontSize="small" />
        </MenuItem>
      )}
      {isUploadIndexFileModalOpen && (
      <UploadIndexFileModal
        handleClose={() => setIsUploadIndexFileModalOpen(false)}
        isOpen={isUploadIndexFileModalOpen}
        databaseData={data}
      />
      )}
      {isUploadFingerprintDatasetModalOpen && (
      <UploadFingerprintDatasetModal
        handleClose={() => setIsUploadFingerprintDatasetModalOpen(false)}
        isOpen={isUploadFingerprintDatasetModalOpen}
        databaseData={data}
      />
      )}
    </>
  );
};

export default DatabaseRowActions;

DatabaseRowActions.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    isIndexFileUploaded: PropTypes.bool,
    isFingerprintDatasetsUploaded: PropTypes.bool,
    isIndexFileValidated: PropTypes.bool,
  })
};

DatabaseRowActions.defaultProps = {
  data: {},
};
