import { createSlice } from '@reduxjs/toolkit';
import {
  addProject, editProject, fetchProject, fetchProjects, removeProject
} from './api/project';
import { highlightElementInArray } from './helper';

const initialState = {
  projects: [],
  project: {}
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
  },
  extraReducers: {
    [fetchProjects.fulfilled]: (state, action) => {
      state.projects = action.payload.data;
    },
    [fetchProject.fulfilled]: (state, action) => {
      state.project = action.payload.data;
    },
    [addProject.fulfilled]: (state, action) => {
      state.projects = [action.payload.data, ...state.projects];
    },
    [removeProject.fulfilled]: (state, action) => {
      state.projects = highlightElementInArray(state.projects, action.payload);
    },
    [editProject.fulfilled]: (state, action) => {
      state.projects = highlightElementInArray(state.projects, action.payload);
    },
  },
});

export const { setProjects, setProject } = projectSlice.actions;

export default projectSlice.reducer;
