import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import processAnimation from '../assets/lotties/build.json';

const BuildIcon = ({ style }) => (
  <Lottie
    style={style}
    animationData={processAnimation}
    loop
  />
);

export default BuildIcon;

BuildIcon.propTypes = {
  style: PropTypes.shape({})
};

BuildIcon.defaultProps = {
  style: {}
};
