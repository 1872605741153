import { Button, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

import { PALETTE } from '../../../../../../theme/constants';

export const ERROR_CHIP_TYPE = {
  WARNING: 'warning',
  INAVLID: 'invalid'
};

const ErrorChip = ({
  title, sx, isDisabled, onClick, type
}) => (
  <Button
    onClick={onClick}
    disabled={isDisabled}
    sx={{
      color: 'general.error',
      borderRadius: '100px',
      textTransform: 'none',
      backgroundColor: 'general.errorLight',
      border: `1px solid ${PALETTE.general.errorDark}`,
      py: 0.5,
      px: 1,
      '&:hover': {
        backgroundColor: 'general.errorDark',
        color: 'white'
      },
      '&:disabled': {
        backgroundColor: 'grey.50',
        border: `1px solid ${grey[500]}`,
      },
      ...sx
    }}
  >
    <Typography noWrap sx={{ display: 'flex', alignItems: 'center', fontWeight: 'fontWeightBold' }} variant="caption">
      {type === ERROR_CHIP_TYPE.WARNING && <WarningIcon fontSize="small" sx={{ mr: 0.5 }} />}
      {type === ERROR_CHIP_TYPE.INAVLID && <ErrorIcon fontSize="small" sx={{ mr: 0.5 }} />}
      {title}
    </Typography>
  </Button>

);

export default ErrorChip;

ErrorChip.propTypes = {
  title: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired
};

ErrorChip.defaultProps = {
  sx: {},
  isDisabled: false,
  onClick: () => {}
};
