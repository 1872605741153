import { GENERAL_ERROR_MESSAGE } from '../../config';
import { setErrorMessage } from '../../redux/snackbarSlice';
import { downloadRelease } from '../../services/releases';
import { BORDER_RADIUS, BOX_SHADOWS } from '../../theme/constants';
import { downloadFile } from '../../utils/downloadHelper';

export const openListItemHoverStyle = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomColor: 'transparent',
  boxShadow: 'none',
  '&:hover': {
    cursor: 'pointer',
    boxShadow: 'none',
    color: 'secondary.light',
  }
};

export const collapsedBoxStyle = {
  p: 4,
  mx: 2,
  borderBottomLeftRadius: BORDER_RADIUS.SMALL,
  borderRightLeftRadius: BORDER_RADIUS.SMALL,
  boxShadow: BOX_SHADOWS.CONTAINER,
  backgroundColor: 'white',
};

export const handleDownloadRelease = async (setIsDownloading, data, dispatch) => {
  try {
    setIsDownloading(true);
    const response = await downloadRelease(data?.id);
    const filename = response.headers?.['algo-downloadreleasename'] || '';
    downloadFile(response?.data, filename);
  } catch (error) {
    dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
  } finally {
    setIsDownloading(false);
  }
};
