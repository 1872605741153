import text from '../../../text.json';

export const columns = [
  {
    name: text.commonInputFields.name,
    key: 'name'
  },
  {
    name: text.addProject.display,
    key: 'displayType'
  },
  {
    name: text.addProject.resolution,
    key: 'dotsPerInch'
  },
  {
    name: text.addProject.platform,
    key: 'platform'
  },
  {
    name: '',
    key: 'action'
  }
];
