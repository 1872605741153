import Axios from 'axios';
import { API_URL } from '../config';

export const getAlgorithms = (projectId) => Axios.get(`${API_URL}/Algorithm`, { params: { projectId } });

export const checkIfAlgorithmVariantExist = (data) => Axios.post(`${API_URL}/Algorithm/CheckNameAvailability`, data);

export const postAlgorithm = (data, projectId) => Axios.post(`${API_URL}/Algorithm/${projectId}`, data);

export const deleteAlgorithm = (algorithmId) => Axios.put(`${API_URL}/Algorithm/RequestRemoval/${algorithmId}`);
