import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getActivityLogs, getCustomer, getCustomers, postCustomer
} from '../../services/customers';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchCustomerInfo = createAsyncThunk('customer/fetchCustomerInfo', async (customerId, thunkAPI) => {
  try {
    return await getCustomer(customerId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const fetchCustomers = createAsyncThunk('customer/fetchCustomers', async (customerId, thunkAPI) => {
  try {
    return await getCustomers();
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const fetchActivityLogs = createAsyncThunk('customer/fetchActivityLogs', async ({
  customerId,
  searchTerm,
  pageNumber,
  pageSize
}, thunkAPI) => {
  try {
    return await getActivityLogs(customerId, searchTerm, pageNumber, pageSize);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const addCustomer = createAsyncThunk('customer/addCustomer', async (customerData, thunkAPI) => {
  try {
    return await postCustomer(customerData);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
