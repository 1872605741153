import { createSlice } from '@reduxjs/toolkit';

import {
  addDatabase,
  addIndexFile,
  fetchDatabases,
  removeDatabase,
  updateDatabase
} from './api/database';
import { highlightElementInArray } from './helper';

const initialState = {
  databases: [],
  databaseFromLogsId: '',
  uploadingProcessData: null
};

export const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {
    setDatabases: (state, action) => {
      state.databases = action.payload;
    },
    setDatabaseFromLogsId: (state, action) => {
      state.databaseFromLogsId = action.payload;

      if (state.databases?.length && action.payload) {
        const databaseFromLogsIndex = state.databases?.findIndex(
          ({ id }) => id === state.databaseFromLogsId
        );
        if (databaseFromLogsIndex === 0 || databaseFromLogsIndex) {
          state.databases = highlightElementInArray(
            state.databases,
            { data: { ...state.databases?.[databaseFromLogsIndex] } }
          );

          const databases = [...state.databases];
          databases.splice(databaseFromLogsIndex, 1);
          state.databases = [
            state.databases?.[databaseFromLogsIndex],
            ...databases
          ];
        }
      }
    },
    setUploadingProcessData: (state, action) => {
      state.uploadingProcessData = action.payload;
    },
  },
  extraReducers: {
    [fetchDatabases.fulfilled]: (state, action) => {
      let databaseFromLogsIndex = null;
      if (state.databaseFromLogsId) {
        databaseFromLogsIndex = action.payload.data?.findIndex(
          ({ id }) => id === state.databaseFromLogsId
        );
      }
      if (databaseFromLogsIndex === 0 || databaseFromLogsIndex) {
        const databases = [...action.payload.data];
        databases.splice(databaseFromLogsIndex, 1);
        state.databases = [
          { ...action.payload.data?.[databaseFromLogsIndex], isHighlighted: true },
          ...databases
        ];
      } else {
        state.databases = action.payload.data;
      }
    },
    [addDatabase.fulfilled]: (state, action) => {
      state.databases = [action.payload.data, ...state.databases];
      state.databaseFromLogsId = '';
    },
    [addIndexFile.fulfilled]: (state, action) => {
      state.databases = highlightElementInArray(state.databases, action.payload);
      state.databaseFromLogsId = '';
    },
    [removeDatabase.fulfilled]: (state, action) => {
      state.databases = highlightElementInArray(state.databases, action.payload);
      state.databaseFromLogsId = '';
    },
    [updateDatabase.fulfilled]: (state, action) => {
      state.databases = highlightElementInArray(state.databases, action.payload);
      state.databaseFromLogsId = '';
    },
  },
});

export const {
  setDatabases,
  setDatabaseFromLogsId,
  setUploadingProcessData,
} = databaseSlice.actions;

export default databaseSlice.reducer;
