import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { fetchProjects } from '../../../redux/api/project';
import SensorDetails from '../OverviewsShared/SensorDetails';
import { columns } from './config';
import {
  ADDING_REQUESTED, REMOVAL_REQUESTED, ROUTES
} from '../../../config';
import { setProjects } from '../../../redux/projectSlice';
import Table from '../OverviewsShared/Table';
import AddProject from '../../Modals/AddProject';
import text from '../../../text.json';
import RemoveProject from '../../Modals/RemoveProject';
import EditProject from '../../Modals/EditProject';

const Projects = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredProjects, setFilteredProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isRemoveProjectModalOpen, setIsRemoveProjectModalOpen] = useState(false);
  const [isEditProjectModalOpen, setIsEditProjectModalOpen] = useState(false);
  const [rowActionProjectData, setRowActionProjectData] = useState({});
  const [tableColumns, setTableColumns] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.project);
  const { sensor } = useSelector((state) => state.sensor);
  const { metaData } = useSelector((state) => state.general);
  const { sensorId } = useParams();

  const isSensorTypeDpiEditable = metaData
    ?.sensorTypes?.find(({ name }) => name === sensor?.sensorType)?.isDotsPerInchEditable;

  useEffect(() => {
    (async () => {
      await dispatch(fetchProjects(sensorId));
      setIsLoading(false);
    })();

    return () => {
      dispatch(setProjects([]));
    };
  }, []);

  useEffect(() => {
    if (projects?.length) {
      setFilteredProjects(projects.filter(
        ({
          name, dotsPerInch, platform, displayType, isPendingToRemove, isPendingToAdd
        }) => (
          name?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || dotsPerInch?.toString().toLowerCase().includes(searchValue.toLocaleLowerCase())
          || platform?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || displayType?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || (isPendingToRemove && REMOVAL_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          ))
          || (isPendingToAdd && ADDING_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          )))
      ));
    } else if (!isLoading && !projects?.length) {
      setFilteredProjects([]);
    }
  }, [searchValue, projects, isLoading]);

  useEffect(() => {
    if (Object.keys(sensor).length !== 0 && !isSensorTypeDpiEditable) {
      setTableColumns(columns.filter(
        ({ name }) => name !== text.addProject.resolution
      ));
    } else if (Object.keys(sensor).length !== 0) {
      setTableColumns(columns);
    }
  }, [sensor]);

  return (
    <>
      <Box sx={{ height: 'calc(100% - 12px)' }} display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
        <Table
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isLoading={isLoading}
          rows={filteredProjects}
          onRowClick={(projectId) => navigate(`${location.pathname}/${ROUTES.PROJECTS}/${projectId}`)}
          columns={tableColumns}
          buttonText={`+ ${text.buttons.add.replace('{{object}}', text.objects.project)}`}
          onButtonClick={() => setIsAddProjectModalOpen(true)}
          isButtonDisabled={sensor?.isPendingToRemove}
          disabledButtonTooltipText={text.tooltips.sensorToBeRemoved}
          handleRemove={(projectData) => {
            setRowActionProjectData(projectData);
            setIsRemoveProjectModalOpen(true);
          }}
          {...(isSensorTypeDpiEditable ? {
            handleEdit: (sensorData) => {
              setRowActionProjectData(sensorData);
              setIsEditProjectModalOpen(true);
            }
          } : {})}
        />
        <SensorDetails />
      </Box>
      {isAddProjectModalOpen && (
        <AddProject
          handleClose={() => setIsAddProjectModalOpen(false)}
          isOpen={isAddProjectModalOpen}
        />
      )}
      {isRemoveProjectModalOpen && (
        <RemoveProject
          handleClose={() => {
            setIsRemoveProjectModalOpen(false);
            setRowActionProjectData({});
          }}
          isOpen={isRemoveProjectModalOpen}
          data={rowActionProjectData}
        />
      )}
      {isEditProjectModalOpen && (
        <EditProject
          handleClose={() => {
            setIsEditProjectModalOpen(false);
            setRowActionProjectData({});
          }}
          isOpen={isEditProjectModalOpen}
          data={rowActionProjectData}
        />
      )}
    </>
  );
};

export default Projects;
