import { useState } from 'react';
import {
  Box,
  Button,
  Collapse, ListItemButton, ListItemText, Typography
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import Proptypes from 'prop-types';
import { NewReleases } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { BORDER_RADIUS, BOX_SHADOWS, PALETTE } from '../../../theme/constants';
import text from '../../../text.json';
import Algorithms from './Algorithms';
import { ReactComponent as AlgorithmIcon } from '../../../assets/icons/algorithm.svg';
import { collapsedBoxStyle, handleDownloadRelease, openListItemHoverStyle } from '../helper';
import Restricted from '../../../shared/Restricted';
import { ADMIN_ROLES } from '../../../config';

const Release = ({ data, index }) => {
  const [isOpen, setIsOpen] = useState(index === 0);
  const [isAlgorithmsModalOpen, setIsAlgorithmsModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <ListItemButton
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: !data?.buildFailedMessage ? 'white' : 'general.errorLight',
          mt: 3.5,
          mx: 2,
          borderRadius: BORDER_RADIUS.SMALL,
          p: 2,
          color: 'secondary.main',
          '&:hover': {
            backgroundColor: !data?.buildFailedMessage ? 'white' : 'general.errorLight',
            boxShadow: BOX_SHADOWS.CONTAINER
          },
          ...isOpen ? { ...openListItemHoverStyle, '&:hover': { backgroundColor: !data?.buildFailedMessage ? 'white' : 'general.errorLight' } } : {},
        }}
        onClick={() => setIsOpen((prevState) => !prevState)}
        disableRipple
        disableGutters
        disableTouchRipple
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {index === 0 && !data?.buildFailedMessage && <NewReleases sx={{ color: 'general.info', mr: 1 }} />}
            {data?.buildFailedMessage && (
            <ErrorIcon
              fontSize="small"
              sx={{
                color: 'general.error',
                backgroundColor: 'white',
                borderRadius: '50%',
                mr: 1
              }}
            />
            )}
            <ListItemText
              sx={{
                span: {
                  fontWeight: 'fontWeightBold',
                  color: !data?.buildFailedMessage ? 'secondary.main' : 'general.error'
                }
              }}
              primary={data?.name}
            />
          </Box>
          {data?.buildFailedMessage && index === 0 && (
          <Box>
            <Typography
              sx={{
                fontWeight: 'fontWeightBold', color: 'general.error', ml: 0.5, mb: 1
              }}
              variant="caption"
            >
              {text.releases.releaseInBuildFail}
            </Typography>
          </Box>
          )}
        </Box>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box sx={{
          ...collapsedBoxStyle,
          ...(data?.buildFailedMessage ? {
            border: `2px solid ${PALETTE.general.errorLight}`,
            borderTopColor: 'transparent'
          } : {})
        }}
        >
          {data?.buildFailedMessage && (
          <Typography variant="body2" sx={{ width: '70%', color: 'general.error', mb: 4 }}>{data.buildFailedMessage}</Typography>
          )}
          {index === 0 && !data?.buildFailedMessage ? (
            <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold', color: 'general.infoDark' }}>
              {text.releases.news}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>
              {text.releases.note}
            </Typography>
          )}
          <Typography variant="body2" sx={{ mt: 2, width: '80%', whiteSpace: 'pre-line' }}>{data?.notes}</Typography>
          <Box sx={{ mt: 5, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="link"
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={() => setIsAlgorithmsModalOpen(true)}
              startIcon={<AlgorithmIcon />}
            >
              {text.buttons.viewAlgorithms}
            </Button>
            <Restricted to={[ADMIN_ROLES.PRECISE]}>
              <Button
                sx={{ ml: 5 }}
                variant="link"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                disabled={isDownloading || !data?.zipUrl}
                onClick={() => handleDownloadRelease(setIsDownloading, data, dispatch)}
                startIcon={<DownloadIcon />}
              >
                {text.buttons.download}
              </Button>
            </Restricted>
          </Box>
        </Box>
      </Collapse>
      {isAlgorithmsModalOpen && (
        <Algorithms
          releaseData={data}
          handleClose={() => setIsAlgorithmsModalOpen(false)}
          isOpen={isAlgorithmsModalOpen}
        />
      )}
    </>
  );
};

export default Release;

Release.propTypes = {
  data: Proptypes.shape({
    id: Proptypes.string,
    name: Proptypes.string,
    notes: Proptypes.string,
    buildFailedMessage: Proptypes.string,
    zipUrl: Proptypes.string,
  }).isRequired,
  index: Proptypes.number.isRequired
};
