import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { setAlgorithms } from '../../../../../redux/algorithmSlice';
import { fetchDatabases } from '../../../../../redux/api/database';
import SensorDetails from '../../../OverviewsShared/SensorDetails';
import Table from '../../../OverviewsShared/Table';
import { columns } from './config';
import text from '../../../../../text.json';
import AddDatabase from '../../../../Modals/AddDatabase';
import ErrorChip, { ERROR_CHIP_TYPE } from './Chips/ErrorChip';
import StaticChip, { STATIC_CHIP_TYPE } from './Chips/StaticChip';
import UploadIndexFileModal from './UploadingModals/UploadIndexFileModal';
import { setDatabaseFromLogsId } from '../../../../../redux/databaseSlice';
import UploadFingerprintDatasetModal from './UploadingModals/UploadFingerprintDatasetModal';
import { ADDING_REQUESTED, ADMIN_ROLES, REMOVAL_REQUESTED } from '../../../../../config';
import InvalidIndexFileModal from './ErrorModals/InvalidIndexFileModal';
import { setItemNotFoundData } from '../../../../../redux/generalSlice';
import RemoveDatabase from '../../../../Modals/RemoveDatabase';
import Restricted from '../../../../../shared/Restricted';

const Databases = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredDatabases, setFilteredDatabases] = useState(null);
  const [transformedDatabases, setTransformedDatabases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDatabaseModalOpen, setIsAddDatabaseModalOpen] = useState(false);
  const [isUploadIndexFileModalOpen, setIsUploadIndexFileModalOpen] = useState(false);
  const [isUploadFingerprintDatasetModalOpen,
    setIsUploadFingerprintDatasetModalOpen] = useState(false);
  const [isInvalidIndexFileModalOpen, setIsInvalidIndexFileModalOpen] = useState(false);
  const [chosenDatabaseDataForUploadFile, setChosenDatabaseDataForUploadFile] = useState('');
  const [isRemoveDatabaseModalOpen, setIsRemoveDatabaseModalOpen] = useState(false);
  const [rowActionDatabaseData, setRowActionDatabaseData] = useState({});

  const dispatch = useDispatch();
  const { databases, databaseFromLogsId } = useSelector((state) => state.database);
  const { project } = useSelector((state) => state.project);
  const { uploadingProcessData } = useSelector((state) => state.database);
  const { projectId, customerId } = useParams();
  const { state } = useLocation();

  useEffect(() => () => {
    dispatch(setDatabaseFromLogsId(''));
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(fetchDatabases(projectId));
      dispatch(setAlgorithms([]));
      setIsLoading(false);
    })();
  }, [projectId]);

  useEffect(() => {
    if (databases?.length
      && databaseFromLogsId
      && databaseFromLogsId !== databases[0]?.id
      && ((customerId && databases[0]?.customerId === customerId)
      || !customerId)) {
      dispatch(setItemNotFoundData({ name: state?.objectName }));
    }
  }, [databaseFromLogsId, databases]);

  const getIndexFileStatusComponent = (database) => {
    switch (!!database?.isIndexFileUploaded) {
      case true:
        if (database?.isIndexFileValidated) {
          return (
            database?.indexFileErrorMessage ? (
              <ErrorChip
                type={ERROR_CHIP_TYPE.INAVLID}
                sx={{ mb: 1 }}
                title={text.databases.invalidFile.replace('{{file}}', text.files.indexFile.toLocaleLowerCase())}
                onClick={() => {
                  setIsInvalidIndexFileModalOpen(true);
                  setChosenDatabaseDataForUploadFile(database);
                }}
              />
            ) : (
              <StaticChip
                sx={{ mb: 1 }}
                title={text.files.indexFile}
                type={STATIC_CHIP_TYPE.SUCCESS}
              />
            )
          );
        }
        return (
          <StaticChip
            sx={{ mb: 1 }}
            title={text.databases.validatingFile.replace('{{file}}', text.files.indexFile.toLowerCase())}
            type={STATIC_CHIP_TYPE.VALIDATING}
          />
        );
      default:
        return (
          <Restricted
            to={[ADMIN_ROLES.PRECISE]}
            componentToRenderInstead={(
              <StaticChip
                title={text.databases.missingFile.replace('{{file}}', text.files.indexFile.toLowerCase())}
                type={STATIC_CHIP_TYPE.ERROR}
              />
            )}
          >
            <ErrorChip
              type={ERROR_CHIP_TYPE.WARNING}
              isDisabled={database?.isPendingToAdd || database?.isPendingToRemove}
              sx={{ mb: 1 }}
              title={text.databases.missingFile.replace('{{file}}', text.files.indexFile.toLowerCase())}
              onClick={() => {
                setIsUploadIndexFileModalOpen(true);
                setChosenDatabaseDataForUploadFile(database);
              }}
            />
          </Restricted>
        );
    }
  };

  const getFingerprintDatasetStatusComponent = (database) => {
    switch (!!database?.isFingerprintDatasetsUploaded) {
      case true:
        return (
          <StaticChip title={text.files.fpdb} type={STATIC_CHIP_TYPE.SUCCESS} />
        );
      default:
        return (!uploadingProcessData?.isFinished
           && uploadingProcessData?.databaseData?.id === database?.id
          ? (
            <StaticChip
              title={text.databases.uploadingFile.replace('{{file}}', text.files.fpdb.toLocaleLowerCase())}
              type={STATIC_CHIP_TYPE.UPLOADING}
            />
          )
          : (
            <Restricted
              to={[ADMIN_ROLES.PRECISE]}
              componentToRenderInstead={(
                <StaticChip
                  title={text.databases.missingFile.replace('{{file}}', text.files.fpdb.toLocaleLowerCase())}
                  type={STATIC_CHIP_TYPE.ERROR}
                />
            )}
            >
              <ErrorChip
                type={ERROR_CHIP_TYPE.WARNING}
                isDisabled={database?.isPendingToAdd || database?.isPendingToRemove}
                title={text.databases.missingFile.replace('{{file}}', text.files.fpdb.toLocaleLowerCase())}
                onClick={() => {
                  setIsUploadFingerprintDatasetModalOpen(true);
                  setChosenDatabaseDataForUploadFile(database);
                }}
              />
            </Restricted>
          )
        );
    }
  };

  useEffect(() => {
    if (databases?.length) {
      setTransformedDatabases(databases?.map((database) => {
        const databaseTmp = {
          ...database,
          status: (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {getIndexFileStatusComponent(database)}
              {getFingerprintDatasetStatusComponent(database)}
            </Box>
          ),
        };
        return databaseTmp;
      }));
    } else if (!isLoading && !databases?.length) {
      setFilteredDatabases([]);
    }
  }, [databases, uploadingProcessData, isLoading]);

  useEffect(() => {
    if (transformedDatabases?.length) {
      setFilteredDatabases(transformedDatabases.filter(
        ({
          name, collectionCountry, createdAt, isPendingToRemove, isPendingToAdd
        }) => (
          name?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || collectionCountry?.toString()?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || createdAt?.toString()?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || (isPendingToRemove && REMOVAL_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          ))
          || (isPendingToAdd && ADDING_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          )))
      ));
    }
  }, [searchValue, transformedDatabases]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" sx={{ height: '100%' }}>
        <Table
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isLoading={isLoading}
          rows={filteredDatabases}
          columns={columns}
          buttonText={`+ ${text.buttons.add.replace('{{object}}', text.objects.database)}`}
          isButtonDisabled={project?.isPendingToRemove}
          onButtonClick={() => setIsAddDatabaseModalOpen(true)}
          disabledButtonTooltipText={text.tooltips.projectToBeRemoved}
          handleRemove={(databaseData) => {
            setRowActionDatabaseData(databaseData);
            setIsRemoveDatabaseModalOpen(true);
          }}
          isDatabaseOverview
        />
        <SensorDetails />
      </Box>
      {isAddDatabaseModalOpen && (
      <AddDatabase
        handleClose={() => setIsAddDatabaseModalOpen(false)}
        isOpen={isAddDatabaseModalOpen}
      />
      )}
      {isUploadIndexFileModalOpen && (
      <UploadIndexFileModal
        handleClose={() => setIsUploadIndexFileModalOpen(false)}
        isOpen={isUploadIndexFileModalOpen}
        databaseData={chosenDatabaseDataForUploadFile}
      />
      )}
      {isUploadFingerprintDatasetModalOpen && (
      <UploadFingerprintDatasetModal
        handleClose={() => setIsUploadFingerprintDatasetModalOpen(false)}
        isOpen={isUploadFingerprintDatasetModalOpen}
        databaseData={chosenDatabaseDataForUploadFile}
      />
      )}
      {isInvalidIndexFileModalOpen && (
      <InvalidIndexFileModal
        handleClose={() => setIsInvalidIndexFileModalOpen(false)}
        isOpen={isInvalidIndexFileModalOpen}
        databaseData={chosenDatabaseDataForUploadFile}
      />
      )}
      {isRemoveDatabaseModalOpen && (
        <RemoveDatabase
          handleClose={() => {
            setIsRemoveDatabaseModalOpen(false);
            setRowActionDatabaseData({});
          }}
          isOpen={isRemoveDatabaseModalOpen}
          data={rowActionDatabaseData}
        />
      )}
    </>
  );
};

export default Databases;
