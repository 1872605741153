import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

import Modal from '../../shared/Modal';
import { getNameErrorMessage } from './validation';
import { getNumberInputTextFieldCommonProps, NUMBER_FIELDS_NAMES } from './helper';
import SelectInput from '../../shared/SelectInput';
import { setErrorMessage } from '../../redux/snackbarSlice';
import { GENERAL_ERROR_MESSAGE, NAME_MAX_NUMBER_CHARACTERS } from '../../config';
import { checkIfProjectNameExist } from '../../services/projects';
import { addProject } from '../../redux/api/project';
import text from '../../text.json';
import { PALETTE } from '../../theme/constants';
import Message, { MESSAGE_TYPE } from '../../shared/Message';

const AddProject = ({ handleClose, isOpen }) => {
  const { metaData } = useSelector((state) => state.general);
  const { sensor } = useSelector((state) => state.sensor);
  const dispatch = useDispatch();
  const { sensorId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [resolutionErrorMessage, setResolutionErrorMessage] = useState('');
  const [projectData, setProjectData] = useState({
    name: '',
    displayTypeId: null,
    platformId: metaData?.platforms?.[0]?.id || '',
    dotsPerInch: sensor?.dotsPerInch
  });

  const isSensorTypeDpiEditable = metaData
    ?.sensorTypes?.find(({ name }) => name === sensor?.sensorType)?.isDotsPerInchEditable;

  const handleAddProject = async () => {
    const {
      name, dotsPerInch
    } = projectData;

    let isDataValid = true;
    setIsLoading(true);

    try {
      const nameErrorMessageTmp = await
      getNameErrorMessage(name, () => checkIfProjectNameExist({ name, sensorId }));

      if (nameErrorMessageTmp) {
        setNameErrorMessage(nameErrorMessageTmp);
        isDataValid = false;
      }
    } catch (error) {
      dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
      isDataValid = false;
    }

    if (isSensorTypeDpiEditable && dotsPerInch !== 0 && !dotsPerInch) {
      setResolutionErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.commonInputFields.resolution));
      isDataValid = false;
    }

    if (isDataValid) {
      if (projectData.dotsPerInch === sensor?.dotsPerInch) {
        projectData.dotsPerInch = null;
      }

      const result = await dispatch(addProject({ projectData, sensorId }));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.add.title.replace('{{object}}', text.objects.project)}
      primaryButtonText={text.buttons.add.replace('{{object}}', text.objects.project)}
      onPrimaryButtonClick={handleAddProject}
      isLoading={isLoading}
    >
      <Message type={MESSAGE_TYPE.WARNING} message={text.addProject.warningMessage} />
      <TextField
        sx={{ mb: 4, '.MuiInputLabel-root': { zIndex: 1 } }}
        fullWidth
        label={`${text.commonInputFields.name} *`}
        variant="standard"
        onChange={(event) => {
          setProjectData((prevProjectData) => (
            { ...prevProjectData, name: event.target.value }));
          setNameErrorMessage('');
        }}
        helperText={nameErrorMessage}
        error={!!nameErrorMessage}
        inputProps={{
          'data-testid': 'nameInputTest',
          maxLength: NAME_MAX_NUMBER_CHARACTERS,
          style: { backgroundColor: PALETTE.general.warningMessageLight }
        }}
      />
      {isSensorTypeDpiEditable && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            {...getNumberInputTextFieldCommonProps(
              'dpi',
              NUMBER_FIELDS_NAMES.resolution,
              setResolutionErrorMessage,
              setProjectData,
              'resolutionInputTest'
            )}
            sx={{ mb: 4 }}
            defaultValue={sensor?.dotsPerInch}
            label={`${text.commonInputFields.resolution} *`}
            helperText={resolutionErrorMessage}
            error={!!resolutionErrorMessage}
          />
          <Tooltip title={text.addProject.resolutionDescription} placement="top">
            <InfoIcon fontSize="small" sx={{ ml: 2, color: 'general.info' }} />
          </Tooltip>
        </Box>
      )}
      <SelectInput
        sx={{ mb: 4, '.MuiInput-root': { backgroundColor: 'general.warningMessageLight' } }}
        label={`${text.addProject.platform} *`}
        value={metaData?.platforms?.find(
          ({ id }) => projectData.platformId === id
        )?.id || ''}
        handleChange={(event) => setProjectData(
          (prevProjectData) => ({ ...prevProjectData, platformId: event.target.value })
        )}
        options={metaData?.platforms}
      />
      <SelectInput
        label={text.addProject.display}
        value={metaData?.displayTypes?.find(
          ({ id }) => projectData.displayTypeId === id
        )?.id || ''}
        handleChange={(event) => setProjectData(
          (prevProjectData) => ({ ...prevProjectData, displayTypeId: event.target.value })
        )}
        options={metaData?.displayTypes}
        sx={{ '.MuiInput-root': { backgroundColor: 'general.warningMessageLight' } }}
      />
    </Modal>
  );
};

export default AddProject;

AddProject.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

AddProject.defaultProps = {
  isOpen: false
};
