import { ListItemButton, ListItemText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate, useMatch } from 'react-router-dom';

const NavigationLink = ({
  text, icon: Icon, to, sx
}) => {
  const navigate = useNavigate();

  const checkIsLinkActive = (route) => useMatch({ path: route, end: false });

  return (
    <ListItemButton
      sx={{ ...sx }}
      selected={!!checkIsLinkActive(to)}
      variant="sidebarLink"
      onClick={() => navigate(to)}
    >
      <Icon
        width={30}
        height={30}
        alt="sidebar link icon"
      />
      <ListItemText disableTypography>
        <Typography variant="body1" sx={{ marginLeft: '12px', fontWeight: checkIsLinkActive(to) ? 'fontWeightBold' : 'fontWeightRegular' }}>
          {text}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};

NavigationLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  sx: PropTypes.shape({})
};

NavigationLink.defaultProps = {
  sx: {}
};

export default NavigationLink;
