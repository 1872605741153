import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import { BORDER_RADIUS } from '../theme/constants';

const SearchInput = ({ placeholder, value, setValue }) => (
  <TextField
    label={placeholder}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    variant="standard"
    sx={{
      backgroundColor: 'white',
      px: 2,
      pb: 1.5,
      width: '100%',
      borderRadius: BORDER_RADIUS.MEDIUM,
      '.MuiInputLabel-root': {
        pl: 2,
      },
    }}
    inputProps={{
      'data-testid': 'searchInputTest',
    }}
  />
);

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default SearchInput;
