import Axios from 'axios';
import { API_URL } from '../config';

export const getSensors = (customerId) => Axios.get(`${API_URL}/Sensor`, { params: { customerId } });

export const getSensor = (sensorId) => Axios.get(`${API_URL}/Sensor/${sensorId}`);

export const postSensor = (data, customerId) => Axios.post(`${API_URL}/Sensor/${customerId}`, data);

export const checkIfSensorNameExist = (data) => Axios.post(`${API_URL}/Sensor/CheckNameAvailability`, data);

export const deleteSensor = (sensorId) => Axios.put(`${API_URL}/Sensor/RequestRemoval/${sensorId}`);

export const updateSensor = (data, sensorId) => Axios.put(`${API_URL}/Sensor/${sensorId}`, data);
