import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import Modal from '../../../../../../shared/Modal';
import text from '../../../../../../text.json';
import CustomDropzone from '../../../../../../shared/CustomDropzone';
import ItemFlag from '../../../../../../shared/ItemFlag';
import { addIndexFile } from '../../../../../../redux/api/database';
import { INDEX_FILE_ALLOWED_FILE_TYPE } from '../config';

const UploadIndexFileModal = ({ handleClose, isOpen, databaseData }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleUpload = async () => {
    setIsLoading(true);
    const file = new FormData();
    file.append('file', files[0]);
    const result = await dispatch(addIndexFile({ databaseId: databaseData?.id, file }));
    if (result?.error) {
      setIsLoading(false);
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.buttons.uploadFile.replace('{{file}}', text.files.indexFile.toLowerCase())}
      primaryButtonText={text.buttons.upload}
      onPrimaryButtonClick={handleUpload}
      isLoading={isLoading}
      isPrimaryButtonDisabled={!files?.length}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <ItemFlag label={text.objects.database} />
        <Typography variant="body2">{databaseData?.name}</Typography>
      </Box>
      <CustomDropzone
        maxFiles={1}
        accept={INDEX_FILE_ALLOWED_FILE_TYPE}
        fileTypeName={text.files.indexFile}
        files={files}
        setFiles={setFiles}
        isLoading={isLoading}
        doesFileAlreadyExists={!!databaseData?.isIndexFileUploaded}
      />
    </Modal>
  );
};

export default UploadIndexFileModal;

UploadIndexFileModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  databaseData: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    isIndexFileUploaded: PropTypes.bool
  }).isRequired
};

UploadIndexFileModal.defaultProps = {
  isOpen: false,
};
