import { createSlice } from '@reduxjs/toolkit';

import {
  addCustomer, fetchActivityLogs, fetchCustomerInfo, fetchCustomers
} from './api/customer';
import { buildRelease, fetchReleases } from './api/release';

const initialState = {
  customerInfo: null,
  customers: [],
  activityLogs: {}
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setActivityLogs: (state, action) => {
      state.activityLogs = action.payload;
    }
  },
  extraReducers: {
    [fetchCustomerInfo.fulfilled]: (state, action) => {
      state.customerInfo = action.payload.data;
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload.data;
    },
    [fetchActivityLogs.fulfilled]: (state, action) => {
      state.activityLogs = action.payload.data;
    },
    [addCustomer.fulfilled]: (state, action) => {
      state.customers = [action.payload.data, ...state.customers];
    },
    [buildRelease.fulfilled]: (state, action) => {
      if (state.customerInfo && Object.keys(state.customerInfo)?.length) {
        state.customerInfo = { ...state.customerInfo, latestRelease: action.payload.data };
      }

      if (state.customers?.length) {
        const updatedCustomerInfoIndex = state.customers?.findIndex(
          ({ id }) => id === action.payload.data?.customerId
        );

        if (updatedCustomerInfoIndex === 0 || updatedCustomerInfoIndex) {
          const customersTmp = [...state.customers];
          customersTmp.splice(
            updatedCustomerInfoIndex,
            1,
            { ...state.customers[updatedCustomerInfoIndex], latestRelease: action.payload.data }
          );

          state.customers = customersTmp;
        }
      }
    },
    [fetchReleases.fulfilled]: (state, action) => {
      if ((action.payload.data?.[0]
        && JSON.stringify(action.payload.data[0])
        !== JSON.stringify(state.customerInfo.latestRelease)) || (
        !action.payload.data.length
          && state.customerInfo.latestRelease
          && Object.keys(state.customerInfo.latestRelease).length
      )) {
        state.customerInfo = {
          ...state.customerInfo,
          latestRelease: action.payload.data?.[0] || {}
        };
      }
    },
  },
});

export const { setCustomerInfo, setActivityLogs } = customerSlice.actions;

export default customerSlice.reducer;
