import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Modal from '../../../../../../shared/Modal';
import text from '../../../../../../text.json';
import CustomDropzone from '../../../../../../shared/CustomDropzone';
import ItemFlag from '../../../../../../shared/ItemFlag';
import { setUploadingProcessData } from '../../../../../../redux/databaseSlice';
import { FPDB_ALLOWED_FILE_TYPE } from '../config';
import Message, { MESSAGE_TYPE } from '../../../../../../shared/Message';

const UploadFingerprintDatasetModal = ({ handleClose, isOpen, databaseData }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { uploadingProcessData } = useSelector((state) => state.database);
  const { customerInfo } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const { sensorId, projectId } = useParams();

  const isUploadingInProcess = uploadingProcessData
  && !!Object.keys(uploadingProcessData)?.length
  && !uploadingProcessData?.isFinished;

  const handleUpload = async () => {
    setIsLoading(true);
    const data = {
      databaseData: {
        ...databaseData,
        sensorId,
        projectId,
        customerId: customerInfo?.id
      },
      file: files[0]
    };
    await dispatch(setUploadingProcessData(data));
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.buttons.uploadFile.replace('{{file}}', text.files.fpdb.toLowerCase())}
      primaryButtonText={text.buttons.upload}
      onPrimaryButtonClick={handleUpload}
      isLoading={isLoading}
      isPrimaryButtonDisabled={!files?.length}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <ItemFlag label={text.objects.database} />
        <Typography variant="body2">{databaseData?.name}</Typography>
      </Box>
      {isUploadingInProcess && (
        <Message
          type={MESSAGE_TYPE.INFO}
          message={text.databases.alreadyUploadingFile.replace('{{database}}', uploadingProcessData?.databaseData?.name)}
          sx={{ mb: 4 }}
        />
      )}
      <CustomDropzone
        maxFiles={1}
        accept={FPDB_ALLOWED_FILE_TYPE}
        fileTypeName={text.files.fpdb}
        files={files}
        setFiles={setFiles}
        isDisabled={isUploadingInProcess}
      />
    </Modal>
  );
};

export default UploadFingerprintDatasetModal;

UploadFingerprintDatasetModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  databaseData: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  }).isRequired
};

UploadFingerprintDatasetModal.defaultProps = {
  isOpen: false,
};
