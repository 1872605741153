import { Typography } from '@mui/material';

import { noDataFound } from '../assets/icons';
import text from '../text.json';

const NoDataFound = () => (
  <>
    <img width="100px" src={noDataFound} alt="no data found" />
    <Typography variant="body2" sx={{ color: 'gray.dark', mt: 1, fontWeight: 'fontWeightBold' }}>
      {text.infoMessages.noData}
    </Typography>
  </>
);

export default NoDataFound;
