import { Outlet } from 'react-router-dom';

import CustomBreadcrumbs from '../components/Sensors/OverviewsShared/Breadcrumbs';

const BreadcrumbsLayout = () => (
  <>
    <CustomBreadcrumbs />
    <Outlet />
  </>
);

export default BreadcrumbsLayout;
