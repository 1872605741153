import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ROLES } from '../config';
import { getCurrentUserRole } from '../utils/authHelper';

const Restricted = ({ children, to, componentToRenderInstead }) => {
  const [isUserAllowedTo, setIsUserAllowedTo] = useState(false);

  const { loggedUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (to && loggedUser) {
      (async () => {
        if (getCurrentUserRole(loggedUser) === ROLES.USER) {
          setIsUserAllowedTo(true);
        } else {
          setIsUserAllowedTo(loggedUser?.groups?.some((group) => to.includes(group)));
        }
      })();
    }
  }, [to]);

  return (
    isUserAllowedTo ? children : componentToRenderInstead
  );
};

export default Restricted;

Restricted.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.array.isRequired,
  componentToRenderInstead: PropTypes.node
};

Restricted.defaultProps = {
  componentToRenderInstead: null
};
