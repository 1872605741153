import text from '../../text.json';
import { NUMBER_FIELDS_NAMES } from '../Modals/helper';

export const columns = [
  {
    name: text.commonInputFields.name,
    key: 'name'
  },
  {
    name: text.commonInputFields.type,
    key: 'sensorType'
  },
  {
    name: text.addSensor.size,
    key: 'size'
  },
  {
    name: text.addSensor.resolution,
    key: NUMBER_FIELDS_NAMES.resolution
  },
  {
    name: '',
    key: 'action'
  }
];
