import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { buildRelease } from '../../redux/api/release';
import Modal from '../../shared/Modal';
import text from '../../text.json';

const BuildRelease = ({
  handleClose, isOpen, customerId, title
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleBuildRelease = async () => {
    setIsLoading(true);
    const result = await dispatch(buildRelease({ customerId }));
    if (result?.error) {
      setIsLoading(false);
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={title || text.buildRelease.question}
      primaryButtonText={text.buttons.build.replace('{{object}}', text.objects.release)}
      secondaryButtonText={text.buttons.cancel}
      onPrimaryButtonClick={handleBuildRelease}
      onSecondaryButtonClick={handleClose}
      isLoading={isLoading}
    />
  );
};

export default BuildRelease;

BuildRelease.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  customerId: PropTypes.string.isRequired,
  title: PropTypes.string
};

BuildRelease.defaultProps = {
  isOpen: false,
  title: ''
};
