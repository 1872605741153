import { createTheme, responsiveFontSizes } from '@mui/material';
import { sidebarWidth } from '../config';
import { BUTTONS } from './buttons';
import { BORDER_RADIUS, PALETTE } from './constants';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1500,
    },
  },
  palette: PALETTE,
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    htmlFontSize: 14,
  },
  MuiCssBaseline: {
    '@global': {
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
    },
  },
  components: {
    MuiButton: {
      variants: Object.values(BUTTONS),
    },
    MuiTableRow: {
      variants: [
        {
          props: {
            variant: 'clickable',
          },
          style: {
            '&:hover': {
              backgroundColor: PALETTE.gray.hover,
              cursor: 'pointer',
            },
          },
        },
      ],
    },
    MuiListItemButton: {
      variants: [
        {
          props: {
            variant: 'sidebarLink',
          },
          style: {
            borderRadius: BORDER_RADIUS.MEDIUM,
            marginTop: 10,
            padding: '8px 15px',
            '&.Mui-selected': {
              backgroundColor: PALETTE.secondary.light10,
              borderRadius: BORDER_RADIUS.MEDIUM,
              '&:hover': {
                backgroundColor: PALETTE.secondary.light10,
              },
            },
            '&:hover': {
              backgroundColor: PALETTE.secondary.light10,
              fontWeight: 400,
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          color: PALETTE.secondary.main,
          textDecoration: 'none',
          padding: '10px 15px',
          '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: PALETTE.secondary.main,
          },
          '.MuiTypography-root': {
            color: PALETTE.secondary.main,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          width: sidebarWidth,
          '.MuiDrawer-paper': {
            width: sidebarWidth,
            height: '100%',
            background: 'white',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: `calc(100% - ${sidebarWidth}px)`,
          height: '60px',
          paddingLeft: '20px',
          justifyContent: 'center',
          boxShadow: '0 0 9px 0 rgba(0, 0, 0, 0.16)',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          width: '100%',
          '.MuiTableCell-root': {
            padding: 10,
            '&:first-of-type': {
              padding: 16
            }
          }
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          circle: {
            strokeWidth: 2
          }
        },
      },
    }
  },
});

export default responsiveFontSizes(defaultTheme);
