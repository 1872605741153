import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';

import { fetchActivityLogs } from '../../redux/api/customer';
import ActivityLogsTable from './ActivityLogsTable';
import ItemFlag from '../../shared/ItemFlag';
import { OBJECT_ICON, OBJECT_STATUS_COLOR } from './helper';
import { setActivityLogs } from '../../redux/customerSlice';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../config';
import { setSensor, setSensors } from '../../redux/sensorSlice';
import { setProject, setProjects } from '../../redux/projectSlice';

const ActivityLogs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transformedActivityLogs, setTransformedActivityLogs] = useState(null);

  const { activityLogs, customerInfo } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSensor({}));
    dispatch(setProject({}));
    dispatch(setProjects([]));
    dispatch(setSensors([]));

    return () => {
      dispatch(setActivityLogs([]));
    };
  }, []);

  useEffect(() => {
    if (customerInfo) {
      (async () => {
        await dispatch(fetchActivityLogs({
          customerId: customerInfo?.id,
          searchTerm: '',
          pageNumber: 1,
          pageSize: DEFAULT_TABLE_PAGE_SIZE
        }));
        setIsLoading(false);
      })();
    }
  }, [customerInfo]);

  useEffect(() => {
    if (activityLogs?.data?.length) {
      setTransformedActivityLogs(activityLogs?.data?.map((activityLog) => {
        const createdAt = new Date(activityLog?.createdAt);
        const activityLogTmp = {
          ...activityLog,
          status: activityLog?.objectStatus,
          createdAt: `${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`,
          activity: (
            <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'max-content' }}>
              <Box sx={{ display: 'flex' }}>
                <Grid item>
                  <img width={40} height={40} src={OBJECT_ICON[activityLog?.table]?.[activityLog?.objectStatus]} alt="icon" />
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                  <ItemFlag
                    backgroundColor={OBJECT_STATUS_COLOR[activityLog?.objectStatus]}
                    color="white"
                    label={activityLog?.objectStatus || ''}
                  />
                  <ItemFlag label={activityLog?.table} />
                </Grid>
              </Box>
              <Grid xs={4} md={7} xl={12} item sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    fontWeight: 'fontWeightBold',
                    mb: 0.5,
                    width: '100%',
                  }}
                >
                  {`${activityLog?.objectName}`}
                </Typography>
              </Grid>
            </Grid>),
          date: (
            <Box>
              <Typography variant="body2" sx={{ color: 'general.info', fontWeight: 'fontWeightBold' }}>
                {`${createdAt.getHours()}:${createdAt.getMinutes() < 10 ? `0${createdAt.getMinutes()}` : createdAt.getMinutes()}`}
              </Typography>
              {`${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`}
            </Box>),
        };
        return activityLogTmp;
      }));
    } else if (!isLoading && !activityLogs?.data?.length) {
      setTransformedActivityLogs([]);
    }
  }, [activityLogs, isLoading]);

  return (
    <ActivityLogsTable
      isLoading={isLoading}
      rows={transformedActivityLogs}
      setIsLoading={setIsLoading}
    />
  );
};

export default ActivityLogs;
