import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteSensor, getSensor, getSensors, postSensor, updateSensor
} from '../../services/sensors';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchSensors = createAsyncThunk('sensor/fetchSensors', async (customerId, thunkAPI) => {
  try {
    return await getSensors(customerId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const fetchSensor = createAsyncThunk('sensor/fetchSensor', async (sensorId, thunkAPI) => {
  try {
    return await getSensor(sensorId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const addSensor = createAsyncThunk('sensor/addSensor', async ({ sensorData, customerId }, thunkAPI) => {
  try {
    return await postSensor(sensorData, customerId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const removeSensor = createAsyncThunk('sensor/removeSensor', async (id, thunkAPI) => {
  try {
    return await deleteSensor(id);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const editSensor = createAsyncThunk('sensor/editSensor', async (sensorData, thunkAPI) => {
  try {
    return await updateSensor(sensorData, sensorData?.id);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
