import Axios from 'axios';
import { API_URL } from '../config';

export const getCustomers = () => Axios.get(`${API_URL}/Customer`);

export const getCustomer = (customerId) => Axios.get(`${API_URL}/Customer/${customerId}`);

export const getActivityLogs = (
  customerId,
  searchTerm,
  pageNumber,
  pageSize
) => (Axios.get((`${API_URL}/Customer/${customerId}/ActivityLogs`), {
  params: { searchTerm, pageNumber, pageSize }
}));

export const checkIfCustomerNameExist = (data) => Axios.post(`${API_URL}/Customer/CheckNameAvailability`, data);

export const postCustomer = (data) => Axios.post(`${API_URL}/Customer`, data);
