import { Grid, Typography } from '@mui/material';
import Proptypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_TABLE_PAGE_SIZE, OBJECTS, ROUTES } from '../../config';
import { setAlgorithmFromLogsId } from '../../redux/algorithmSlice';
import { fetchActivityLogs } from '../../redux/api/customer';
import { setDatabaseFromLogsId } from '../../redux/databaseSlice';
import CustomTable from '../../shared/CustomTable';
import SearchInput from '../../shared/SearchInput';
import text from '../../text.json';
import { getFullRoute, getTopExtraCardLayerStyle } from '../helper';
import { TAB_INDEX } from '../Sensors/Projects/Project';
import { columns } from './config';
import { getCurrentUserRole } from '../../utils/authHelper';

const ActivityLogsTable = ({
  isLoading,
  setIsLoading,
  rows,
  sx,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);

  const { activityLogs, customerInfo } = useSelector((state) => state.customer);
  const { loggedUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePageWithApi = async (pageNumber, pageSize) => {
    setIsLoading(true);
    await dispatch(fetchActivityLogs({
      customerId: customerInfo?.id,
      searchTerm: searchValue,
      pageNumber: pageNumber + 1,
      pageSize
    }));
    setIsLoading(false);
  };

  const handleChangeRowsPerPageWithApi = async (pageSize) => {
    setIsLoading(true);
    setRowsPerPage(pageSize);
    await dispatch(fetchActivityLogs({
      customerId: customerInfo?.id,
      searchTerm: searchValue,
      pageNumber: 0,
      pageSize
    }));
    setIsLoading(false);
  };

  const handleClick = async (logId) => {
    const clickedLogData = activityLogs?.data?.find(({ id }) => logId === id);
    switch (clickedLogData?.table) {
      case OBJECTS.SENSOR:
        navigate(
          getFullRoute(getCurrentUserRole(loggedUser), `${ROUTES.SENSORS}/${clickedLogData?.sensorId}`, customerInfo?.id),
          {
            state: {
              objectName: clickedLogData?.objectName
            }
          }
        );
        break;
      case OBJECTS.PROJECT:
        navigate(
          getFullRoute(getCurrentUserRole(loggedUser), `${ROUTES.SENSORS}/${clickedLogData?.sensorId}/${ROUTES.PROJECTS}/${clickedLogData?.projectId}`, customerInfo?.id),
          {
            state: {
              objectName: clickedLogData?.objectName
            }
          }
        );
        break;
      case OBJECTS.ALGORITHM:
        dispatch(setAlgorithmFromLogsId(clickedLogData?.objectId));
        navigate(
          getFullRoute(getCurrentUserRole(loggedUser), `${ROUTES.SENSORS}/${clickedLogData?.sensorId}/${ROUTES.PROJECTS}/${clickedLogData?.projectId}`, customerInfo?.id),
          {
            state: {
              tabIndex: TAB_INDEX.ALGORITHMS,
              objectName: clickedLogData?.objectName
            }
          }
        );
        break;
      case OBJECTS.DATABASE:
        dispatch(setDatabaseFromLogsId(clickedLogData?.objectId));
        navigate(
          getFullRoute(getCurrentUserRole(loggedUser), `${ROUTES.SENSORS}/${clickedLogData?.sensorId}/${ROUTES.PROJECTS}/${clickedLogData?.projectId}`, customerInfo?.id),
          {
            state: {
              tabIndex: TAB_INDEX.DATABASES,
              objectName: clickedLogData?.objectName
            }
          }
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (customerInfo) {
      (async () => {
        setIsLoading(true);
        await dispatch(fetchActivityLogs({
          customerId: customerInfo?.id,
          searchTerm: searchValue,
          pageNumber: 1,
          pageSize: rowsPerPage
        }));
        setIsLoading(false);
      })();
    }
  }, [searchValue]);

  return (
    <Grid
      sx={{
        ...getTopExtraCardLayerStyle().CONTAINER,
        ...sx,
      }}
    >
      <Grid mb={3} item xs={12} display="flex" justifyContent="space-between" alignItems="flex-start">
        <Grid>
          <Typography
            sx={(theme) => ({ ...getTopExtraCardLayerStyle(theme).TITLE })}
          >
            {text.sections.activityLogs}
          </Typography>
        </Grid>
      </Grid>
      {setSearchValue && (
      <Grid mb={4} xs={12} item>
        <SearchInput
          placeholder={text.commonInputFields.search}
          value={searchValue}
          setValue={setSearchValue}
        />
      </Grid>
      )}
      <Grid xs={12} sx={{ height: '100%', overflow: 'hidden' }} item>
        <CustomTable
          isDataLoading={isLoading}
          rows={rows}
          columns={columns}
          onRowClick={handleClick}
          isPaginationFromApi
          count={activityLogs?.rowCount}
          handleChangePageWithApi={handleChangePageWithApi}
          handleChangeRowsPerPageWithApi={handleChangeRowsPerPageWithApi}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityLogsTable;

ActivityLogsTable.propTypes = {
  isLoading: Proptypes.bool,
  rows: Proptypes.arrayOf(Proptypes.shape({})),
  sx: Proptypes.shape({}),
  setIsLoading: Proptypes.func.isRequired
};

ActivityLogsTable.defaultProps = {
  isLoading: false,
  rows: [],
  sx: {},
};
