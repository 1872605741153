import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '../../shared/Modal';
import text from '../../text.json';
import { getNumberInputTextFieldCommonProps, NUMBER_FIELDS_NAMES } from './helper';
import { editSensor } from '../../redux/api/sensor';

const EditSensor = ({ data, handleClose, isOpen }) => {
  const dispatch = useDispatch();
  const [sensorData, setSensorData] = useState(data);
  const [widthErrorMessage, setWidthErrorMessage] = useState('');
  const [heightErrorMessage, setHeightErrorMessage] = useState('');
  const [resolutionErrorMessage, setResolutionErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEditSensor = async () => {
    const { width, height, dotsPerInch } = sensorData;

    setIsLoading(true);
    let isDataValid = true;

    if (!width) {
      setWidthErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.addSensor.width));
      isDataValid = false;
    }
    if (!height) {
      setHeightErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.addSensor.height));
      isDataValid = false;
    }
    if (dotsPerInch !== 0 && !dotsPerInch) {
      setResolutionErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.commonInputFields.resolution));
      isDataValid = false;
    }

    if (isDataValid) {
      const result = await dispatch(editSensor(sensorData));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.edit.title.replace('{{object}}', text.objects.sensor)}
      primaryButtonText={text.buttons.editObject.replace('{{object}}', text.objects.sensor)}
      onPrimaryButtonClick={handleEditSensor}
      isLoading={isLoading}
      isPrimaryButtonDisabled={JSON.stringify(sensorData) === JSON.stringify(data)}
    >
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        label={`${text.commonInputFields.name} *`}
        variant="standard"
        value={data?.name}
        disabled
        inputProps={{ 'data-testid': 'nameInputTest' }}
      />
      <Box mb={4} display="flex" alignItems="center">
        <TextField
          {...getNumberInputTextFieldCommonProps('px', NUMBER_FIELDS_NAMES.width, setWidthErrorMessage, setSensorData, 'widthInputTest')}
          label={`${text.addSensor.width} *`}
          sx={{ mr: 2 }}
          helperText={widthErrorMessage}
          error={!!widthErrorMessage}
          defaultValue={data?.width}
        />
        <Typography fontWeight="fontWeightBold" sx={{ color: 'gray.dark' }}>x</Typography>
        <TextField
          {...getNumberInputTextFieldCommonProps('px', NUMBER_FIELDS_NAMES.height, setHeightErrorMessage, setSensorData, 'heightInputTest')}
          sx={{ ml: 2 }}
          label={`${text.addSensor.height} *`}
          helperText={heightErrorMessage}
          error={!!heightErrorMessage}
          defaultValue={data?.height}
        />
      </Box>
      <TextField
        {...getNumberInputTextFieldCommonProps('dpi', NUMBER_FIELDS_NAMES.resolution, setResolutionErrorMessage, setSensorData, 'resolutionInputTest')}
        sx={{ mb: 4 }}
        label={`${text.commonInputFields.resolution} *`}
        helperText={resolutionErrorMessage}
        error={!!resolutionErrorMessage}
        defaultValue={data?.dotsPerInch}
      />
      <TextField
        fullWidth
        label={`${text.commonInputFields.type} *`}
        variant="standard"
        value={data?.sensorType}
        disabled
        inputProps={{ 'data-testid': 'typeInputTest' }}
      />
    </Modal>

  );
};

export default EditSensor;

EditSensor.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({
    sensorType: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    dotsPerInch: PropTypes.number
  })
};

EditSensor.defaultProps = {
  isOpen: false,
  data: {}
};
