import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  Avatar,
  Typography,
  Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { logo } from '../../assets/icons';
import ButtonLink from '../Sidebar/ButtonLink';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import SearchInput from '../../shared/SearchInput';
import Customer from './Customer';
import NoDataFound from '../../shared/NoDataFound';
import { sessionExpired } from '../../redux/authSlice';
import text from '../../text.json';
import { fetchCustomers } from '../../redux/api/customer';
import AddCustomer from '../Modals/AddCustomer';
import { getTopExtraCardLayerStyle } from '../helper';
import Restricted from '../../shared/Restricted';
import { ADMIN_ROLES } from '../../config';

const Customers = () => {
  const [searchValue, setSearchValue] = useState('');
  const { customers } = useSelector((state) => state.customer);
  const [filteredCustomers, setFilteredCustomers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);

  const { loggedUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(fetchCustomers());
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (customers?.length) {
      setFilteredCustomers(customers.filter(
        ({ name }) => name?.toLowerCase().includes(searchValue.toLocaleLowerCase())
      ));
    } else if (!isLoading && !customers?.length) {
      setFilteredCustomers([]);
    }
  }, [searchValue, customers, isLoading]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            py: 3,
          }}
        >
          <img src={logo} alt="logo" width="130px" height="38px" />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {loggedUser && (
              <Tooltip
                title={(
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {(loggedUser?.idTokenClaims?.given_name
                    || loggedUser?.idTokenClaims?.family_name) && (
                      <Typography variant="caption">
                        {`${loggedUser?.idTokenClaims?.given_name || ''} ${loggedUser?.idTokenClaims?.family_name || ''}`}
                      </Typography>
                    )}
                    <Typography variant="caption">
                      {loggedUser?.idTokenClaims?.emails?.[0]}
                    </Typography>
                  </Box>
            )}
              >
                <Avatar
                  sx={(theme) => ({
                    backgroundColor: 'secondary.main',
                    fontSize: theme.typography.body2,
                    width: '32px',
                    height: '32px'
                  })}
                >
                  {loggedUser?.idTokenClaims?.given_name?.charAt(0)?.toUpperCase()}
                  {loggedUser?.idTokenClaims?.family_name?.charAt(0)?.toUpperCase()}
                </Avatar>
              </Tooltip>
            )}
            <ButtonLink
              text={text.buttons.logout}
              icon={LogoutIcon}
              onClick={() => dispatch(sessionExpired())}
            />
          </Box>
        </Box>
        <Grid
          sx={(theme) => ({ ...getTopExtraCardLayerStyle(theme).CONTAINER, height: 'calc(100% - 98px)' })}
          maxWidth="xl"
        >
          <Grid item xs={12} mb={3} px={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Typography sx={(theme) => ({ ...getTopExtraCardLayerStyle(theme).TITLE })}>
              {text.customers.customers}
            </Typography>
            <Restricted to={[ADMIN_ROLES.PRECISE]}>
              <Button
                onClick={() => setIsAddCustomerModalOpen(true)}
                variant="primary"
              >
                {`+ ${text.buttons.add.replace('{{object}}', text.objects.customer)}`}
              </Button>
            </Restricted>
          </Grid>
          <Grid item xs={12} px={2} mb={4}>
            <SearchInput
              placeholder={text.commonInputFields.search}
              value={searchValue}
              setValue={setSearchValue}
            />
          </Grid>
          <Grid
            container
            sx={{
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                width: 0,
                height: 0,
              }
            }}
          >
            {!isLoading && filteredCustomers?.map((data) => (
              <Grid key={data?.id} item xs={6} md={4} lg={3} xl={2} p={2}>
                <Customer data={data} />
              </Grid>
            ))}
          </Grid>
          {filteredCustomers && !isLoading && !filteredCustomers?.length && (
          <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
            <NoDataFound />
          </Grid>
          )}
          {isLoading && (
          <Grid data-testid="noDataTest" item xs={12} display="flex" justifyContent="center">
            <CircularProgress />
          </Grid>
          )}
        </Grid>
      </Box>
      {isAddCustomerModalOpen && (
        <AddCustomer
          handleClose={() => setIsAddCustomerModalOpen(false)}
          isOpen={isAddCustomerModalOpen}
        />
      )}
    </>
  );
};

export default Customers;
