import {
  Box, Dialog, DialogContent, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { noDataFound } from '../assets/icons';
import { setItemNotFoundData } from '../redux/generalSlice';
import { BORDER_RADIUS } from '../theme/constants';
import text from '../text.json';

const NotFoundItemModal = ({ isOpen }) => {
  const { itemNotFoundData } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  return (
    <Dialog
      onClose={() => dispatch(setItemNotFoundData(null))}
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: BORDER_RADIUS.SMALL,
          p: 8
        }
      }}
      fullWidth
    >
      <DialogContent>
        <Box sx={{ width: '100%', textAlign: 'center', opacity: 0.7 }}>
          <img width="150px" src={noDataFound} alt="no data found" />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1,
          flexWrap: 'wrap'
        }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              mr: 0.7,
              fontWeight: 'fontWeightBold',
            }}
          >
            {text.infoMessages.couldNotFind}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: 'primary.main',
              textAlign: 'center',
              fontWeight: 'fontWeightBold',
            }}
          >
            {itemNotFoundData?.name}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: 'grey.600', textAlign: 'center', fontWeight: 'fontWeightBold', }}>
          {text.infoMessages.itemRemoved}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default NotFoundItemModal;

NotFoundItemModal.propTypes = {
  isOpen: PropTypes.bool,
};

NotFoundItemModal.defaultProps = {
  isOpen: false
};
