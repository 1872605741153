import {
  Box, Breadcrumbs, Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { ROUTES } from '../../../config';
import { fetchSensor } from '../../../redux/api/sensor';
import { setSensor } from '../../../redux/sensorSlice';
import { fetchProject } from '../../../redux/api/project';
import { setProject } from '../../../redux/projectSlice';
import text from '../../../text.json';
import ItemFlag from '../../../shared/ItemFlag';
import { getFullRoute } from '../../helper';
import { getCurrentUserRole } from '../../../utils/authHelper';
import { setItemNotFoundData } from '../../../redux/generalSlice';

const CustomBreadcrumbs = () => {
  const [chosenSensorInfo, setChosenSensorInfo] = useState({});
  const [chosenProjectInfo, setChosenProjectInfo] = useState({});

  const { sensorId, projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { sensors } = useSelector((state) => state.sensor);
  const { projects } = useSelector((state) => state.project);
  const { loggedUser } = useSelector((state) => state.auth);
  const { customerInfo } = useSelector((state) => state.customer);

  const shouldBreadcrumbsShow = !!sensorId;

  useEffect(() => {
    if (sensorId && sensors?.length && customerInfo && loggedUser) {
      const sensorInfo = sensors.find(({ id }) => sensorId === id);
      setChosenSensorInfo(sensorInfo || {});
      dispatch(setSensor(sensorInfo || {}));
    } else if (sensorId && loggedUser && customerInfo) {
      (async () => {
        const response = await dispatch(fetchSensor(sensorId));
        if (!response?.payload?.data) {
          navigate(getFullRoute(getCurrentUserRole(loggedUser), ROUTES.SENSORS, customerInfo?.id));
          dispatch(setItemNotFoundData({ name: location?.state?.objectName || '' }));
        } else {
          setChosenSensorInfo(response?.payload?.data);
        }
      })();
    }
  }, [sensorId, sensors, customerInfo, loggedUser]);

  useEffect(() => {
    if (projectId && projects?.length && customerInfo && loggedUser) {
      const projectInfo = projects?.find(({ id }) => projectId === id);
      setChosenProjectInfo(projectInfo || {});
      dispatch(setProject(projectInfo || {}));
    } else if (projectId && loggedUser && customerInfo) {
      (async () => {
        const response = await dispatch(fetchProject(projectId));
        if (!response?.payload?.data) {
          navigate(getFullRoute(getCurrentUserRole(loggedUser), `${ROUTES.SENSORS}/${sensorId}`, customerInfo?.id));
          dispatch(setItemNotFoundData({ name: location?.state?.objectName || '' }));
        } else {
          setChosenProjectInfo(response?.payload?.data);
        }
      })();
    }
  }, [projectId, projects, customerInfo, loggedUser]);

  const getBreadcrumbs = () => ([
    {
      name: text.sections.sensors,
      route: ROUTES.SENSORS
    },
    {
      name: chosenSensorInfo?.name || '',
      route: projectId ? `${ROUTES.SENSORS}/${sensorId}` : '',
      section: text.objects.sensor,
    },
    ...(projectId ? [{
      name: chosenProjectInfo?.name || '',
      section: text.objects.project,
    }] : [])
  ]);

  const handleClick = (route) => {
    if (route) {
      const to = getFullRoute(getCurrentUserRole(loggedUser), route, customerInfo?.id);
      navigate(to);
    } else {
      window.location.reload();
    }
  };

  return (
    shouldBreadcrumbsShow
    && (
      <Breadcrumbs sx={{ mt: -4, mb: 2 }} separator={<NavigateNextIcon />} aria-label="breadcrumb">
        {getBreadcrumbs()?.map(({ name, route, section }, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            {!!section && <ItemFlag label={section} />}
            <Button
              sx={(theme) => ({
                fontSize: theme.typography.subtitle2,
                mb: 0.3,
                ...(route ? {} : { fontWeight: 'fontWeightBold' }),
                display: 'block',
                minWidth: 'max-content'
              })}
              variant="link"
              onClick={() => handleClick(route)}
              disableTouchRipple
            >
              {name}
            </Button>
          </Box>
        ))}
      </Breadcrumbs>
    )
  );
};

export default CustomBreadcrumbs;
