import text from '../../text.json';

export const getNameErrorMessage = async (name, checkIfNameExist) => {
  if (!name) {
    return text.errorMessages.fieldRequired.replace(/{{field}}/g, text.commonInputFields.name);
  }
  if (!name.match(/^([a-z]*[A-Z]*[0-9]*[_]*[-]*)*$/)) {
    return text.errorMessages.nameInvalid;
  }

  const response = await checkIfNameExist();

  if (!response?.data?.isAvailable) {
    return text.errorMessages.fieldValueExists.replace(/{{field}}/g, text.commonInputFields.name);
  }

  return '';
};

export const getVariantErrorMessage = async (variant, checkIfVariantExist) => {
  if (!variant.match(/^([a-z]*[A-Z]*[0-9]*[_]*[-]*)*$/)) {
    return text.errorMessages.variantInvalid;
  }

  const response = await checkIfVariantExist();

  if (!response?.data?.isAvailable) {
    return text.errorMessages.fieldValueExists.replace(/{{field}}/g, text.addAlgorithm.variant);
  }

  return '';
};
