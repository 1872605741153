import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import uploadAnimation from '../assets/lotties/upload.json';

const UploadIcon = ({ style }) => (
  <Lottie
    style={{
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      ...style
    }}
    animationData={uploadAnimation}
    loop
  />
);

export default UploadIcon;

UploadIcon.propTypes = {
  style: PropTypes.shape({})
};

UploadIcon.defaultProps = {
  style: {}
};
