import { createSlice } from '@reduxjs/toolkit';
import {
  addSensor, editSensor, fetchSensor, fetchSensors, removeSensor
} from './api/sensor';
import { highlightElementInArray } from './helper';

const initialState = {
  sensors: [],
  sensor: {}
};

export const sensorSlice = createSlice({
  name: 'sensor',
  initialState,
  reducers: {
    setSensor: (state, action) => {
      state.sensor = action.payload;
    },
    setSensors: (state, action) => {
      state.sensors = action.payload;
    }
  },
  extraReducers: {
    [fetchSensors.fulfilled]: (state, action) => {
      state.sensors = action.payload.data;
    },
    [fetchSensor.fulfilled]: (state, action) => {
      state.sensor = action.payload.data;
    },
    [addSensor.fulfilled]: (state, action) => {
      state.sensors = [action.payload.data, ...state.sensors];
    },
    [removeSensor.fulfilled]: (state, action) => {
      state.sensors = highlightElementInArray(state.sensors, action.payload);
    },
    [editSensor.fulfilled]: (state, action) => {
      state.sensors = highlightElementInArray(state.sensors, action.payload);
    },
  },
});

export const { setSensor, setSensors } = sensorSlice.actions;

export default sensorSlice.reducer;
