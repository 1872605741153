import { createAsyncThunk } from '@reduxjs/toolkit';

import { getMetaData } from '../../services/general';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchMetaData = createAsyncThunk('general/fetchMetaData', async (id, thunkAPI) => {
  try {
    return await getMetaData();
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
