import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import { BORDER_RADIUS, BOX_SHADOWS, PALETTE } from '../theme/constants';

const TabPanel = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    children,
    // eslint-disable-next-line react/prop-types
    value,
    // eslint-disable-next-line react/prop-types
    index,
    ...other
  } = props;

  return (
    <div
      style={{ height: '100%', marginLeft: '3px' }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const CustomTabs = ({ tabs, currentIndex, handleChange }) => (
  <>
    <Box sx={{ borderBottom: 'none' }}>
      <Tabs
        textColor="secondary"
        value={currentIndex}
        onChange={handleChange}
        sx={{ '.MuiTabs-indicator': { display: 'none' }, '.MuiTabs-flexContainer': { ml: '3px' } }}
      >
        {tabs?.map(({ icon: Icon, label, index }, tabIndex) => (
          <Tab
            key={tabIndex}
            iconPosition="start"
            sx={(theme) => ({
              textTransform: 'none',
              fontSize: theme.typography.body1,
              '&.Mui-selected': {
                borderBottom: 'none',
                fontWeight: 'fontWeightBold',
                background: PALETTE.gray.containerLight,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: BORDER_RADIUS.MEDIUM,
                borderTopLeftRadius: BORDER_RADIUS.MEDIUM,
                boxShadow: BOX_SHADOWS.CONTAINER,
              }
            })}
            icon={(
              <Icon
                fill={currentIndex === index ? PALETTE.secondary.main : PALETTE.gray.dark}
              />
            )}
            disableTouchRipple
            label={label}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
          />
        ))}
      </Tabs>
    </Box>
    {tabs?.map(({ index, component: Component }, tabIndex) => (
      <TabPanel key={tabIndex} value={currentIndex} index={index}>
        <Component />
      </TabPanel>
    ))}
  </>
);

export default CustomTabs;

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentIndex: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};
