import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import NoDataFound from './NoDataFound';
import {
  ADDING_REQUESTED, ADMIN_ROLES, DEFAULT_TABLE_PAGE_SIZE, REMOVAL_REQUESTED, TABLE_PAGE_SIZES
} from '../config';
import StatusFlag from './StatusFlag';
import text from '../text.json';
import Restricted from './Restricted';

const CustomTable = ({
  onRowClick,
  rows,
  columns,
  isDataLoading,
  handleRemove,
  handleEdit,
  actionComponent: ActionComponent,
  isDatabaseOverview,
  handleChangePageWithApi,
  handleChangeRowsPerPageWithApi,
  isPaginationFromApi,
  count
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const tableRef = useRef(null);

  useEffect(() => {
    if (!isPaginationFromApi) {
      setPage(0);
    }
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef.current.scroll({ top: 0, behavior: 'smooth' });
    handleChangePageWithApi(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    handleChangeRowsPerPageWithApi(event.target.value);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page)
  * rowsPerPage - (isPaginationFromApi ? count : rows.length)) : 0;

  const getRows = () => (isPaginationFromApi ? rows
    : rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));

  return (
    <Paper sx={{
      overflow: 'hidden',
      boxShadow: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
    >
      <TableContainer sx={{ height: '100%' }} ref={tableRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns?.map(({ name }, index) => (
                <TableCell sx={{ fontWeight: 'fontWeightBold' }} key={index}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isDataLoading || !rows) && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  sx={{
                    textAlign: 'center', height: '100%', border: 'none'
                  }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {rows && !rows.length && !isDataLoading && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  sx={{
                    textAlign: 'center', height: '100%', border: 'none'
                  }}
                >
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
            {!isDataLoading && rows && getRows().map((row, index) => (
              <TableRow
                data-testid="row"
                key={index}
                variant={!row?.isPendingToAdd && onRowClick ? 'clickable' : ''}
                {...(!row?.isPendingToAdd && onRowClick ? { onClick: () => onRowClick(row?.id || '') } : {})}
                sx={{
                  ...(row?.isPendingToAdd ? { opacity: '0.5' } : {}),
                  ...(row?.isHighlighted ? { backgroundColor: 'primary.light' } : {})
                }}
              >
                {columns?.map(({ key }, columnIndex) => (
                  key !== 'action' ? (
                    <TableCell
                      sx={{
                        ...(columnIndex === 0 ? {
                          lineBreak: 'anywhere',
                          width: {
                            sm: '100px', lg: '250px'
                          }
                        } : {})
                      }}
                      key={columnIndex}
                    >
                      {(row[key] === 0 || typeof row[key] === 'boolean' || row[key]) ? (
                        <Tooltip title={columnIndex === 0 ? row[key] : ''} followCursor>
                          <Typography
                            sx={{
                              ...(columnIndex === 0 ? {
                                lineBreak: 'anywhere',
                                width: {
                                  sm: '100px', lg: '250px'
                                }
                              } : {})
                            }}
                            component="div"
                            variant="body2"
                          >
                            {typeof row[key] === 'boolean' ? (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                              <>
                                {row[key]
                                  ? <CheckIcon fontSize="small" sx={{ color: 'general.success' }} />
                                  : <CancelIcon fontSize="small" sx={{ color: 'gray.light' }} />}
                              </>
                            ) : row[key]}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="caption"
                          sx={{ color: 'gray.disabledTextColor', fontStyle: 'italic' }}
                        >
                          {text.infoMessages.emptyField}
                        </Typography>
                      )}
                    </TableCell>

                  ) : (
                    <TableCell sx={{ py: 0, width: `${(1 / (columns.length + 1)) * 100}%` }} key={columnIndex} align="right">
                      {row?.isPendingToAdd || row?.isPendingToRemove ? (
                        <StatusFlag
                          data={row?.isPendingToAdd
                            ? ADDING_REQUESTED : REMOVAL_REQUESTED}
                        />
                      )
                        : ActionComponent && (
                          <Restricted to={[ADMIN_ROLES.PRECISE]}>
                            <ActionComponent
                              handleEdit={handleEdit}
                              handleRemove={handleRemove}
                              data={row}
                              isDatabaseOverview={isDatabaseOverview}
                            />
                          </Restricted>
                        )}
                    </TableCell>
                  )
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGE_SIZES}
        component="div"
        count={!isPaginationFromApi ? (rows?.length || 0) : count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ overflow: 'hidden' }}
      />
    </Paper>
  );
};

CustomTable.propTypes = {
  onRowClick: PropTypes.func,
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  isDataLoading: PropTypes.bool,
  actionComponent: PropTypes.func,
  isDatabaseOverview: PropTypes.bool,
  apiToCallOnPagination: PropTypes.func,
  handleChangePageWithApi: PropTypes.func,
  handleChangeRowsPerPageWithApi: PropTypes.func,
  isPaginationFromApi: PropTypes.bool,
  count: PropTypes.number
};

CustomTable.defaultProps = {
  onRowClick: null,
  rows: [],
  columns: [],
  isDataLoading: false,
  handleRemove: null,
  handleEdit: null,
  actionComponent: null,
  isDatabaseOverview: false,
  apiToCallOnPagination: null,
  handleChangePageWithApi: () => {},
  handleChangeRowsPerPageWithApi: () => {},
  isPaginationFromApi: false,
  count: 0
};

export default CustomTable;
