import {
  Box, TextField, Tooltip, Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { NAME_MAX_NUMBER_CHARACTERS } from '../../../config';
import { PALETTE } from '../../../theme/constants';
import text from '../../../text.json';

const Variant = ({
  setAlgorithmData, setVariantErrorMessage, variantErrorMessage, typeId
}) => {
  const [algorithmsWithSameType, setAlgorithmsWithSameType] = useState([]);
  const { algorithms } = useSelector((state) => state.algorithm);
  const { metaData } = useSelector((state) => state.general);

  const getAlgorthmsWithSameType = () => (
    algorithms?.filter(({ algoType }) => algoType === metaData?.algoTypes?.find(
      ({ id }) => typeId === id
    )?.name)
  );

  useEffect(() => {
    if (typeId) {
      setAlgorithmsWithSameType(getAlgorthmsWithSameType());
    }
  }, [typeId]);

  return (
    algorithmsWithSameType?.length > 0
      ? (
        <Box sx={{ mb: 4 }}>
          <TextField
            sx={{ '.MuiInputLabel-root': { zIndex: 1 } }}
            fullWidth
            label={`${text.addAlgorithm.variant} *`}
            variant="standard"
            onChange={(event) => {
              setAlgorithmData((prevSensorData) => (
                { ...prevSensorData, variant: event.target.value }));
              setVariantErrorMessage('');
            }}
            helperText={variantErrorMessage}
            error={!!variantErrorMessage}
            inputProps={{
              'data-testid': 'variantInputTest',
              maxLength: NAME_MAX_NUMBER_CHARACTERS,
              style: { backgroundColor: PALETTE.general.warningMessageLight }
            }}
          />
          <Tooltip
            title={(
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {algorithmsWithSameType?.map(
                  ({ variant }, index) => (
                    <span key={index}>
                      {variant || text.addAlgorithm.defaultVariant}
                    </span>
                  )
                )}
              </Box>
          )}
            placement="right-end"
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                variant="caption"
                component="div"
                sx={{ color: 'grey.800' }}
              >
                Variants
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontWeight: 'fontWeightBold', ml: 0.5, color: 'primary.dark' }}
              >
                {`(${algorithmsWithSameType?.length})`}
              </Typography>
            </Box>

          </Tooltip>
        </Box>
      ) : null
  );
};

export default Variant;

Variant.propTypes = {
  setAlgorithmData: PropTypes.func.isRequired,
  setVariantErrorMessage: PropTypes.func.isRequired,
  variantErrorMessage: PropTypes.string,
  typeId: PropTypes.string.isRequired
};

Variant.defaultProps = {
  variantErrorMessage: ''
};
