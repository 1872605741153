import WarningIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import InvalidIcon from '@mui/icons-material/Cancel';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { BORDER_RADIUS, PALETTE } from '../theme/constants';
import text from '../text.json';

export const MESSAGE_TYPE = {
  INFO: 'info',
  WARNING: 'warning',
  INVALID: 'invalid'
};

const Message = ({
  message, sx, type, title
}) => {
  const getData = () => {
    switch (type) {
      case MESSAGE_TYPE.WARNING:
        return {
          colors: {
            border: '#fff480',
            title: '#57500f',
          },
          icon: <WarningIcon fontSize="small" />,
          title: title || text.warningMessages.title
        };
      case MESSAGE_TYPE.INFO:
        return {
          colors: {
            border: PALETTE.general.info,
            title: 'general.info',
          },
          icon: <InfoIcon fontSize="small" />,
          title: title || text.infoMessages.title
        };
      case MESSAGE_TYPE.INVALID:
        return {
          colors: {
            border: PALETTE.general.error,
            title: 'general.error',
          },
          icon: <InvalidIcon fontSize="small" />,
          title: title || text.errorMessages.invalid
        };
      default:
        return {};
    }
  };

  return (
    <Box sx={{
      borderRadius: BORDER_RADIUS.SMALL,
      borderLeft: 'none',
      display: 'flex',
      alignItems: 'center',
      mb: 2,
      ...sx,
    }}
    >
      <Box sx={{
        py: 0.5,
        pl: 1,
        width: '100%',
        borderLeft: `8px solid ${getData()?.colors?.border}`,
        borderTopLeftRadius: BORDER_RADIUS.SMALL,
        borderBottomLeftRadius: BORDER_RADIUS.SMALL,
      }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: getData()?.colors?.title,
            mb: 1
          }}
        >
          {getData()?.icon}
          <Typography variant="caption" sx={{ fontWeight: 'fontWeightBold', ml: 0.5, textTransform: 'uppercase' }}>
            {getData()?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            ...type === MESSAGE_TYPE.INVALID ? {
              borderRadius: BORDER_RADIUS.SMALL,
              border: `0.5px solid ${PALETTE.general.error}`,
            } : {},
            width: '100%'
          }}
        >
          {type === MESSAGE_TYPE.INVALID && (
            <Typography
              sx={{
                color: 'general.error',
                fontWeight: 'fontWeightBold',
                backgroundColor: 'general.errorLight',
                borderTopLeftRadius: BORDER_RADIUS.SMALL,
                borderTopRightRadius: BORDER_RADIUS.SMALL,
                p: 1,
              }}
              variant="body2"
            >
              {text.errorMessages.errorMessage}
            </Typography>
          )}
          <Typography
            sx={{
              ...type === MESSAGE_TYPE.INVALID ? {
                p: 1,
              } : {}
            }}
            component="div"
            dangerouslySetInnerHTML={{ __html: message }}
            variant={type === MESSAGE_TYPE.INVALID ? 'body2' : 'caption'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Message.defaultProps = {
  sx: {},
  title: ''
};
