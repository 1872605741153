import { createAsyncThunk } from '@reduxjs/toolkit';

import { deleteAlgorithm, getAlgorithms, postAlgorithm } from '../../services/algorithms';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchAlgorithms = createAsyncThunk('algorithm/fetchAlgorithms', async (projectId, thunkAPI) => {
  try {
    return await getAlgorithms(projectId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const addAlgorithm = createAsyncThunk('algorithm/addAlgorithm', async ({ algorithmData, projectId }, thunkAPI) => {
  try {
    return await postAlgorithm(algorithmData, projectId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const removeAlgorithm = createAsyncThunk('algorithm/removeAlgorithm', async (id, thunkAPI) => {
  try {
    return await deleteAlgorithm(id);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
