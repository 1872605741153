import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

import UploadIcon from '../../../../../../shared/UploadIcon';
import { PALETTE } from '../../../../../../theme/constants';
import text from '../../../../../../text.json';

export const getColor = (isFinished, isFailed) => {
  switch (isFinished) {
    case true:
      return (
        isFailed ? PALETTE.general.error : PALETTE.general.success
      );
    default:
      return PALETTE.general.info;
  }
};

export const getTitle = (isFinished, isFailed) => {
  switch (isFinished) {
    case true:
      return (
        isFailed ? text.uploadingPopUp.titleFailed : text.uploadingPopUp.titleUploaded
      );
    default:
      return text.uploadingPopUp.titleUploading;
  }
};

export const getIcon = (isFinished, isFailed) => {
  switch (isFinished) {
    case true:
      return (
        isFailed ? <CloudUploadIcon sx={{ color: 'general.error' }} />
          : <CloudDoneIcon sx={{ color: 'general.success' }} />
      );
    default:
      return <UploadIcon />;
  }
};
