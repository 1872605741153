import { Outlet, useMatch } from 'react-router-dom';
import Box from '@mui/material/Box';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Container } from '@mui/material';

import Sidebar from '../components/Sidebar';
import { ROUTES, sidebarWidth } from '../config';
import Navbar from '../components/Navbar';

const PrivateLayout = () => {
  const isCustomersPage = useMatch({ path: ROUTES.CUSTOMERS, end: true });

  return (
    <AuthenticatedTemplate>
      <Box
        sx={{
          display: 'flex',
          background: 'linear-gradient(to right bottom, #ffffff, #f8f8f8, #f0f0f0, #e9e9e9, #e2e2e2)',
          height: '100vh',
          overflowY: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          }
        }}
      >
        {!isCustomersPage && window.location.pathname !== '/' && (
        <>
          <Navbar />
          <Sidebar />
        </>
        )}
        <Container
          maxWidth="xl"
          component="main"
          sx={{
            ...(!isCustomersPage ? { pt: 14, px: { lg: '20px !important' } } : {}),
            width: `calc(100% - ${sidebarWidth}px)`,
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </AuthenticatedTemplate>
  );
};

export default PrivateLayout;
