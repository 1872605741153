export const downloadFile = (responseData, filename) => {
  const url = window.URL.createObjectURL(
    new Blob([responseData]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename,
  );

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
