import { Typography, CircularProgress, Box } from '@mui/material';
import Proptypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchReleaseAlgorithms } from '../../../redux/api/release';
import Modal from '../../../shared/Modal';
import text from '../../../text.json';

const Algorithms = ({
  handleClose, isOpen, releaseData
}) => {
  const [algorithms, setAlgorithms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const response = await dispatch(fetchReleaseAlgorithms(releaseData?.id));
      setAlgorithms(response?.payload?.data);
      setIsLoading(false);
    })();
  }, []);

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.releases.algorithms.replace('{{releaseName}}', releaseData?.name)}
      bodySx={{ px: 3 }}
    >
      {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
        : algorithms?.map(({ name }, index) => <Typography key={index}>{name}</Typography>)}
    </Modal>
  );
};

export default Algorithms;

Algorithms.propTypes = {
  handleClose: Proptypes.func.isRequired,
  isOpen: Proptypes.bool,
  releaseData: Proptypes.shape({ id: Proptypes.string, name: Proptypes.string }).isRequired
};

Algorithms.defaultProps = {
  isOpen: false,
};
