import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { BORDER_RADIUS } from '../theme/constants';

const ItemFlag = ({ label, color, backgroundColor }) => (
  <Typography
    sx={{
      mr: 1,
      fontWeight: 'fontWeightBold',
      color: color || 'gray.disabledTextColor',
      backgroundColor: backgroundColor || 'gray.disabledBackgroundColor',
      py: 0.2,
      px: 1,
      borderRadius: BORDER_RADIUS.SMALL
    }}
    variant="caption"
  >
    {label.toUpperCase()}
  </Typography>
);

export default ItemFlag;

ItemFlag.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

ItemFlag.defaultProps = {
  color: '',
  backgroundColor: ''
};
