import { AppBar, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ROLES, ROUTES } from '../../config';
import { fetchCustomerInfo } from '../../redux/api/customer';
import { setCustomerInfo } from '../../redux/customerSlice';
import { sessionExpired } from '../../redux/authSlice';
import ItemFlag from '../../shared/ItemFlag';
import { getCurrentUserRole, getCustomerIdFromToken } from '../../utils/authHelper';
import text from '../../text.json';

const Navbar = () => {
  const { customerInfo } = useSelector((state) => state.customer);
  const { loggedUser } = useSelector((state) => state.auth);
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if ((customerId && loggedUser) || loggedUser) {
      (async () => {
        const currentCustomerId = customerId || getCustomerIdFromToken(loggedUser);
        const result = await dispatch(fetchCustomerInfo(currentCustomerId));
        if (result?.error
        && (result?.payload?.response?.status === 404
          || result?.payload?.response?.status === 400)) {
          if (getCurrentUserRole(loggedUser) === ROLES.ADMIN) {
            navigate(ROUTES.CUSTOMERS);
          } else {
            dispatch(sessionExpired());
          }
        }
      })();
    }

    return () => {
      dispatch(setCustomerInfo(null));
    };
  }, [customerId, loggedUser]);

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: 'white', boxShadow: 'none' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ItemFlag label={text.objects.customer} />
        <Typography variant="h6" sx={{ color: 'secondary.main', fontWeight: 'fontWeightBold' }}>
          {customerInfo?.name || ''}
        </Typography>
      </Box>
    </AppBar>
  );
};

export default Navbar;
