import {
  Box, Slide, Snackbar, SnackbarContent, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import WifiOffIcon from '@mui/icons-material/WifiOff';

import { BORDER_RADIUS } from '../theme/constants';
import text from '../text.json';

const ConnectionLost = () => {
  const [isConnected, setIsConnected] = useState(window.navigator.onLine);

  const handleConnectionChange = (event) => {
    if (event.type === 'offline') {
      setIsConnected(false);
    }
    if (event.type === 'online') {
      setIsConnected(true);
    }
  };

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener(handleConnectionChange, null);
      window.removeEventListener(handleConnectionChange, null);
    };
  }, []);

  return (
    !isConnected && (
    <Box sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgba(255, 255, 255, 0.6)',
      zIndex: 1401
    }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open
        TransitionComponent={Slide}
        sx={{ zIndex: 1402 }}
      >
        <SnackbarContent
          style={{
            backgroundColor: 'grey.600',
            borderRadius: BORDER_RADIUS.SMALL,
            display: 'block',
            color: 'white'
          }}
          message={(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <WifiOffIcon fontSize="small" sx={{ mr: 2 }} />
              <Typography variant="body2">{text.connection.lost}</Typography>
            </Box>
          )}
        />
      </Snackbar>
    </Box>
    )
  );
};

export default ConnectionLost;
