import Axios from 'axios';
import { API_URL } from '../config';

export const getDatabases = (projectId) => Axios.get(`${API_URL}/FingerprintDatabase`, { params: { projectId } });

export const checkIfDatabaseNameExist = (data) => Axios.post(`${API_URL}/FingerprintDatabase/CheckNameAvailability`, data);

export const postDatabase = (data, projectId) => Axios.post(`${API_URL}/FingerprintDatabase/${projectId}`, data);

export const uploadIndexFile = (file, databaseId) => Axios.post(
  `${API_URL}/FingerprintDatabase/${databaseId}/IndexFile/Upload`,
  file,
  { headers: { 'Content-Type': 'multipart/form-data' } }
);

export const getUploadFpdbTemporaryUrl = (databaseId, ipAddress) => Axios.get(`${API_URL}/FingerprintDatabase/${databaseId}/GenerateLinkForUploadDataset`, {
  params: {
    ipAddress
  }
});

export const downloadIndexFile = (databaseId) => Axios.get(`${API_URL}/FingerprintDatabase/${databaseId}/IndexFile/Download`, {
  headers: {
    'Content-Type': 'application/octet-stream',
  },
  responseType: 'blob'
});

export const deleteDatabase = (databaseId) => Axios.put(`${API_URL}/FingerprintDatabase/RequestRemoval/${databaseId}`);

export const putDatabase = (databaseId, data) => Axios.put(`${API_URL}/FingerprintDatabase/${databaseId}`, data);

export const getIpAddress = async () => {
  const res = await fetch('https://geolocation-db.com/json/');
  const data = await res.json();
  return data.IPv4;
};
