import {
  Avatar,
  Box,
  Drawer,
  List,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { grey } from '@mui/material/colors';

import { ROLES, ROUTES } from '../../config';
import { logo } from '../../assets/icons';
import { ReactComponent as ActivityLogsIcon } from '../../assets/icons/activity_logs.svg';
import { ReactComponent as ReleasesIcon } from '../../assets/icons/releases.svg';
import { ReactComponent as SensorsIcon } from '../../assets/icons/sensors.svg';
import { ReactComponent as ChangeCustomerIcon } from '../../assets/icons/changeCustomer.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import NavigationLink from './NavigationLink';
import ButtonLink from './ButtonLink';
import { sessionExpired } from '../../redux/authSlice';
import { setCustomerInfo } from '../../redux/customerSlice';
import text from '../../text.json';
import { getFullRoute } from '../helper';
import ProcessIcon from '../../shared/ProcessIcon';
import { isReleaseInBuild } from '../../utils/releaseHelper';
import { getCurrentUserRole } from '../../utils/authHelper';

const Sidebar = () => {
  const { customerInfo } = useSelector((state) => state.customer);
  const { loggedUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
    >
      <Box>
        <Toolbar
          variant="dense"
          sx={{
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => navigate(
            getFullRoute(
              getCurrentUserRole(loggedUser),
              ROUTES.ACTIVITY_LOGS,
              customerInfo?.id
            )
          )}
        >
          <img src={logo} alt="logo" width="130px" height="38px" />
        </Toolbar>
        <List>
          <NavigationLink
            text={text.sections.activityLogs}
            icon={ActivityLogsIcon}
            to={getFullRoute(
              getCurrentUserRole(
                loggedUser
              ),
              ROUTES.ACTIVITY_LOGS,
              customerInfo?.id
            )}
            sx={{ marginTop: '46px' }}
          />
          <NavigationLink
            text={text.sections.sensors}
            icon={SensorsIcon}
            to={getFullRoute(getCurrentUserRole(loggedUser), ROUTES.SENSORS, customerInfo?.id)}
          />
          <Box sx={{ position: 'relative' }}>
            <NavigationLink
              text={text.sections.releases}
              icon={ReleasesIcon}
              to={getFullRoute(getCurrentUserRole(loggedUser), ROUTES.RELEASES, customerInfo?.id)}
            />
            {customerInfo?.latestRelease && (
              <>
                {isReleaseInBuild(customerInfo) && (
                  <ProcessIcon
                    style={{
                      position: 'absolute',
                      width: '35px',
                      height: '35px',
                      right: 0,
                      top: 0,
                      marginTop: '9px',
                      marginRight: '10px'
                    }}
                  />
                )}
                {customerInfo?.latestRelease?.buildFailedMessage && (
                <ErrorIcon
                  sx={{
                    color: 'general.error',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    marginTop: '12px',
                    marginRight: '10px'
                  }}
                />
                )}
              </>
            )}
          </Box>
        </List>
      </Box>
      <List>
        {loggedUser && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
            borderTop: `1px solid ${grey[200]}`,
            pt: 2,
            px: 1.5,
            width: '100%'
          }}
          >
            <Avatar
              sx={(theme) => ({
                backgroundColor: 'secondary.main',
                fontSize: theme.typography.body2,
                width: '32px',
                height: '32px'
              })}
            >
              {loggedUser?.idTokenClaims?.given_name?.charAt(0)?.toUpperCase()
              || loggedUser?.idTokenClaims?.emails?.[0]?.charAt(0)?.toUpperCase()}
              {loggedUser?.idTokenClaims?.family_name?.charAt(0)?.toUpperCase()
              || loggedUser?.idTokenClaims?.emails?.[0]?.charAt(1)?.toUpperCase()}
            </Avatar>
            <Box sx={{
              ml: 1,
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
            >
              {(loggedUser?.idTokenClaims?.given_name
              || loggedUser?.idTokenClaims?.family_name) && (
                <Typography noWrap variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>
                  {`${loggedUser?.idTokenClaims?.given_name || ''} ${loggedUser?.idTokenClaims?.family_name || ''}`}
                </Typography>
              )}
              <Typography sx={{ lineBreak: 'anywhere', fontSize: '12px !important' }}>
                {loggedUser?.idTokenClaims?.emails?.[0]}
              </Typography>
            </Box>
          </Box>
        )}
        {getCurrentUserRole(loggedUser) === ROLES.ADMIN && (
          <ButtonLink
            text={text.buttons.changeCustomer}
            icon={ChangeCustomerIcon}
            onClick={() => {
              dispatch(setCustomerInfo(null));
              navigate(ROUTES.CUSTOMERS);
            }}
          />
        )}
        <ButtonLink
          text={text.buttons.logout}
          icon={LogoutIcon}
          onClick={() => dispatch(sessionExpired())}
        />
      </List>
    </Drawer>
  );
};

export default Sidebar;
