export const highlightElementInArray = (array, payload) => {
  const updatedObjectIndex = array?.findIndex(
    ({ id }) => id === payload.data?.id
  );

  const arrayTmp = array?.map((object) => {
    const objectTmp = { ...object };
    if (objectTmp.isHighlighted) {
      objectTmp.isHighlighted = false;
    }

    return objectTmp;
  });
  arrayTmp.splice(updatedObjectIndex, 1, { ...payload.data, isHighlighted: true });
  return arrayTmp;
};
