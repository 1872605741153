import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import BuildIcon from '../../shared/BuildIcon';
import text from '../../text.json';
import { BORDER_RADIUS, BOX_SHADOWS } from '../../theme/constants';

const ReleaseInBuild = ({ data }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mt: 3.5,
      mx: 2,
      px: 2,
      pt: 1,
      backgroundColor: 'general.infoLight',
      borderRadius: BORDER_RADIUS.SMALL,
      boxShadow: BOX_SHADOWS.CLICKABLE_BOX,
    }}
  >
    <Box>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 'fontWeightBold',
          color: 'secondary.main'
        }}
      >
        {data?.name || text.releases.tagNamePlaceholder}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <BuildIcon
          style={{
            width: '50px',
            height: '50px',
            marginLeft: '-15px',
            marginRight: '-20px',
            marginTop: '-5px'
          }}
        />
        <Typography sx={{ fontWeight: 'fontWeightBold', mb: 2, ml: 1.5 }} variant="caption">
          {text.releases.releaseInBuildInfo}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default ReleaseInBuild;

ReleaseInBuild.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
  })
};

ReleaseInBuild.defaultProps = {
  data: {}
};
