import { createSlice } from '@reduxjs/toolkit';

import { addAlgorithm, fetchAlgorithms, removeAlgorithm } from './api/algorithm';
import { highlightElementInArray } from './helper';

const initialState = {
  algorithms: [],
  algorithmFromLogsId: ''
};

export const algorithmSlice = createSlice({
  name: 'algorithm',
  initialState,
  reducers: {
    setAlgorithms: (state, action) => {
      state.algorithms = action.payload;
    },
    setAlgorithmFromLogsId: (state, action) => {
      state.algorithmFromLogsId = action.payload;
    }
  },
  extraReducers: {
    [fetchAlgorithms.fulfilled]: (state, action) => {
      let algorithmFromLogsIndex = null;
      if (state.algorithmFromLogsId) {
        algorithmFromLogsIndex = action.payload.data?.findIndex(
          ({ id }) => id === state.algorithmFromLogsId
        );
      }
      if (algorithmFromLogsIndex === 0 || algorithmFromLogsIndex) {
        const algorithms = [...action.payload.data];
        algorithms.splice(algorithmFromLogsIndex, 1);
        state.algorithms = [
          { ...action.payload.data?.[algorithmFromLogsIndex], isHighlighted: true },
          ...algorithms
        ];
      } else {
        state.algorithms = action.payload.data;
      }
    },
    [addAlgorithm.fulfilled]: (state, action) => {
      state.algorithms = [action.payload.data, ...state.algorithms];
      state.algorithmFromLogsId = '';
    },
    [removeAlgorithm.fulfilled]: (state, action) => {
      state.algorithms = highlightElementInArray(state.algorithms, action.payload);
      state.algorithmFromLogsId = '';
    },
  },
});

export const { setAlgorithms, setAlgorithmFromLogsId } = algorithmSlice.actions;

export default algorithmSlice.reducer;
