export const columns = [
  {
    name: 'Date',
    key: 'date'
  },
  {
    name: 'Activity',
    key: 'activity'
  }
];
