import { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../shared/Modal';
import text from '../../text.json';
import { GENERAL_ERROR_MESSAGE, NAME_MAX_NUMBER_CHARACTERS } from '../../config';
import { getNameErrorMessage } from './validation';
import { checkIfDatabaseNameExist } from '../../services/databases';
import { setErrorMessage } from '../../redux/snackbarSlice';
import Message, { MESSAGE_TYPE } from '../../shared/Message';
import { addDatabase } from '../../redux/api/database';

const AddDatabase = ({ handleClose, isOpen }) => {
  const { metaData } = useSelector((state) => state.general);
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [databaseData, setDatabaseData] = useState({
    name: '',
    collectionCountry: metaData?.countries?.[0] || '',
  });

  const handleAddDatabase = async () => {
    const { name } = databaseData;

    let isDataValid = true;
    setIsLoading(true);

    try {
      const nameErrorMessageTmp = await
      getNameErrorMessage(name, () => checkIfDatabaseNameExist({ name, projectId }));

      if (nameErrorMessageTmp) {
        setNameErrorMessage(nameErrorMessageTmp);
        isDataValid = false;
      }
    } catch (error) {
      dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
      isDataValid = false;
    }

    if (isDataValid) {
      const result = await dispatch(addDatabase({ databaseData, projectId }));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.add.title.replace('{{object}}', text.objects.database)}
      primaryButtonText={text.buttons.add.replace('{{object}}', text.objects.database)}
      onPrimaryButtonClick={handleAddDatabase}
      isLoading={isLoading}
    >
      <Message
        type={MESSAGE_TYPE.INFO}
        message={text.addDatabase.infoMessage}
        sx={{ mb: 4 }}
      />
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        label={`${text.commonInputFields.name} *`}
        variant="standard"
        onChange={(event) => {
          setDatabaseData((prevDatabaseData) => (
            { ...prevDatabaseData, name: event.target.value }));
          setNameErrorMessage('');
        }}
        helperText={nameErrorMessage}
        error={!!nameErrorMessage}
        inputProps={{
          'data-testid': 'nameInputTest',
          maxLength: NAME_MAX_NUMBER_CHARACTERS,
        }}
      />
      <Autocomplete
        disableClearable
        options={metaData?.countries}
        onChange={(event, value) => setDatabaseData(
          (prevDatabaseData) => ({ ...prevDatabaseData, collectionCountry: value })
        )}
        defaultValue={metaData?.countries?.[0]}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            label={`${text.addDatabase.country} *`}
          />
        )}
      />
    </Modal>
  );
};

export default AddDatabase;

AddDatabase.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

AddDatabase.defaultProps = {
  isOpen: false
};
