import { ROLES } from '../config';

export const getCurrentUserRole = (account) => {
  if (account?.idTokenClaims) {
    return (
      account.idTokenClaims?.extension_EmployerId ? ROLES.USER : ROLES.ADMIN
    );
  }

  return null;
};

export const getCustomerIdFromToken = (account) => account?.idTokenClaims?.extension_EmployerId;
