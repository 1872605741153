import {
  Box, Tooltip, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

import { ADMIN_ROLES, ROUTES } from '../../config';
import { BORDER_RADIUS, BOX_SHADOWS, PALETTE } from '../../theme/constants';
import BuildRelease from '../Modals/BuildRelease';
import CustomerActions from './CustomerActions';
import text from '../../text.json';
import ProcessIcon from '../../shared/ProcessIcon';
import { isReleaseInBuild } from '../../utils/releaseHelper';
import Restricted from '../../shared/Restricted';

const Customer = ({ data }) => {
  const [isBuildReleaseModalOpen, setIsBuildReleaseModalOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Box
        data-testid="customerTest"
        onClick={() => navigate(`${data?.id}/${ROUTES.ACTIVITY_LOGS}`)}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '185px',
          background: 'white',
          borderRadius: BORDER_RADIUS.MEDIUM,
          boxShadow: BOX_SHADOWS.CLICKABLE_BOX,
          p: 1,
          '&:hover': {
            cursor: 'pointer',
            boxShadow: BOX_SHADOWS.CLICKABLE_BOX_HOVER
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            p: 1,
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          {data?.latestRelease && (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{
                width: '30px', height: '30px', display: 'flex', justifyContent: 'center'
              }}
              >
                {isReleaseInBuild(data) && (
                  <ProcessIcon style={{ width: '20px', height: '20px' }} />
                )}
                {data?.latestRelease?.buildFailedMessage && (
                  <ErrorIcon sx={{ color: 'general.error', fontSize: '20px' }} />
                )}
              </Box>
              {(isReleaseInBuild(data) || data?.latestRelease?.buildFailedMessage) && (
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{
                    __html: !data?.latestRelease?.buildFailedMessage
                      ? text.releases.specificReleaseInBuildInfo.replace('{{name}}', data?.latestRelease?.name || '')
                      : text.releases.specificReleaseInBuildFail.replace('{{name}}', data?.latestRelease?.name || '')
                  }}
                  sx={{
                    fontSize: '12px',
                    ml: 0.5,
                    ...data?.latestRelease?.buildFailedMessage ? { color: 'general.error' } : {}
                  }}
                />
              )}
            </Box>
          )}
          <Restricted to={[ADMIN_ROLES.PRECISE]}>
            <CustomerActions
              handleBuildingRelease={() => setIsBuildReleaseModalOpen(true)}
              customerInfo={data}
            />
          </Restricted>
        </Box>
        <Tooltip title={data?.name} followCursor enterDelay={700}>
          <Typography noWrap variant="h5" color={PALETTE.secondary.main}>
            {data?.name}
          </Typography>
        </Tooltip>
      </Box>
      {isBuildReleaseModalOpen && (
      <BuildRelease
        handleClose={() => setIsBuildReleaseModalOpen(false)}
        isOpen={isBuildReleaseModalOpen}
        customerId={data?.id}
        title={text.buildRelease.questionWithSpecificCustomer.replace('{{customer}}', data?.name)}
      />
      )}
    </>
  );
};

Customer.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    latestRelease: PropTypes.shape({
      buildFailedMessage: PropTypes.string,
      zipUrl: PropTypes.string,
      name: PropTypes.string,
    })
  }).isRequired
};

export default Customer;
