import {
  Box, Button, CircularProgress, List, ListSubheader, Tooltip, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BuildReleaseIcon from '@mui/icons-material/Rocket';

import Release from './Release';
import { fetchReleases } from '../../redux/api/release';
import NoDataFound from '../../shared/NoDataFound';
import { setReleases } from '../../redux/releaseSlice';
import { getTopExtraCardLayerStyle } from '../helper';
import text from '../../text.json';
import { ADMIN_ROLES, ROLES } from '../../config';
import BuildRelease from '../Modals/BuildRelease';
import ReleaseInBuild from './ReleaseInBuild';
import { isReleaseInBuild } from '../../utils/releaseHelper';
import { getCurrentUserRole } from '../../utils/authHelper';
import Restricted from '../../shared/Restricted';

const Releases = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBuildReleaseModalOpen, setIsBuildReleaseModalOpen] = useState(false);

  const { releases } = useSelector((state) => state.release);
  const { customerInfo } = useSelector((state) => state.customer);
  const { loggedUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(setReleases([]));
  }, []);

  useEffect(() => {
    if (customerInfo) {
      (async () => {
        await dispatch(fetchReleases(customerInfo?.id));
        setIsLoading(false);
      })();
    }
  }, [customerInfo]);

  return (
    <>
      <List
        sx={{
          ...getTopExtraCardLayerStyle().CONTAINER,
          display: 'block',
          width: '100%',
          p: 0,
          pb: 3,
          height: '100%',
          overflowY: 'scroll',
          alignSelf: 'center',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          }
        }}
        subheader={(
          <ListSubheader
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'gray.containerLight',
              py: 2
            }}
            component="div"
          >
            <Typography sx={(theme) => ({ ...getTopExtraCardLayerStyle(theme).TITLE })}>
              {text.sections.releases}
            </Typography>
            {getCurrentUserRole(loggedUser) === ROLES.ADMIN && customerInfo && (
              <Restricted to={[ADMIN_ROLES.PRECISE]}>
                <Tooltip title={isReleaseInBuild(customerInfo) ? text.buildRelease.disabledInfo : ''}>
                  <div>
                    <Button
                      variant="primary"
                      onClick={() => setIsBuildReleaseModalOpen(true)}
                      disabled={isReleaseInBuild(customerInfo)}
                    >
                      <BuildReleaseIcon sx={{ mr: 1 }} />
                      {text.buttons.build.replace('{{object}}', text.objects.release)}
                    </Button>
                  </div>
                </Tooltip>
              </Restricted>
            )}
          </ListSubheader>
      )}
      >
        {!releases.length ? (
          <Box
            sx={{
              width: '100%',
              height: 'calc(100% - 115px)',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isLoading && <CircularProgress />}
            {!isLoading && <NoDataFound />}
          </Box>
        )
          : (
            <>
              {isReleaseInBuild(customerInfo) && !isLoading && (
              <ReleaseInBuild data={customerInfo?.latestRelease} />
              )}
              {releases?.map((release, index) => (
                (isReleaseInBuild(customerInfo)
                && release?.id !== customerInfo?.latestRelease?.id)
                  || !isReleaseInBuild(customerInfo) ? (
                    <Release
                      key={release?.id}
                      data={release}
                      index={isReleaseInBuild(customerInfo) ? index + 1 : index}
                    />
                  ) : null
              ))}
            </>
          )}
      </List>
      {isBuildReleaseModalOpen && (
      <BuildRelease
        handleClose={() => setIsBuildReleaseModalOpen(false)}
        isOpen={isBuildReleaseModalOpen}
        customerId={customerInfo?.id}
      />
      )}
    </>
  );
};

export default Releases;
