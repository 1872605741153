import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import processAnimation from '../assets/lotties/process.json';
import { PALETTE } from '../theme/constants';

const ProcessIcon = ({ style }) => (
  <Lottie
    style={{
      width: '30px',
      height: '30px',
      backgroundColor: PALETTE.general.info,
      borderRadius: '50%',
      ...style
    }}
    animationData={processAnimation}
    loop
  />
);

export default ProcessIcon;

ProcessIcon.propTypes = {
  style: PropTypes.shape({})
};

ProcessIcon.defaultProps = {
  style: {}
};
