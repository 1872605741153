import { createAsyncThunk } from '@reduxjs/toolkit';

import { getReleaseAlgorithms, getReleases, postRelease } from '../../services/releases';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchReleases = createAsyncThunk('release/fetchReleases', async (customerId, thunkAPI) => {
  try {
    return await getReleases(customerId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const fetchReleaseAlgorithms = createAsyncThunk('release/fetchReleaseAlgorithms', async (releaseId, thunkAPI) => {
  try {
    return await getReleaseAlgorithms(releaseId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const buildRelease = createAsyncThunk('release/buildRelease', async (data, thunkAPI) => {
  try {
    return await postRelease(data);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
