import { BORDER_RADIUS, BOX_SHADOWS, PALETTE } from './constants';

export const BUTTONS = {
  PRIMARY: {
    props: {
      variant: 'primary',
    },
    style: {
      color: 'white',
      backgroundColor: PALETTE.primary.main,
      borderRadius: BORDER_RADIUS.MEDIUM,
      textTransform: 'none',
      whiteSpace: 'nowrap',
      padding: '9px 20px',
      fontSize: '18px',
      fontWeight: 700,
      minWidth: '74px',
      border: `1px solid ${PALETTE.primary.main}`,
      '&:hover': { backgroundColor: PALETTE.primary.dark },
      '&:disabled': {
        color: PALETTE.gray.disabledTextColor,
        backgroundColor: PALETTE.gray.disabledBackgroundColor,
        borderColor: PALETTE.gray.disabledBackgroundColor,
        boxShadow: BOX_SHADOWS.CLICKABLE_BOX,
      },
    },
  },
  SECONDARY: {
    props: {
      variant: 'secondary',
    },
    style: {
      color: 'black',
      fontWeight: 600,
      backgroundColor: 'white',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      padding: '9px 20px',
      fontSize: '18px',
      minWidth: '74px',
      borderRadius: BORDER_RADIUS.MEDIUM,
      border: '1px solid black',
      '&:hover': {
        backgroundColor: 'white',
      },
      '&:disabled': {
        color: PALETTE.gray.disabledTextColor,
        backgroundColor: PALETTE.gray.disabledBackgroundColor,
        borderColor: PALETTE.gray.disabledBackgroundColor,
      },
    },
  },
  DESTRUCTIVE: {
    props: {
      variant: 'destructive',
    },
    style: {
      color: 'white',
      backgroundColor: PALETTE.general.error,
      borderRadius: BORDER_RADIUS.MEDIUM,
      textTransform: 'none',
      whiteSpace: 'nowrap',
      padding: '9px 20px',
      fontSize: '18px',
      fontWeight: 700,
      minWidth: '74px',
      border: `1px solid ${PALETTE.general.error}`,
      '&:hover': { backgroundColor: PALETTE.general.errorDark },
      '&:disabled': {
        color: PALETTE.gray.disabledTextColor,
        backgroundColor: PALETTE.gray.disabledBackgroundColor,
        borderColor: PALETTE.gray.disabledBackgroundColor,
      },
    },
  },
  LINK: {
    props: {
      variant: 'link'
    },
    style: {
      textTransform: 'none',
      fontSize: '16px',
      color: PALETTE.secondary.dark,
      padding: 0,
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
      },
    }
  },
  DISABLED_LINK: {
    props: {
      variant: 'disabledLink'
    },
    style: {
      fontSize: '18px',
      textTransform: 'none',
      color: PALETTE.secondary.dark,
      padding: 0,
      '&:hover': {
        cursor: 'default',
        color: PALETTE.secondary.dark,
        background: 'none',
      }
    }
  }
};
