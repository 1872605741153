import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import PropTypes from 'prop-types';

const SelectInput = ({
  label,
  handleChange,
  options,
  value,
  sx
}) => (
  <FormControl sx={sx} variant="standard" fullWidth>
    <InputLabel id="select-label">{label}</InputLabel>
    <Select
      variant="standard"
      value={value}
      label={label}
      onChange={handleChange}
      sx={{
        '.MuiInput-input': {
          '&:focus': {
            background: 'transparent'
          }
        }
      }}
    >
      {options?.map(({ name, id }, index) => (
        <MenuItem key={index} value={id}>{name}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectInput;

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
  sx: PropTypes.shape({})
};

SelectInput.defaultProps = {
  sx: {}
};
