import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedUser: null,
  shouldUserBeLoggedOut: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sessionExpired: (state) => {
      state.loggedUser = null;
      state.shouldUserBeLoggedOut = true;
    },
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  sessionExpired,
  setLoggedUser
} = authSlice.actions;

export default authSlice.reducer;
