import text from '../../../../../text.json';

export const columns = [
  {
    name: text.commonInputFields.name,
    key: 'name'
  },
  {
    name: text.addDatabase.country,
    key: 'collectionCountry'
  },
  {
    name: text.databases.status,
    key: 'status'
  },
  {
    name: '',
    key: 'action'
  }
];

export const INDEX_FILE_ALLOWED_FILE_TYPE = {
  'text/html': ['.fpdbindex']
};

export const FPDB_ALLOWED_FILE_TYPE = {
  'application/zip': ['.zip'],
  'application/x-7z-compressed': ['.7z'],
  'application/vnd.rar': ['.rar'],
  'application/gzip': ['.gz']
};
