import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton, Menu, MenuItem, Tooltip, Typography
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import BuildReleaseIcon from '@mui/icons-material/Rocket';

import text from '../../text.json';
import { BORDER_RADIUS } from '../../theme/constants';
import { MENU_ICON_STYLE, MENU_ITEM_STYLE } from '../Sensors/OverviewsShared/config';
import { isReleaseInBuild } from '../../utils/releaseHelper';

const CustomerActions = ({ handleBuildingRelease, customerInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ justifySelf: 'flex-end' }} onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(event) => handleClose(event)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '.MuiPaper-root': { borderRadius: BORDER_RADIUS.SMALL }, '.MuiList-root': { p: 0 } }}
      >
        <Tooltip title={isReleaseInBuild(customerInfo) ? text.buildRelease.disabledInfo : ''}>
          <div>
            <MenuItem
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleBuildingRelease();
                handleClose(event);
              }}
              sx={MENU_ITEM_STYLE}
              disabled={isReleaseInBuild(customerInfo)}
            >
              <BuildReleaseIcon sx={MENU_ICON_STYLE} fontSize="small" />
              <Typography variant="body2">
                {text.buttons.build.replace('{{object}}', text.objects.release)}
              </Typography>
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
    </>
  );
};

export default CustomerActions;

CustomerActions.propTypes = {
  handleBuildingRelease: PropTypes.func.isRequired,
  customerInfo: PropTypes.shape({
    latestRelease: PropTypes.shape({
      zipUrl: PropTypes.string,
      buildFailedMessage: PropTypes.string
    })
  }).isRequired
};
