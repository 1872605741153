import {
  Box,
  CircularProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { REMOVAL_REQUESTED } from '../../../config';
import StatusFlag from '../../../shared/StatusFlag';
import { BORDER_RADIUS, BOX_SHADOWS } from '../../../theme/constants';
import text from '../../../text.json';

const SensorDetails = () => {
  const { sensor } = useSelector((state) => state.sensor);
  const { project } = useSelector((state) => state.project);
  const { projectId } = useParams();

  const sectionTitleCommonProps = {
    sx: (theme) => ({
      color: 'gray.disabledTextColor',
      fontWeight: 'fontWeightBold',
      fontSize: theme.typography.caption
    }),
    variant: 'button'
  };

  const cardNameCommonProps = {
    sx: {
      color: 'secondary.main',
      fontWeight: 'fontWeightBold',
      mt: 0.2,
      overflow: 'hidden',
      textTransform: 'ellipsis'
    },
    variant: 'h5',
    noWrap: true
  };

  return (
    <Box sx={{
      backgroundColor: projectId ? 'secondary.light20' : 'white',
      ml: { xs: 1, md: 1.5, lg: 2 },
      borderRadius: BORDER_RADIUS.SMALL,
      width: { md: '40%', lg: '30%', xl: '25%' },
      maxHeight: '100%',
      overflowY: 'scroll',
      p: 3,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: BOX_SHADOWS.CONTAINER,
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      }
    }}
    >
      {!sensor || Object.keys(sensor).length === 0 ? <CircularProgress size={30} sx={{ alignSelf: 'center' }} />
        : (
          <>
            <Box mb={1.5}>
              {sensor?.isPendingToRemove && (
                <StatusFlag data={REMOVAL_REQUESTED} />
              )}
              <Tooltip title={sensor?.name}>
                <Typography {...cardNameCommonProps}>
                  {sensor?.name}
                </Typography>
              </Tooltip>
            </Box>
            <Typography {...sectionTitleCommonProps}>
              {text.commonInputFields.type}
            </Typography>
            <Typography
              sx={{ color: 'secondary.main', mb: 1.5 }}
              variant="subtitle1"
            >
              {sensor?.sensorType}
            </Typography>
            <Typography {...sectionTitleCommonProps}>
              {text.addSensor.size}
            </Typography>
            <Typography sx={{ color: 'secondary.main', mb: 1.5 }} variant="subtitle1">
              {`${sensor?.width}x${sensor?.height}`}
            </Typography>
            {sensor?.dotsPerInch !== 0 && sensor?.dotsPerInch && (
              <>
                <Typography {...sectionTitleCommonProps}>
                  {text.addSensor.resolution}
                </Typography>
                <Typography sx={{ color: 'secondary.main' }} variant="subtitle1">
                  {sensor.dotsPerInch}
                </Typography>
              </>
            )}
          </>
        )}
      {projectId && (
      <Box
        p={2}
        mt={2}
        sx={{
          background: 'white',
          borderRadius: BORDER_RADIUS.SMALL,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {!project || Object.keys(project).length === 0 ? (
          <CircularProgress size={30} sx={{ alignSelf: 'center' }} />) : (
            <>
              <Box mb={1.5}>
                {project?.isPendingToRemove && (
                  <StatusFlag data={REMOVAL_REQUESTED} />
                )}
                <Tooltip title={project?.name}>
                  <Typography {...cardNameCommonProps}>
                    {project?.name}
                  </Typography>
                </Tooltip>
              </Box>
              {project?.displayType && (
              <>
                <Typography {...sectionTitleCommonProps}>
                  {text.addProject.display}
                </Typography>
                <Typography sx={{ color: 'secondary.main', mb: 1.5 }} variant="subtitle1">
                  {project?.displayType}
                </Typography>
              </>
              )}
              {project?.dotsPerInch !== 0 && project?.dotsPerInch && (
              <>
                <Typography {...sectionTitleCommonProps}>
                  {text.addProject.resolution}
                </Typography>
                <Typography sx={{ color: 'secondary.main', mb: 1.5 }} variant="subtitle1">
                  {project.dotsPerInch}
                </Typography>
              </>
              )}
              <Typography {...sectionTitleCommonProps}>
                {text.addProject.platform}
              </Typography>
              <Typography sx={{ color: 'secondary.main' }} variant="subtitle1">
                {project?.platform}
              </Typography>
            </>
        )}
      </Box>
      )}
    </Box>

  );
};

export default SensorDetails;
