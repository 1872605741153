/* eslint-disable react/prop-types */
import { Navigate, Outlet } from 'react-router-dom';
import { ROLES, ROUTES } from '../config';
import BreadcrumbsLayout from '../layouts/BreadcrumbsLayout';

import PrivateLayout from '../layouts/PrivateLayout';

export const PublicOutlet = () => <Outlet />;

export const PrivateOutlet = () => <PrivateLayout />;

export const SensorOutlet = () => <BreadcrumbsLayout />;

export const AdminOutlet = ({ loggedUserRole: role }) => {
  if (role) {
    return (
      role !== ROLES.USER ? <Outlet /> : <Navigate to={ROUTES.ACTIVITY_LOGS} />
    );
  }
  return null;
};

export const UserOutlet = ({ loggedUserRole: role }) => {
  if (role) {
    return (
      role === ROLES.USER ? <Outlet /> : <Navigate to={ROUTES.CUSTOMERS} />
    );
  }
  return null;
};
