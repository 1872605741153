import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import { grey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

import text from '../text.json';
import UploadIcon from './UploadIcon';
import Message, { MESSAGE_TYPE } from './Message';

const CustomDropzone = ({
  maxFiles, accept, fileTypeName, files, setFiles, isLoading, doesFileAlreadyExists, isDisabled
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, [files]);

  const {
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles,
    accept,
    onDrop
  });

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  return (
    <Box>
      {doesFileAlreadyExists && (
        <Message type={MESSAGE_TYPE.WARNING} message={text.dropzone.oldFile} />
      )}
      {!files?.length && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          color: fileRejections?.length ? 'general.error' : 'grey.600',
          mb: 0.5,
        }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>
            {Object.values(accept)?.map((extensions) => extensions.join(' '))?.join(' ')}
          </Typography>
          <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'fontWeightBold' }}>
            {text.dropzone.filesAllowed}
          </Typography>
        </Box>
      )}
      <Box className="container" sx={{ border: `1px solid ${grey[300]}`, height: '300px', position: 'relative' }}>
        {isLoading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}
        >
          <UploadIcon style={{ width: '70px', height: '70px' }} />
        </Box>
        )}
        {!files?.length ? (
          <Box
            {...getRootProps({
              className: 'dropzone',
              ...isDisabled ? { onClick: (event) => event.stopPropagation() } : {},
              ...isDisabled ? { onDrop: (event) => event.stopPropagation() } : {},
            })}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              ...isDisabled ? {} : {
                '&:hover': {
                  cursor: 'pointer',
                  '> div': {
                    textDecoration: 'underline'
                  }
                }
              }
            }}
          >
            <input {...getInputProps()} />
            <FileIcon sx={(theme) => ({ color: isDisabled ? 'grey.200' : 'secondary.light30', fontSize: theme.typography.h1, mb: 1 })} />
            <Typography
              component="div"
              sx={{ color: isDisabled ? 'grey.400' : 'secondary.main', fontWeight: 'fontWeightBold' }}
            >
              {text.dropzone.select.replace('{{file}}', fileTypeName.toLowerCase())}
            </Typography>
            <Typography sx={{ textDecoration: 'none', color: isDisabled ? 'grey.300' : 'grey.600' }} variant="caption">
              {text.dropzone.drop}
            </Typography>
          </Box>
        ) : (
          files?.map((file, index) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'grey.100',
                px: 1,
                py: 1.5,
              }}
              key={index}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FileIcon sx={{ color: 'secondary.light30', mr: 1 }} />
                <Typography variant="body2">{file.path}</Typography>
              </Box>
              <CloseIcon
                sx={{ color: 'secondary.main', cursor: 'pointer', '&:hover': { transform: 'scale(1.05)', color: 'general.error' } }}
                onClick={() => removeFile(file)}
              />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default CustomDropzone;

CustomDropzone.propTypes = {
  maxFiles: PropTypes.number.isRequired,
  accept: PropTypes.shape({}).isRequired,
  fileTypeName: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
  setFiles: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  doesFileAlreadyExists: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

CustomDropzone.defaultProps = {
  isLoading: false,
  doesFileAlreadyExists: false,
  isDisabled: false
};
