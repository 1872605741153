import Axios from 'axios';
import { API_URL } from '../config';

export const getProjects = (sensorId) => Axios.get(`${API_URL}/Project`, { params: { sensorId } });

export const getProject = (projectId) => Axios.get(`${API_URL}/Project/${projectId}`);

export const checkIfProjectNameExist = (data) => Axios.post(`${API_URL}/Project/CheckNameAvailability`, data);

export const postProject = (data, customerId) => Axios.post(`${API_URL}/Project/${customerId}`, data);

export const deleteProject = (projectId) => Axios.put(`${API_URL}/Project/RequestRemoval/${projectId}`);

export const updateProject = (data, projectId) => Axios.put(`${API_URL}/Project/${projectId}`, data);
