import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import Modal from '../../../../../../shared/Modal';
import text from '../../../../../../text.json';
import ItemFlag from '../../../../../../shared/ItemFlag';
import Message, { MESSAGE_TYPE } from '../../../../../../shared/Message';
import { handleDownloadIndexFile } from '../helper';
import Restricted from '../../../../../../shared/Restricted';
import { ADMIN_ROLES } from '../../../../../../config';

const InvalidIndexFileModal = ({ handleClose, isOpen, databaseData }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.databases.invalidFile.replace('{{file}}', text.files.indexFile.toLocaleLowerCase())}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <ItemFlag label={text.objects.database} />
        <Typography variant="body2">{databaseData?.name}</Typography>
      </Box>
      <Message
        type={MESSAGE_TYPE.INVALID}
        message={databaseData?.indexFileErrorMessage}
        sx={{ mb: 4 }}
      />
      <Message
        type={MESSAGE_TYPE.INFO}
        message={text.databases.invalidFileInfo}
        sx={{ mb: 4 }}
      />
      <Restricted to={[ADMIN_ROLES.PRECISE]}>
        <Button
          variant="link"
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disabled={isDownloading}
          onClick={() => handleDownloadIndexFile(setIsDownloading, databaseData, dispatch)}
          startIcon={<DownloadIcon />}
          sx={(theme) => ({ textAlign: 'center', width: '100%', fontSize: theme.typography.body1 })}
        >
          {text.buttons.downloadFile.replace('{{file}}', text.files.indexFile.toLocaleLowerCase())}
        </Button>
      </Restricted>
    </Modal>
  );
};

export default InvalidIndexFileModal;

InvalidIndexFileModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  databaseData: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    isIndexFileUploaded: PropTypes.bool,
    indexFileErrorMessage: PropTypes.string
  }).isRequired
};

InvalidIndexFileModal.defaultProps = {
  isOpen: false,
};
