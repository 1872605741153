import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { BORDER_RADIUS } from '../../../theme/constants';
import text from '../../../text.json';

const NameGenerator = ({
  typeId, variant, sx, isInvalid
}) => {
  const { customerInfo } = useSelector((state) => state.customer);
  const { sensor } = useSelector((state) => state.sensor);
  const { project } = useSelector((state) => state.project);
  const { metaData } = useSelector((state) => state.general);

  return (
    <Box sx={{ ...sx }}>
      <Typography
        sx={{ fontWeight: 'fontWeightBold' }}
        variant="caption"
      >
        {`${text.commonInputFields.name} (${text.commonInputFields.autoGenerated})`}
      </Typography>
      <Grid
        container
        sx={{
          display: 'flex',
          borderRadius: BORDER_RADIUS.SMALL,
          color: isInvalid ? 'general.error' : 'grey.900'
        }}
      >
        <Grid item>
          <Typography variant="body2">{customerInfo?.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{`_${sensor?.name}`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{`_${project?.name}`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold', color: isInvalid ? 'general.error' : 'primary.dark' }}>
            {`_${metaData?.algoTypes?.find(
              ({ id }) => typeId === id
            )?.name}`}
          </Typography>
        </Grid>
        {variant && (
        <Grid item>
          <Typography
            variant="body2"
            sx={{ fontWeight: 'fontWeightBold', color: isInvalid ? 'general.error' : 'primary.dark' }}
          >
            {`_${variant}`}
          </Typography>
        </Grid>
        )}
        <Grid item>
          <Typography variant="body2">_algorithm</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NameGenerator;

NameGenerator.propTypes = {
  typeId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  sx: PropTypes.shape({}),
  isInvalid: PropTypes.bool
};

NameGenerator.defaultProps = {
  variant: '',
  sx: {},
  isInvalid: false
};
