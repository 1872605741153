import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import Modal from '../../shared/Modal';
import text from '../../text.json';
import { removeDatabase } from '../../redux/api/database';

const RemoveDatabase = ({ handleClose, isOpen, data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleRemoveDatabase = async () => {
    setIsLoading(true);

    const result = await dispatch(removeDatabase(data?.id));

    if (result?.error) {
      setIsLoading(false);
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      isDestructive
      isLoading={isLoading}
      handleClose={handleClose}
      isOpen={isOpen}
      primaryButtonText={text.buttons.remove}
      secondaryButtonText={text.buttons.cancel}
      onPrimaryButtonClick={handleRemoveDatabase}
      onSecondaryButtonClick={handleClose}
      modalTitle={text.remove.title.replace('{{name}}', data?.name)}
    >
      <Typography>
        {text.removeDatabase.description}
      </Typography>
    </Modal>
  );
};

export default RemoveDatabase;

RemoveDatabase.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
};

RemoveDatabase.defaultProps = {
  isOpen: false,
  data: {
    id: '',
    name: ''
  }
};
