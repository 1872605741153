import {
  Box, IconButton, Slide, Snackbar, SnackbarContent, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowLeftIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { BlobServiceClient } from '@azure/storage-blob';

import { TAB_INDEX } from '../..';
import { ROUTES } from '../../../../../../config';
import { setDatabaseFromLogsId, setUploadingProcessData } from '../../../../../../redux/databaseSlice';
import { BORDER_RADIUS } from '../../../../../../theme/constants';
import { getFullRoute } from '../../../../../helper';
import text from '../../../../../../text.json';
import { getColor, getIcon, getTitle } from './helper';
import { getCurrentUserRole } from '../../../../../../utils/authHelper';
import { updateDatabase } from '../../../../../../redux/api/database';
import { getUploadFpdbTemporaryUrl, getIpAddress } from '../../../../../../services/databases';

const UploadSnackbar = () => {
  const [isShrinked, setIsShrinked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const { uploadingProcessData } = useSelector((state) => state.database);
  const { loggedUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const upload = async () => {
    const file = uploadingProcessData?.file;
    const ipAddress = await getIpAddress();
    const temporaryUrl = await getUploadFpdbTemporaryUrl(
      uploadingProcessData?.databaseData?.id,
      ipAddress
    );

    if (file) {
      const blobService = new BlobServiceClient(
        `${temporaryUrl?.data?.url}${temporaryUrl?.data?.sas}`
      );
      const containerClient = blobService.getContainerClient(temporaryUrl?.data?.containerName);
      const blobClient = containerClient.getBlockBlobClient(`${temporaryUrl?.data?.filePath}/${file.name}`);
      const options = { blobHTTPHeaders: { blobContentType: file.type } };

      return blobClient.uploadData(file, {
        onProgress: (ev) => {
          setProgress((ev.loadedBytes / file.size) * 100);
        },
        options
      });
    }

    return null;
  };

  const handleUpload = async () => {
    try {
      dispatch(setUploadingProcessData({
        ...uploadingProcessData,
        isProgress: true,
        isFinished: false,
        isFailed: false
      }));
      const response = await upload();
      if (response) {
        if (response.errorCode != null) {
          dispatch(setUploadingProcessData({
            ...uploadingProcessData,
            isFinished: true,
            inProgress: false,
            isFailed: true
          }));
        } else {
          await dispatch(updateDatabase({
            databaseId: uploadingProcessData?.databaseData?.id,
            data: {
              isFingerprintDatasetsUploaded: true
            }
          }));
          dispatch(setUploadingProcessData({
            ...uploadingProcessData,
            isFinished: true,
            inProgress: false,
            isFailed: false
          }));
        }
      }
    } catch (error) {
      setProgress(0);
      dispatch(setUploadingProcessData({
        ...uploadingProcessData,
        isFinished: true,
        inProgress: false,
        isFailed: true
      }));
    } finally {
      setIsShrinked(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (uploadingProcessData && Object.keys(uploadingProcessData)?.length) {
        if (!isOpen) {
          setIsOpen(true);
        }

        if (!uploadingProcessData?.isFinished && !uploadingProcessData?.isProgress) {
          handleUpload();
        }
      }
    })();
  }, [uploadingProcessData]);

  const handleNavigation = () => {
    dispatch(setDatabaseFromLogsId(uploadingProcessData?.databaseData?.id));
    navigate(
      getFullRoute(
        getCurrentUserRole(loggedUser),
        `${ROUTES.SENSORS}/${uploadingProcessData?.databaseData?.sensorId}/${ROUTES.PROJECTS}/${uploadingProcessData?.databaseData?.projectId}`,
        uploadingProcessData?.databaseData?.customerId
      ),
      {
        state: {
          tabIndex: TAB_INDEX.DATABASES
        }
      }
    );
  };

  return (
    uploadingProcessData && (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      TransitionComponent={Slide}
      sx={{
        width: !isShrinked ? { xs: '100%', md: '40%', xl: '30%' } : { xs: '20%', md: '10%' },
        right: '0px !important',
        '.MuiPaper-root': {
          pl: !uploadingProcessData?.isFinished ? 0 : 2,
          ...!uploadingProcessData?.isFinished ? { minWidth: 0 } : {},
          borderTopRightRadius: '0px !important',
          borderBottomRightRadius: '0px !important'
        }
      }}
    >
      <SnackbarContent
        style={{
          backgroundColor: 'white',
          borderRadius: BORDER_RADIUS.SMALL,
          display: 'block',
          borderLeft: `8px solid ${getColor(uploadingProcessData?.isFinished, uploadingProcessData?.isFailed)}`,
          color: 'black',
        }}
        message={(
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {!uploadingProcessData?.isFinished && (
              <IconButton
                onClick={() => setIsShrinked((prevState) => !prevState)}
                sx={{ py: 0, '&:hover': { backgroundColor: 'transparent', color: 'general.info' } }}
                disableFocusRipple
                disableTouchRipple
                disableRipple
              >
                {isShrinked ? <ArrowLeftIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
              </IconButton>
            )}
            <Box sx={{ width: '90%' }}>
              <Box sx={{
                display: 'flex', alignItems: 'center', mb: 2, ...(isShrinked ? { justifyContent: 'center' } : {})
              }}
              >
                {getIcon(uploadingProcessData?.isFinished, uploadingProcessData?.isFailed)}
                <Slide timeout={50} direction="left" in={!isShrinked} mountOnEnter unmountOnExit>
                  <Typography
                    sx={{
                      fontWeight: 'fontWeightBold', color: getColor(uploadingProcessData?.isFinished, uploadingProcessData?.isFailed), ml: 1
                    }}
                    variant="body2"
                  >
                    {getTitle(uploadingProcessData?.isFinished, uploadingProcessData?.isFailed)}
                  </Typography>
                </Slide>
              </Box>
              <Box sx={{
                display: 'flex',
                mt: 1,
                justifyContent: 'space-between',
              }}
              >
                <Box sx={{ display: 'flex', width: '50%' }}>
                  <Slide timeout={50} direction="left" in={!isShrinked} mountOnEnter unmountOnExit>
                    <Typography noWrap variant="body2" sx={{ color: 'grey.800', mr: 1, fontWeight: 'fontWeightBold' }}>
                      {uploadingProcessData?.file?.path}
                    </Typography>
                  </Slide>
                  {!uploadingProcessData?.isFinished && (
                    <Typography variant="body2" sx={{ color: 'general.info', fontWeight: 'fontWeightBold' }}>
                      {`${Math.round(progress)}%`}
                    </Typography>
                  )}
                  {uploadingProcessData?.isFinished && uploadingProcessData?.isFailed && (
                    <IconButton
                      onClick={() => {
                        dispatch(
                          setUploadingProcessData({
                            ...uploadingProcessData,
                            isFinished: false,
                            isFailed: false
                          })
                        );
                      }}
                      sx={{ py: 0, color: 'general.error', '&:hover': { backgroundColor: 'transparent' } }}
                      disableFocusRipple
                      disableTouchRipple
                      disableRipple
                    >
                      <RefreshIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Slide timeout={50} direction="left" in={!isShrinked} mountOnEnter unmountOnExit>
                  <Typography
                    textAlign="end"
                    onClick={handleNavigation}
                    variant="caption"
                    noWrap
                    sx={{
                      width: '50%',
                      color: 'grey.700',
                      '&:hover': {
                        cursor: 'pointer', textDecoration: 'underline', color: 'secondary.main'
                      }
                    }}
                  >
                    {uploadingProcessData?.databaseData?.name}
                  </Typography>
                </Slide>
              </Box>
              <Slide timeout={50} direction="left" in={!isShrinked} mountOnEnter unmountOnExit>
                <Typography
                  component="div"
                  variant="caption"
                  sx={{
                    fontWeight: 'fontWeightBold',
                    color: 'grey.500',
                    mt: 2.5,
                    visibility: uploadingProcessData?.isFinished ? 'hidden' : 'visible'
                  }}
                >
                  {text.uploadingPopUp.warning}
                </Typography>
              </Slide>
            </Box>
            {uploadingProcessData?.isFinished && (
              <IconButton
                onClick={() => dispatch(setUploadingProcessData(null))}
                sx={{ py: 0, '&:hover': { backgroundColor: 'transparent' } }}
                disableFocusRipple
                disableTouchRipple
                disableRipple
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
    )}
      />
    </Snackbar>
    )
  );
};

export default UploadSnackbar;
