import { createSlice } from '@reduxjs/toolkit';

import { ERROR_MESSAGES, GENERAL_ERROR_MESSAGE, SUCCESS_MESSAGES } from '../config';
import { addAlgorithm, fetchAlgorithms, removeAlgorithm } from './api/algorithm';
import {
  addCustomer, fetchActivityLogs, fetchCustomerInfo, fetchCustomers
} from './api/customer';
import {
  addDatabase,
  addIndexFile,
  fetchDatabases,
  removeDatabase,
  updateDatabase
} from './api/database';
import { fetchMetaData } from './api/general';
import {
  addProject, editProject, fetchProject, fetchProjects, removeProject
} from './api/project';
import { buildRelease, fetchReleaseAlgorithms, fetchReleases } from './api/release';
import {
  addSensor, editSensor, fetchSensor, fetchSensors, removeSensor
} from './api/sensor';

const initialState = {
  successMessage: '',
  errorMessage: '',
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    resetSnackbar(state) {
      state.successMessage = '';
      state.errorMessage = '';
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    }
  },
  extraReducers: {
    [fetchMetaData.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchSensors.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchProjects.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchSensor.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchCustomerInfo.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchAlgorithms.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchProject.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchDatabases.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchCustomers.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchActivityLogs.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchReleases.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [fetchReleaseAlgorithms.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [updateDatabase.rejected]: (state) => {
      state.errorMessage = GENERAL_ERROR_MESSAGE;
    },
    [addSensor.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.ADD_SENSOR;
    },
    [addProject.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.ADD_PROJECT;
    },
    [addDatabase.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.ADD_DATABASE;
    },
    [addAlgorithm.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.ADD_ALGORITHM;
    },
    [addCustomer.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.ADD_CUSTOMER;
    },
    [removeSensor.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.REMOVE_SENSOR;
    },
    [removeProject.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.REMOVE_PROJECT;
    },
    [removeDatabase.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.REMOVE_DATABASE;
    },
    [removeAlgorithm.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.REMOVE_ALGORITHM;
    },
    [editSensor.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.EDIT_SENSOR;
    },
    [editProject.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.EDIT_PROJECT;
    },
    [addIndexFile.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.UPLOAD_INDEX_FILE;
    },
    [buildRelease.rejected]: (state) => {
      state.errorMessage = ERROR_MESSAGES.BUILD_RELEASE;
    },
    [addSensor.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.ADD_SENSOR;
    },
    [addProject.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.ADD_PROJECT;
    },
    [addDatabase.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.ADD_DATABASE;
    },
    [addAlgorithm.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.ADD_ALGORITHM;
    },
    [addCustomer.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.ADD_CUSTOMER;
    },
    [removeSensor.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.REMOVE_SENSOR;
    },
    [removeProject.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.REMOVE_PROJECT;
    },
    [removeDatabase.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.REMOVE_DATABASE;
    },
    [removeAlgorithm.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.REMOVE_ALGORITHM;
    },
    [editSensor.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.EDIT_SENSOR;
    },
    [editProject.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.EDIT_PROJECT;
    },
    [addIndexFile.fulfilled]: (state) => {
      state.successMessage = SUCCESS_MESSAGES.UPLOAD_INDEX_FILE;
    },
  },
});

export const { resetSnackbar, setErrorMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;
