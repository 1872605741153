import Axios from 'axios';

import { API_URL } from '../config';

export const getReleases = (customerId) => Axios.get(`${API_URL}/Release`, { params: { customerId } });

export const getReleaseAlgorithms = (releaseId) => Axios.get(`${API_URL}/Release/${releaseId}/Algorithms`);

export const postRelease = (data) => Axios.post(`${API_URL}/Release`, data);

export const downloadRelease = (releaseId) => Axios.get(`${API_URL}/Release/${releaseId}/Download`, {
  headers: {
    'Content-Type': 'application/octet-stream',
  },
  responseType: 'blob'
});
