import {
  Button,
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { BORDER_RADIUS } from '../theme/constants';

const Modal = ({
  children,
  handleClose,
  isOpen,
  modalTitle,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  isLoading,
  isDestructive,
  isPrimaryButtonDisabled,
  bodySx,
  titleSx
}) => (
  <Dialog
    onClose={isLoading ? () => {} : handleClose}
    open={isOpen}
    PaperProps={{
      sx: {
        borderRadius: BORDER_RADIUS.SMALL,
        p: 1
      }
    }}
    fullWidth
  >
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: 'secondary.main',
        fontWeight: 'fontWeightBold',
        ...titleSx
      }}
    >
      {modalTitle}
      <CloseIcon onClick={isLoading ? () => {} : handleClose} sx={{ ml: 5, cursor: 'pointer', '&:hover': { transform: 'scale(1.05)' } }} />
    </DialogTitle>
    {children && (
      <DialogContent sx={{ px: 10, ...bodySx }}>
        {children}
      </DialogContent>
    )}
    <DialogActions sx={{ mt: 5 }}>
      {secondaryButtonText && (
        <Button
          disabled={isLoading}
          onClick={onSecondaryButtonClick}
          variant="secondary"
        >
          {secondaryButtonText}
        </Button>
      )}
      {primaryButtonText && (
        <Button
          disabled={isLoading || isPrimaryButtonDisabled}
          onClick={onPrimaryButtonClick}
          variant={isDestructive ? 'destructive' : 'primary'}
          data-testid="primaryButtonModalTest"
        >
          {isLoading && <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />}
          {primaryButtonText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

export default Modal;

Modal.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDestructive: PropTypes.bool,
  isPrimaryButtonDisabled: PropTypes.bool,
  bodySx: PropTypes.shape({}),
  titleSx: PropTypes.shape({}),
};

Modal.defaultProps = {
  children: null,
  isOpen: false,
  modalTitle: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  isLoading: false,
  isDestructive: false,
  onPrimaryButtonClick: () => {},
  onSecondaryButtonClick: () => {},
  isPrimaryButtonDisabled: false,
  bodySx: {},
  titleSx: {}
};
