import { createSlice } from '@reduxjs/toolkit';
import { fetchReleases } from './api/release';

const initialState = {
  releases: [],
};

export const releaseSlice = createSlice({
  name: 'release',
  initialState,
  reducers: {
    setReleases: (state, action) => {
      state.releases = action.payload;
    },
  },
  extraReducers: {
    [fetchReleases.fulfilled]: (state, action) => {
      state.releases = action.payload.data;
    },
  },
});

export const { setReleases } = releaseSlice.actions;

export default releaseSlice.reducer;
