import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton, Menu, MenuItem, Typography
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

import text from '../../../text.json';
import { BORDER_RADIUS } from '../../../theme/constants';
import DatabaseRowActions from '../Projects/Project/Databases/DatabaseRowActions';
import { MENU_ICON_STYLE, MENU_ITEM_STYLE } from './config';

const RowActions = ({
  data, handleRemove, handleEdit, isDatabaseOverview
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(event) => handleClose(event)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '.MuiPaper-root': { borderRadius: BORDER_RADIUS.SMALL }, '.MuiList-root': { p: 0 } }}
      >
        {handleEdit && (
          <MenuItem
            sx={MENU_ITEM_STYLE}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleEdit(data);
              handleClose(event);
            }}
            divider
          >
            <EditIcon sx={MENU_ICON_STYLE} fontSize="small" />
            <Typography variant="body2">{text.buttons.edit}</Typography>
          </MenuItem>
        )}
        {isDatabaseOverview && (
          <DatabaseRowActions data={data} />
        )}
        {handleRemove && (
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleRemove({ id: data?.id, name: data?.name });
              handleClose(event);
            }}
            sx={{
              ...MENU_ITEM_STYLE,
              color: 'general.error',
              '&:hover': {
                backgroundColor: 'general.error',
                color: 'white',
                '.MuiSvgIcon-root': {
                  color: 'white',
                },
              },
              '.MuiSvgIcon-root': {
                color: 'general.error',
              },
            }}
          >
            <DeleteIcon sx={{ ...MENU_ICON_STYLE, color: 'general.error' }} fontSize="small" />
            <Typography variant="body2">{text.buttons.remove}</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default RowActions;

RowActions.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func,
  isDatabaseOverview: PropTypes.bool,
};

RowActions.defaultProps = {
  data: {},
  handleRemove: null,
  handleEdit: null,
  isDatabaseOverview: false
};
