/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ButtonLink = ({ onClick, text, icon: Icon }) => (
  <Link
    component="button"
    onClick={onClick}
  >
    <Icon width={25} alt="button link icon" />
    <Typography variant="body2" sx={{ marginLeft: 1 }}>
      {text}
    </Typography>
  </Link>
);

ButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.shape({}).isRequired,
};

export default ButtonLink;
