import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { fetchAlgorithms } from '../../../../../redux/api/algorithm';
import { setDatabases } from '../../../../../redux/databaseSlice';
import SensorDetails from '../../../OverviewsShared/SensorDetails';
import Table from '../../../OverviewsShared/Table';
import { columns } from './config';
import AddAlgorithm from '../../../../Modals/AddAlgorithm';
import text from '../../../../../text.json';
import { ADDING_REQUESTED, REMOVAL_REQUESTED } from '../../../../../config';
import RemoveAlgorithm from '../../../../Modals/RemoveAlgorithm';
import { setAlgorithmFromLogsId } from '../../../../../redux/algorithmSlice';
import { setItemNotFoundData } from '../../../../../redux/generalSlice';

const Algorithms = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredAlgorithms, setFilteredAlgorithms] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddAlgorithmModalOpen, setIsAddAlgorithmModalOpen] = useState(false);
  const [isRemoveAlgorithmModalOpen, setIsRemoveAlgorithmModalOpen] = useState(false);
  const [toBeRemovedAlgorithmData, setToBeRemovedAlgorithmData] = useState({});

  const dispatch = useDispatch();
  const { algorithms, algorithmFromLogsId } = useSelector((state) => state.algorithm);
  const { project } = useSelector((state) => state.project);
  const { projectId } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    (async () => {
      await dispatch(fetchAlgorithms(projectId));
      dispatch(setDatabases([]));
      setIsLoading(false);
    })();

    return () => {
      dispatch(setAlgorithmFromLogsId(''));
    };
  }, []);

  useEffect(() => {
    if (algorithms?.length && algorithmFromLogsId) {
      if (algorithmFromLogsId !== algorithms[0]?.id) {
        dispatch(setItemNotFoundData({ name: state?.objectName }));
      }
    }
  }, [algorithmFromLogsId, algorithms]);

  useEffect(() => {
    if (algorithms?.length) {
      setFilteredAlgorithms(algorithms.filter(
        ({
          name, algoType, isPendingToRemove, isPendingToAdd
        }) => (
          name?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || algoType?.toString()?.toLowerCase().includes(searchValue.toLocaleLowerCase())
          || (isPendingToRemove && REMOVAL_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          ))
          || (isPendingToAdd && ADDING_REQUESTED.text.toLowerCase().includes(
            searchValue.toLocaleLowerCase()
          )))
      ));
    } else if (!isLoading && !algorithms?.length) {
      setFilteredAlgorithms([]);
    }
  }, [searchValue, algorithms, isLoading]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" sx={{ height: '100%' }}>
        <Table
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isLoading={isLoading}
          rows={filteredAlgorithms}
          columns={columns}
          buttonText={`+ ${text.buttons.add.replace('{{object}}', text.objects.algorithm)}`}
          onButtonClick={() => setIsAddAlgorithmModalOpen(true)}
          isButtonDisabled={project?.isPendingToRemove}
          disabledButtonTooltipText={text.tooltips.projectToBeRemoved}
          handleRemove={(projectData) => {
            setToBeRemovedAlgorithmData(projectData);
            setIsRemoveAlgorithmModalOpen(true);
          }}
          sx={{ borderTopLeftRadius: 0 }}
        />
        <SensorDetails />
      </Box>
      {isAddAlgorithmModalOpen && (
        <AddAlgorithm
          handleClose={() => setIsAddAlgorithmModalOpen(false)}
          isOpen={isAddAlgorithmModalOpen}
        />
      )}
      {isRemoveAlgorithmModalOpen && (
        <RemoveAlgorithm
          handleClose={() => {
            setIsRemoveAlgorithmModalOpen(false);
            setToBeRemovedAlgorithmData({});
          }}
          isOpen={isRemoveAlgorithmModalOpen}
          data={toBeRemovedAlgorithmData}
        />
      )}
    </>
  );
};

export default Algorithms;
