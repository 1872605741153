import { ROLES, ROUTES } from '../config';
import { BORDER_RADIUS, BOX_SHADOWS } from '../theme/constants';

export const getFullRoute = (role, route, customerId) => {
  if (role === ROLES.ADMIN) {
    return `/${ROUTES.CUSTOMERS}/${customerId}/${route}`;
  }
  return `/${route}`;
};

export const getTopExtraCardLayerStyle = (theme = {}) => ({
  CONTAINER: {
    backgroundColor: 'gray.containerLight',
    p: 2,
    borderRadius: BORDER_RADIUS.SMALL,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: BOX_SHADOWS.CONTAINER,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  TITLE: {
    color: 'secondary.main',
    fontSize: theme?.typography?.h6,
    fontWeight: theme?.typography?.fontWeightBold,
    backgroundColor: 'gray.containerLight',
  },
  SUBTITLE: {
    color: 'gray.disabledTextColor',
    mt: 0.5,
  }
});
