import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import processAnimation from '../assets/lotties/mainLoader.json';

const MainLoaderIcon = ({ style }) => (
  <Lottie
    style={{
      width: '180px',
      height: '180px',
      ...style
    }}
    animationData={processAnimation}
    loop
  />
);

export default MainLoaderIcon;

MainLoaderIcon.propTypes = {
  style: PropTypes.shape({})
};

MainLoaderIcon.defaultProps = {
  style: {}
};
