import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox, FormControlLabel, FormGroup, Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';

import Modal from '../../../shared/Modal';
import SelectInput from '../../../shared/SelectInput';
import { getVariantErrorMessage } from '../validation';
import { checkIfAlgorithmVariantExist } from '../../../services/algorithms';
import { setErrorMessage } from '../../../redux/snackbarSlice';
import { GENERAL_ERROR_MESSAGE } from '../../../config';
import { addAlgorithm } from '../../../redux/api/algorithm';
import text from '../../../text.json';
import { BORDER_RADIUS, BOX_SHADOWS } from '../../../theme/constants';
import NameGenerator from './NameGenerator';
import Variant from './Variant';
import Message, { MESSAGE_TYPE } from '../../../shared/Message';

const AddAlgorithm = ({ handleClose, isOpen }) => {
  const { metaData } = useSelector((state) => state.general);
  const [algorithmData, setAlgorithmData] = useState({
    algoTypeId: metaData?.algoTypes?.[0]?.id,
    variant: '',
    hasISP: false,
    hasPAD: false,
    hasLatentDetection: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [variantErrorMessage, setVariantErrorMessage] = useState('');
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const handleAddAlgorithm = async () => {
    const { variant, algoTypeId } = algorithmData;

    let isDataValid = true;
    setIsLoading(true);

    try {
      const variantErrorMessageTmp = await
      getVariantErrorMessage(
        variant,
        () => checkIfAlgorithmVariantExist({ variant, projectId, algoTypeId })
      );

      if (variantErrorMessageTmp) {
        setVariantErrorMessage(variantErrorMessageTmp);
        isDataValid = false;
      }
    } catch (error) {
      dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
      isDataValid = false;
    }

    if (isDataValid) {
      const result = await dispatch(addAlgorithm({ algorithmData, projectId }));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.add.title.replace('{{object}}', text.objects.algorithm)}
      primaryButtonText={text.buttons.add.replace('{{object}}', text.objects.algorithm)}
      onPrimaryButtonClick={handleAddAlgorithm}
      isLoading={isLoading}
      bodySx={{ px: 6 }}
    >
      <NameGenerator
        sx={{ mb: 3 }}
        typeId={algorithmData?.algoTypeId}
        variant={algorithmData?.variant}
        isInvalid={!!variantErrorMessage}
      />
      <Box sx={{
        boxShadow: BOX_SHADOWS.CONTAINER, borderRadius: BORDER_RADIUS.SMALL, py: 2, px: 3
      }}
      >
        <Message
          type={MESSAGE_TYPE.WARNING}
          message={text.addAlgorithm.warningMessage}
          sx={{ mb: 4 }}
        />
        <SelectInput
          sx={{ mb: 4, '.MuiInput-root': { backgroundColor: 'general.warningMessageLight' } }}
          label={`${text.commonInputFields.type} *`}
          value={metaData?.algoTypes?.find(
            ({ id }) => algorithmData.algoTypeId === id
          )?.id || ''}
          handleChange={(event) => {
            setAlgorithmData(
              (prevAlgoTypes) => ({ ...prevAlgoTypes, algoTypeId: event.target.value })
            );
            setVariantErrorMessage('');
          }}
          options={metaData?.algoTypes}
        />
        <Variant
          setAlgorithmData={setAlgorithmData}
          variantErrorMessage={variantErrorMessage}
          setVariantErrorMessage={setVariantErrorMessage}
          typeId={algorithmData?.algoTypeId}
        />
        <FormGroup>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {text.addAlgorithm.chooseModules}
          </Typography>
          <FormControlLabel
            control={(
              <Checkbox
                checked
                disabled
                size="small"
              />
          )}
            label={(
              <Typography variant="body2">
                {text.addAlgorithm.matcher}
              </Typography>
          )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                onChange={(event) => (
                  setAlgorithmData((prevAlgoData) => (
                    { ...prevAlgoData, hasISP: event.target.checked }))
                )}
                size="small"
              />
          )}
            label={(
              <Typography variant="body2">
                {text.addAlgorithm.isp}
              </Typography>
          )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                onChange={(event) => (
                  setAlgorithmData((prevAlgoData) => (
                    { ...prevAlgoData, hasPAD: event.target.checked }))
                )}
                size="small"
              />
        )}
            label={(
              <Typography variant="body2">
                {text.addAlgorithm.pad}
              </Typography>
        )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                size="small"
                onChange={(event) => (
                  setAlgorithmData((prevAlgoData) => (
                    { ...prevAlgoData, hasLatentDetection: event.target.checked }))
                )}
              />
        )}
            label={(
              <Typography variant="body2">
                {text.addAlgorithm.latentDetection}
              </Typography>
        )}
          />
        </FormGroup>
      </Box>
    </Modal>
  );
};

export default AddAlgorithm;

AddAlgorithm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

AddAlgorithm.defaultProps = {
  isOpen: false
};
