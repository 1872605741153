import { grey } from '@mui/material/colors';

export const PALETTE = {
  primary: {
    light: '#fcf6f0',
    main: '#E9973F',
    dark: '#e4831b',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#243f59',
    dark: '#162736',
    light: '#556580',
    light10: 'rgba(85, 101, 128, 0.1)',
    light30: 'rgba(85, 101, 128, 0.3)',
    light20: 'rgba(85, 101, 128, 0.2)',
  },
  gray: {
    disabledTextColor: grey[700],
    disabledBackgroundColor: grey[300],
    light: grey[200],
    dark: grey[500],
    containerLight: grey[100],
    hover: grey[50]
  },
  general: {
    success: '#70C041',
    successLight: '#d4ecc6',
    info: '#4d97ff',
    infoDark: '#004099',
    infoLight: '#CCE0FF',
    error: '#ec5d57',
    errorLight: '#fce9e8',
    errorDark: '#e83730',
    warning: '#827717',
    warningLight: '#f9fbe7',
    warningMessageLight: 'rgb(255 253 231 / 40%)'
  },
};

export const BORDER_RADIUS = {
  SMALL: '5px',
  MEDIUM: '10px',
};

export const BOX_SHADOWS = {
  CONTAINER: 'rgba(0, 0, 0, 0.3) 0px 2px 5px',
  CLICKABLE_BOX: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  CLICKABLE_BOX_HOVER: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
};
