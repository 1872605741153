import { GENERAL_ERROR_MESSAGE } from '../../../../../config';
import { setErrorMessage } from '../../../../../redux/snackbarSlice';
import { downloadIndexFile } from '../../../../../services/databases';
import { downloadFile } from '../../../../../utils/downloadHelper';

export const handleDownloadIndexFile = async (setIsDownloading, data, dispatch) => {
  try {
    setIsDownloading(true);
    const response = await downloadIndexFile(data?.id);
    const filename = response.headers?.['algo-downloadfilename'] || 'index.fpdbindex';
    downloadFile(response?.data, filename);
  } catch (error) {
    dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
  } finally {
    setIsDownloading(false);
  }
};
