import { ROUTES } from './config';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES],
    redirectUri: '/',
    postLogoutRedirectUri: `/${ROUTES.LOGIN}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
  scopes: ['openid', 'offline_access', process.env.REACT_APP_B2C_CLIENT_ID]
};
