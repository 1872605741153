import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';
import { useMsal, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { loginImage, logoWhite } from '../../assets/icons';
import { loginRequest } from '../../authConfig';
import text from '../../text.json';

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <UnauthenticatedTemplate>
      <Grid container m={0} sx={{ height: '100vh' }}>
        <Grid
          sx={(theme) => ({
            backgroundColor: 'secondary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
          item
          md={5}
        >
          <Box sx={{
            p: 5, display: 'flex', flexDirection: 'column',
          }}
          >
            <img width="170px" height="50px" src={logoWhite} alt="login white" />
            <Typography sx={{ mt: 5, mb: 9 }} variant="h6" color="white">
              {text.login.description}
            </Typography>
            <img style={{ alignSelf: 'center' }} width="300px" height="260px" src={loginImage} alt="login icon" />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5" sx={{ color: 'secondary.main' }}>{text.login.header1}</Typography>
          <Typography variant="h5" sx={{ color: 'secondary.main', fontWeight: 'fontWeightBold', mb: 4 }}>{text.login.header2}</Typography>
          <Button
            sx={{ mb: 8 }}
            variant="primary"
            onClick={() => instance.loginRedirect(loginRequest)}
          >
            {text.buttons.login}
          </Button>
        </Grid>
      </Grid>
    </UnauthenticatedTemplate>
  );
};

export default Login;
