import {
  Box,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../shared/SelectInput';
import Modal from '../../shared/Modal';
import { getNameErrorMessage } from './validation';
import { addSensor } from '../../redux/api/sensor';
import { checkIfSensorNameExist } from '../../services/sensors';
import { getNumberInputTextFieldCommonProps, NUMBER_FIELDS_NAMES } from './helper';
import { setErrorMessage } from '../../redux/snackbarSlice';
import { GENERAL_ERROR_MESSAGE, NAME_MAX_NUMBER_CHARACTERS } from '../../config';
import text from '../../text.json';
import { PALETTE } from '../../theme/constants';
import Message, { MESSAGE_TYPE } from '../../shared/Message';

const AddSensor = ({ handleClose, isOpen }) => {
  const { metaData } = useSelector((state) => state.general);
  const { customerInfo } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [sensorData, setSensorData] = useState({
    name: '',
    width: null,
    height: null,
    dotsPerInch: null,
    sensorTypeId: metaData?.sensorTypes?.[0]?.id || ''
  });
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [widthErrorMessage, setWidthErrorMessage] = useState('');
  const [heightErrorMessage, setHeightErrorMessage] = useState('');
  const [resolutionErrorMessage, setResolutionErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddSensor = async () => {
    const {
      name, width, height, dotsPerInch
    } = sensorData;

    setIsLoading(true);
    let isDataValid = true;

    try {
      const nameErrorMessageTmp = await
      getNameErrorMessage(name, () => checkIfSensorNameExist(
        { name, customerId: customerInfo?.id }
      ));

      if (nameErrorMessageTmp) {
        setNameErrorMessage(nameErrorMessageTmp);
        isDataValid = false;
      }
    } catch (error) {
      dispatch(setErrorMessage(GENERAL_ERROR_MESSAGE));
      isDataValid = false;
    }

    if (!width) {
      setWidthErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.addSensor.width));
      isDataValid = false;
    }
    if (!height) {
      setHeightErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.addSensor.height));
      isDataValid = false;
    }
    if (dotsPerInch !== 0 && !dotsPerInch) {
      setResolutionErrorMessage(text.errorMessages.fieldRequired.replace(/{{field}}/g, text.commonInputFields.resolution));
      isDataValid = false;
    }

    if (isDataValid) {
      const result = await dispatch(addSensor({ sensorData, customerId: customerInfo?.id }));
      if (result?.error) {
        setIsLoading(false);
      } else {
        handleClose();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalTitle={text.add.title.replace('{{object}}', text.objects.sensor)}
      primaryButtonText={text.buttons.add.replace('{{object}}', text.objects.sensor)}
      onPrimaryButtonClick={handleAddSensor}
      isLoading={isLoading}
    >
      <Message type={MESSAGE_TYPE.WARNING} message={text.addSensor.warningMessage} />
      <TextField
        sx={{ mb: 4, '.MuiInputLabel-root': { zIndex: 1 } }}
        fullWidth
        label={`${text.commonInputFields.name} *`}
        variant="standard"
        onChange={(event) => {
          setSensorData((prevSensorData) => (
            { ...prevSensorData, name: event.target.value }));
          setNameErrorMessage('');
        }}
        helperText={nameErrorMessage}
        error={!!nameErrorMessage}
        inputProps={{
          'data-testid': 'nameInputTest',
          maxLength: NAME_MAX_NUMBER_CHARACTERS,
          style: { backgroundColor: PALETTE.general.warningMessageLight }
        }}
      />
      <Box mb={4} display="flex" alignItems="center">
        <TextField
          {...getNumberInputTextFieldCommonProps('px', NUMBER_FIELDS_NAMES.width, setWidthErrorMessage, setSensorData, 'widthInputTest')}
          label={`${text.addSensor.width} *`}
          sx={{ mr: 2 }}
          helperText={widthErrorMessage}
          error={!!widthErrorMessage}
        />
        <Typography fontWeight="fontWeightBold" sx={{ color: 'gray.dark' }}>x</Typography>
        <TextField
          {...getNumberInputTextFieldCommonProps('px', NUMBER_FIELDS_NAMES.height, setHeightErrorMessage, setSensorData, 'heightInputTest')}
          sx={{ ml: 2 }}
          label={`${text.addSensor.height} *`}
          helperText={heightErrorMessage}
          error={!!heightErrorMessage}
        />
      </Box>
      <SelectInput
        label={`${text.commonInputFields.type} *`}
        value={metaData?.sensorTypes?.find(
          ({ id }) => sensorData.sensorTypeId === id
        )?.id || ''}
        handleChange={(event) => setSensorData(
          (prevSensorData) => ({ ...prevSensorData, sensorTypeId: event.target.value })
        )}
        options={metaData?.sensorTypes}
        sx={{ mb: 4, '.MuiInput-root': { backgroundColor: 'general.warningMessageLight' } }}
      />
      <TextField
        {...getNumberInputTextFieldCommonProps('dpi', NUMBER_FIELDS_NAMES.resolution, setResolutionErrorMessage, setSensorData, 'resolutionInputTest')}
        label={`${text.commonInputFields.resolution} *`}
        helperText={resolutionErrorMessage}
        error={!!resolutionErrorMessage}
      />
    </Modal>
  );
};

export default AddSensor;

AddSensor.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

AddSensor.defaultProps = {
  isOpen: false
};
