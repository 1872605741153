import {
  Grid,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import Proptypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ADMIN_ROLES } from '../../../config';

import CustomTable from '../../../shared/CustomTable';
import Restricted from '../../../shared/Restricted';
import SearchInput from '../../../shared/SearchInput';
import text from '../../../text.json';
import { getTopExtraCardLayerStyle } from '../../helper';
import RowActions from './RowActions';

const Table = ({
  searchValue,
  setSearchValue,
  onRowClick,
  isLoading,
  rows,
  columns,
  buttonText,
  onButtonClick,
  handleRemove,
  handleEdit,
  isButtonDisabled,
  disabledButtonTooltipText,
  sx,
  isDatabaseOverview
}) => {
  const { sensorId, projectId } = useParams();

  const getSectionName = () => {
    if (!sensorId) {
      return text.sections.sensors;
    }

    if (sensorId && !projectId) {
      return text.sections.projects;
    }

    return '';
  };

  return (
    <Grid
      sx={{
        ...getTopExtraCardLayerStyle().CONTAINER,
        ...sx
      }}
    >
      <Grid mb={3} item xs={12} display="flex" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Typography
            sx={(theme) => ({ ...getTopExtraCardLayerStyle(theme).TITLE })}
          >
            {getSectionName()}
          </Typography>
          {text.sections.projects === getSectionName() && (
          <Typography
            variant="body2"
            sx={(theme) => ({ ...getTopExtraCardLayerStyle(theme).SUBTITLE })}
          >
            {text.sections.projectsDescription}
          </Typography>
          )}
        </Grid>
        {buttonText && typeof isButtonDisabled === 'boolean' && (
        <Restricted to={[ADMIN_ROLES.PRECISE]}>
          <Grid item>
            <Tooltip title={isButtonDisabled ? disabledButtonTooltipText : ''}>
              <span>
                <Button
                  onClick={onButtonClick}
                  variant="primary"
                  disabled={isButtonDisabled}
                >
                  {buttonText}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Restricted>
        )}
      </Grid>
      {setSearchValue && (
      <Grid mb={4} item xs={12}>
        <SearchInput
          placeholder={text.commonInputFields.search}
          value={searchValue}
          setValue={setSearchValue}
        />
      </Grid>
      )}
      <Grid item xs={12} sx={{ height: '100%', overflow: 'hidden' }}>
        <CustomTable
          isDataLoading={isLoading}
          rows={rows}
          columns={columns}
          onRowClick={onRowClick}
          handleRemove={handleRemove}
          actionComponent={RowActions}
          handleEdit={handleEdit}
          isDatabaseOverview={isDatabaseOverview}
        />
      </Grid>
    </Grid>
  );
};

export default Table;

Table.propTypes = {
  searchValue: Proptypes.string,
  disabledButtonTooltipText: Proptypes.string,
  setSearchValue: Proptypes.func,
  onRowClick: Proptypes.func,
  isLoading: Proptypes.bool,
  rows: Proptypes.arrayOf(Proptypes.shape({})),
  columns: Proptypes.arrayOf(Proptypes.shape({})),
  buttonText: Proptypes.string,
  onButtonClick: Proptypes.func,
  handleRemove: Proptypes.func,
  handleEdit: Proptypes.func,
  isButtonDisabled: Proptypes.bool,
  sx: Proptypes.shape({}),
  isDatabaseOverview: Proptypes.bool
};

Table.defaultProps = {
  searchValue: '',
  disabledButtonTooltipText: '',
  buttonText: '',
  setSearchValue: null,
  onRowClick: undefined,
  isLoading: false,
  isButtonDisabled: null,
  rows: [],
  columns: [],
  onButtonClick: () => {},
  handleRemove: null,
  handleEdit: null,
  sx: {},
  isDatabaseOverview: false
};
