import text from './text.json';

export const API_URL = process.env.REACT_APP_API_URL;
export const ROUTES = {
  LOGIN: 'login',
  ACTIVITY_LOGS: 'activity-logs',
  RELEASES: 'releases',
  SENSORS: 'sensors',
  PROJECTS: 'projects',
  CUSTOMERS: 'customers',
};

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
};

export const ADMIN_ROLES = {
  PRECISE: 'Algo-Admins',
  PRECISE_LIMITED: 'Algo-limited-access'
};

export const sidebarWidth = 270;

export const GENERAL_ERROR_MESSAGE = text.errorMessages.general;

export const ERROR_MESSAGES = {
  ADD_SENSOR: text.errorMessages.add.replace('{{object}}', text.objects.sensor),
  ADD_PROJECT: text.errorMessages.add.replace('{{object}}', text.objects.project),
  ADD_DATABASE: text.errorMessages.add.replace('{{object}}', text.objects.database),
  ADD_ALGORITHM: text.errorMessages.add.replace('{{object}}', text.objects.algorithm),
  ADD_CUSTOMER: text.errorMessages.add.replace('{{object}}', text.objects.customer),
  REMOVE_SENSOR: text.errorMessages.remove.replace('{{object}}', text.objects.sensor),
  REMOVE_PROJECT: text.errorMessages.remove.replace('{{object}}', text.objects.project),
  REMOVE_DATABASE: text.errorMessages.remove.replace('{{object}}', text.objects.database),
  REMOVE_ALGORITHM: text.errorMessages.remove.replace('{{object}}', text.objects.algorithm),
  EDIT_SENSOR: text.errorMessages.edit.replace('{{object}}', text.objects.sensor),
  EDIT_PROJECT: text.errorMessages.edit.replace('{{object}}', text.objects.project),
  UPLOAD_INDEX_FILE: text.errorMessages.upload.replace('{{file}}', text.files.indexFile.toLowerCase()),
  BUILD_RELEASE: text.errorMessages.build,
};

export const SUCCESS_MESSAGES = {
  ADD_SENSOR: text.successMessages.add.replace('{{object}}', text.objects.sensor),
  ADD_PROJECT: text.successMessages.add.replace('{{object}}', text.objects.project),
  ADD_DATABASE: text.successMessages.add.replace('{{object}}', text.objects.database),
  ADD_ALGORITHM: text.successMessages.add.replace('{{object}}', text.objects.algorithm),
  ADD_CUSTOMER: text.successMessages.added.replace('{{object}}', text.objects.customer),
  REMOVE_SENSOR: text.successMessages.remove.replace('{{object}}', text.objects.sensor),
  REMOVE_PROJECT: text.successMessages.remove.replace('{{object}}', text.objects.project),
  REMOVE_DATABASE: text.successMessages.remove.replace('{{object}}', text.objects.database),
  REMOVE_ALGORITHM: text.successMessages.remove.replace('{{object}}', text.objects.algorithm),
  EDIT_SENSOR: text.successMessages.edit.replace('{{object}}', text.objects.sensor),
  EDIT_PROJECT: text.successMessages.edit.replace('{{object}}', text.objects.project),
  UPLOAD_INDEX_FILE: text.successMessages.upload.replace('{{file}}', text.files.indexFile),
};

export const NAME_MAX_NUMBER_CHARACTERS = 32;

export const REMOVAL_REQUESTED = {
  text: `${text.status.removal} ${text.status.requested}`,
  tooltip: text.tooltips.removalRequested
};

export const ADDING_REQUESTED = {
  text: `${text.status.adding} ${text.status.requested}`,
  tooltip: text.tooltips.addingRequested
};

export const OBJECTS = {
  SENSOR: 'Sensor',
  PROJECT: 'Project',
  ALGORITHM: 'Algorithm',
  DATABASE: 'FingerprintDatabase',
  RELEASE: 'Release'
};

export const OBJECT_STATUSES_ACTIVITY_LOG = {
  ADDED: 'Added',
  ADDING_REQUESTED: 'Adding requested',
  REMOVAL_REQUESTED: 'Deleting requested',
  UPDATED: 'Updated',
  READY: 'Ready',
  NOT_READY: 'NotReady',
  INDEX_FILE_UPLOADED: 'Index file uploaded',
  FPDB_UPLOADED: 'Fingerprint dataset uploaded'
};

export const STORAGE_ITEMS = {
  UPLOAD_PROCESS: 'uploadingProcessData'
};

export const TABLE_PAGE_SIZES = [10, 25, 100];
export const DEFAULT_TABLE_PAGE_SIZE = TABLE_PAGE_SIZES[0];
