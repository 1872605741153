import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import ProcessIcon from '../../../../../../shared/ProcessIcon';
import UploadIcon from '../../../../../../shared/UploadIcon';

export const STATIC_CHIP_TYPE = {
  SUCCESS: 'success',
  VALIDATING: 'validating',
  UPLOADING: 'uploading',
  ERROR: 'error'
};

const StaticChip = ({ title, sx, type }) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    py: 0.5,
    ...sx
  }}
  >
    {type === STATIC_CHIP_TYPE.SUCCESS && <CheckIcon fontSize="small" sx={{ color: 'general.success' }} />}
    {type === STATIC_CHIP_TYPE.VALIDATING && <ProcessIcon style={{ width: '22px', height: '22px' }} />}
    {type === STATIC_CHIP_TYPE.UPLOADING && <UploadIcon style={{ width: '22px', height: '22px' }} />}
    {type === STATIC_CHIP_TYPE.ERROR && <WarningIcon fontSize="small" sx={{ color: 'general.error' }} />}
    <Typography noWrap variant="caption" sx={{ fontWeight: 'fontWeightBold', ml: 0.5 }}>{title}</Typography>
  </Box>
);

export default StaticChip;

StaticChip.propTypes = {
  title: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  type: PropTypes.string.isRequired
};

StaticChip.defaultProps = {
  sx: {},
};
