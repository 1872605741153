import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteProject, getProject, getProjects, postProject, updateProject
} from '../../services/projects';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchProjects = createAsyncThunk('project/fetchProjects', async (sensorId, thunkAPI) => {
  try {
    return await getProjects(sensorId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const fetchProject = createAsyncThunk('project/fetchProject', async (projectId, thunkAPI) => {
  try {
    return await getProject(projectId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const addProject = createAsyncThunk('project/addProject', async ({ projectData, sensorId }, thunkAPI) => {
  try {
    return await postProject(projectData, sensorId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const removeProject = createAsyncThunk('project/removeProject', async (id, thunkAPI) => {
  try {
    return await deleteProject(id);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const editProject = createAsyncThunk('project/editProject', async (projectData, thunkAPI) => {
  try {
    return await updateProject(projectData, projectData?.id);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
