import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteDatabase,
  getDatabases,
  postDatabase,
  putDatabase,
  uploadIndexFile
} from '../../services/databases';
import { rejectionsHandler } from '../../utils/errorHelper';
import { sessionExpired } from '../authSlice';

const handleRejections = rejectionsHandler(sessionExpired);

export const fetchDatabases = createAsyncThunk('database/fetchDatabases', async (projectId, thunkAPI) => {
  try {
    return await getDatabases(projectId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const addDatabase = createAsyncThunk('database/addDatabase', async ({ databaseData, projectId }, thunkAPI) => {
  try {
    return await postDatabase(databaseData, projectId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const addIndexFile = createAsyncThunk('database/addIndexFile', async ({ databaseId, file }, thunkAPI) => {
  try {
    return await uploadIndexFile(file, databaseId);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const removeDatabase = createAsyncThunk('database/removeDatabase', async (id, thunkAPI) => {
  try {
    return await deleteDatabase(id);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});

export const updateDatabase = createAsyncThunk('database/updateDatabase', async ({ databaseId, data }, thunkAPI) => {
  try {
    return await putDatabase(databaseId, data);
  } catch (e) {
    return handleRejections(e, thunkAPI);
  }
});
